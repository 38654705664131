import React from 'react';
import './PageInDev.css';

const PageInDev = () => {
  return (
    <div className="placeholder-container">
      <div className="placeholder-text">
        Сторінка в процесі розробки...
      </div>
    </div>
  );
};

export default PageInDev;
