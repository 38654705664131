import {text_mod_22, } from "../text/Text.js";
export const module22 = {
  title:
    "Тема 22. Створюю фотоколаж «Я і моя родина»​​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_22, },
    ]
  }
