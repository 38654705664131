
import { text_mod_20_1, text_mod_20_2, text_mod_20_3 } from "../text/Text.js";

export const module20 = {
  title: "Романтизм у творчості Віктора Забіли",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_20_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_20_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_20_3,
    },
    {
      id: 4,
      question: "Головний мотив лірики Віктора Забіли – це:",
      options: [
        { id: "A", text: "національно-визвольна боротьба" },
        { id: "B", text: "глибокий смуток і нещасливе кохання" },
        { id: "C", text: "природа і її краса" },
        { id: "D", text: "військові пригоди" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка трискладова стопа має наголос на третьому складі?",
      options: [
        { id: "A", text: "Дактиль" },
        { id: "B", text: "Амфібрах" },
        { id: "C", text: "Анапест" },
        { id: "D", text: "Хорей" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка риса романтизму притаманна пісні «Не щебече соловейко»?",
      options: [
        { id: "A", text: "Філософські роздуми" },
        { id: "B", text: "Поглиблене зображення кохання" },
        { id: "C", text: "Романтична ідеалізація природи" },
        { id: "D", text: "Соціальний протест" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
