import {text_mod_9, } from "../text/Text.js";
export const module09 = {
  title:
    "Розділ 2.3. Теми 67-76. Розв’язання задач. Обчислення виразів. ",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_9, },
    ]
  };