import { text_mod_21_1, text_mod_21_2 } from "../text/Text.js";

export const module21 = {
  title: "Тема 21. ЛІТЕРАТУРА ВІДРОДЖЕННЯ. Доба Ренесансу (Відродження) в Європі",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_21_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_21_2,
    },
    {
      id: 3,
      question: "1. Який період охоплює епоха Відродження в європейській культурі?",
      options: [
        { id: "А", text: "початок XIII ст. - кінець XV ст." },
        { id: "Б", text: "середина XIV ст. - перша половина XVII ст." },
        { id: "В", text: "кінець XV ст. - середина XVIII ст." },
        { id: "Г", text: "початок XVI ст. - кінець XVII ст." },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "2. Що НЕ БУЛО новими життєвими цінностями епохи Відродження в Європі?",
      options: [
        { id: "А", text: "особиста свобода людини" },
        { id: "Б", text: "збагачення та матеріальний достаток" },
        { id: "В", text: "аскетизм і відмова від земних благ" },
        { id: "Г", text: "активність і підприємливість" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "3. Хто винайшов друкарський прес?",
      options: [
        { id: "А", text: "Христофор Колумб" },
        { id: "Б", text: "Йоганн Ґутенберг" },
        { id: "В", text: "Леонардо да Вінчі" },
        { id: "Г", text: "Галілео Галілей" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "4. Що стало найпомітнішою ознакою доби Ренесансу?",
      options: [
        { id: "А", text: "розвиток математики" },
        { id: "Б", text: "відродження традицій античності" },
        { id: "В", text: "винайдення друкарського преса" },
        { id: "Г", text: "географічні відкриття" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "5. Хто вважається першим гуманістом в історії мистецтва?",
      options: [
        { id: "А", text: "Мікеланджело Буонарроті" },
        { id: "Б", text: "Джордано Бруно" },
        { id: "В", text: "Франческо Петрарка" },
        { id: "Г", text: "Рафаель Санті" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "6. Яке поняття НЕ НАЛЕЖИТЬ до світоглядної основи Ренесансу?",
      options: [
        { id: "А", text: "гуманізм" },
        { id: "Б", text: "реформація" },
        { id: "В", text: "схоластика" },
        { id: "Г", text: "антропоцентризм" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
  ],
};
