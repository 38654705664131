export const module30 = {
  title: "Жанри мовлення",
  questions: [
    {
      id: 1,
      question: "Що означає термін 'дорікати'?",
      options: [
        { id: "A", text: "Висловлювати своє незадоволення щодо чогось" },
        { id: "B", text: "Висловлювати радість" },
        { id: "C", text: "Виявляти байдужість" },
        { id: "D", text: "Підтримувати когось у важкій ситуації" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з наведених характеристик найбільш точно описує обман?",
      options: [
        { id: "A", text: "Випадкове непорозуміння" },
        { id: "B", text: "Намірене введення в оману" },
        { id: "C", text: "Висловлення співчуття" },
        { id: "D", text: "Неправильне тлумачення фактів" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка комунікативна мета мовленнєвого жанру співчуття?",
      options: [
        { id: "A", text: "Висловлення радості" },
        { id: "B", text: "Висловлення жалю через нещастя іншої людини" },
        { id: "C", text: "Обговорення досягнень" },
        { id: "D", text: "Висловлення критики" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка структура відгуку включає в себе?",
      options: [
        { id: "A", text: "Тільки особисті враження" },
        { id: "B", text: "Тезу, докази, висновок, рекомендації" },
        { id: "C", text: "Лише загальну оцінку" },
        { id: "D", text: "Лише опис сюжету" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених характеристик є ризиком при написанні відгуку у вільному стилі?",
      options: [
        { id: "A", text: "Втрата логіки викладу думок" },
        { id: "B", text: "Занадто формальний стиль" },
        { id: "C", text: "Відсутність емоцій" },
        { id: "D", text: "Неправильне використання термінів" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка мета відгуку на прочитану книжку?",
      options: [
        { id: "A", text: "Оцінити автора" },
        { id: "B", text: "Висловити свої почуття та думки, рекомендувати книжку іншим" },
        { id: "C", text: "Підсумувати сюжет" },
        { id: "D", text: "Висловити незадоволення" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
