export const module41 = {
  title: "Ввічливе заперечення",
  questions: [
    {
      id: 1,
      question: "Яка з наведених характеристик є прикладом ввічливого заперечення?",
      options: [
        { id: "A", text: "Ні, я не згоден" },
        { id: "B", text: "Вибачте, але я бачу це зовсім інакше" },
        { id: "C", text: "Це неправильно" },
        { id: "D", text: "Я не хочу обговорювати це" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з наведених фраз є прикладом категоричного заперечення?",
      options: [
        { id: "A", text: "Можливо, я не зрозумів" },
        { id: "B", text: "Я не можу з цим погодитися" },
        { id: "C", text: "Я не хочу цього обговорювати" },
        { id: "D", text: "Це не зовсім правильно" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з порад є важливою під час заперечення?",
      options: [
        { id: "A", text: "Не слухати співрозмовника" },
        { id: "B", text: "Зберігати позитивний настрій" },
        { id: "C", text: "Використовувати грубі слова" },
        { id: "D", text: "Ігнорувати думку інших" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка з наведених фраз є прикладом м'якого заперечення?",
      options: [
        { id: "A", text: "Це неправильно" },
        { id: "B", text: "Я не можу з цим погодитися" },
        { id: "C", text: "На жаль, я не можу з вами погодитися" },
        { id: "D", text: "Я не згоден з тобою" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених ситуацій є прикладом ввічливого заперечення?",
      options: [
        { id: "A", text: "Ти помиляєшся" },
        { id: "B", text: "Я не згоден з твоєю думкою" },
        { id: "C", text: "Я думаю, що ти не зовсім правий" },
        { id: "D", text: "Це не так" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка з наведених фраз є прикладом запитання для уточнення?",
      options: [
        { id: "A", text: "Я не розумію твоєї думки" },
        { id: "B", text: "Це неправильно" },
        { id: "C", text: "Я не згоден" },
        { id: "D", text: "Я думаю, що ти помиляєшся" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
  ],
};
