import {text_mod_5, } from "../text/Text.js";
export const module05 = {
  title:
    "Розділ 1.5. Теми 33-36. Додавання і віднімання в межах 10. ",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_5, },
    ]
  };