import { text_mod_49_1, text_mod_49_2 } from "../text/Text.js";

export const module49 = {
  title: "Тема 49. Сучасні українські та зарубіжні балади",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_49_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_49_2,
    },
    {
      id: 3,
      question: "Який факт із біографії В. Івасюка є помилковим?",
      options: [
        { id: "А", text: "В. Івасюк – автор відомої на весь світ пісні “Червона рута”." },
        { id: "Б", text: "Автором слів та музики до твору “Балада про мальви” є Володимир Івасюк." },
        { id: "В", text: "Композитор В. Івасюк – один з основоположників української естрадної музики." },
        { id: "Г", text: "Володимир Івасюк прожив всього 30 років." },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "Провідний мотив “Балади про мальви” передано у рядках",
      options: [
        { id: "А", text: "“Життя – як пісня, що не віддзвенить. / Я в мальві знов для тебе буду жить”." },
        { id: "Б", text: "“О, мамо рідна, ти мене не жди, /Мені в наш дім ніколи не прийти”." },
        { id: "В", text: "“Не плач, не плач, бо ти вже не одна/ Багато мальв насіяла війна”." },
        { id: "Г", text: "“У матерів є любі діти, / А у моєї – тільки квіти”." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "Трагедію війни у рядках “Багато мальв насіяла війна” автор передає за допомогою",
      options: [
        { id: "А", text: "гіперболи" },
        { id: "Б", text: "фразеологізма" },
        { id: "В", text: "персоніфікації" },
        { id: "Г", text: "епітета" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "Автор “Кленової балади”",
      options: [
        { id: "А", text: "Володимир Івасюк" },
        { id: "Б", text: "Анатолій Матвійчук" },
        { id: "В", text: "Богдан Гура" },
        { id: "Г", text: "Іван Драч" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "У рядках “Загриміли в полі, мов громи, гармати” автор використовує",
      options: [
        { id: "А", text: "порівняння та фразеологізм" },
        { id: "Б", text: "порівняння та метафору" },
        { id: "В", text: "порівняння та епітет" },
        { id: "Г", text: "порівняння та алітерацію" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 8,
      question: "Яка з ознак НЕ характерна для “Кленової балади”?",
      options: [
        { id: "А", text: "Висока емоційність" },
        { id: "Б", text: "Невелика кількість подій і персонажів" },
        { id: "В", text: "Фантастичність, загадковість, недомовленість" },
        { id: "Г", text: "Народнопісенна основа, символічність образів" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
  ],
};
