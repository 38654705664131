export const module25 = {
  title: "Розрізнення фактів і суджень",
  questions: [
    {
      id: 1,
      question: "Яка з наведених характеристик найкраще описує факт?",
      options: [
        { id: "A", text: "Думка, що може бути істинною або хибною" },
        { id: "B", text: "Реальна подія, що не потребує доведення" },
        { id: "C", text: "Оцінка особистого досвіду" },
        { id: "D", text: "Загальноприйняте твердження" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з наведених фраз є прикладом судження?",
      options: [
        { id: "A", text: "Швидкість вібрації вимірюють в герцах" },
        { id: "B", text: "Немає нічого кращого за кілька улюблених мелодій перед сном" },
        { id: "C", text: "Солов’ї мають посереднє коричневе забарвлення" },
        { id: "D", text: "Дев’ята симфонія Бетховена удвічі довша, ніж треба" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Чому важливо вміти відрізняти факти від суджень?",
      options: [
        { id: "A", text: "Це допомагає уникати емоційних реакцій" },
        { id: "B", text: "Це дозволяє формувати об’єктивну точку зору" },
        { id: "C", text: "Це покращує навички письма" },
        { id: "D", text: "Це робить спілкування легшим" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 4,
      question: "Який з наведених прикметників найкраще пасує до факту?",
      options: [
        { id: "A", text: "Витончений" },
        { id: "B", text: "Достемений" },
        { id: "C", text: "Хибний" },
        { id: "D", text: "Суб’єктивний" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених фраз є прикладом хибного судження?",
      options: [
        { id: "A", text: "Квитки на рок-концерт дуже дорогі" },
        { id: "B", text: "Усі музичні інструменти реагують на зміну температури" },
        { id: "C", text: "Немає нічого кращого за музику, що лікує ковід" },
        { id: "D", text: "Борис Лятошинський написав 11 симфонічних поем" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
  ],
};
