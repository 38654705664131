
import { text_mod_21_1, text_mod_21_2, text_mod_21_3 } from "../text/Text.js";

export const module21 = {
  title: "Жанр балади в літературі романтизму. Тарас Шевченко «Причинна»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_21_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_21_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_21_3,
    },
    {
      id: 4,
      question: "Жанр балади походить від:",
      options: [
        { id: "A", text: "українських народних пісень" },
        { id: "B", text: "середньовічних історичних пісень" },
        { id: "C", text: "західноєвропейських танцювально-хорових пісень" },
        { id: "D", text: "соціально-побутових пісень" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "Підготувати першу збірку «Кобзар» до друку Т. Шевченку допоміг:",
      options: [
        { id: "A", text: "поміщик Енгельґард" },
        { id: "B", text: "Євген Гребінка" },
        { id: "C", text: "Карл Брюллов" },
        { id: "D", text: "Микола Костомаров" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "Звуковий образ буряної ночі на Дніпрі у баладі «Причинна» Т. Шевченко передає за допомогою:",
      options: [
        { id: "A", text: "метафори та епітетів" },
        { id: "B", text: "епітетів та уособлення" },
        { id: "C", text: "алітерацій та асонансів" },
        { id: "D", text: "порівняння" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 7,
      question: "Ознаками романтизму в баладі є всі названі, ОКРІМ:",
      options: [
        { id: "A", text: "Мотиви розлуки, нестерпної туги за коханою людиною" },
        { id: "B", text: "Фольклорні образи русалок" },
        { id: "C", text: "Існування «двох світів»: світу ідеалу, мрії та світу дійсності" },
        { id: "D", text: "Показ впливу соціально-побутових умов на формування характеру людини" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 8,
      question: "Рядки «Не русалонька блукає: / То дівчина ходить, / Й сама не зна (бо причинна), / Що такеє робить» у композиції балади є:",
      options: [
        { id: "A", text: "епілогом" },
        { id: "B", text: "зав'язкою" },
        { id: "C", text: "розв'язкою" },
        { id: "D", text: "кульмінацією" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 9,
      question: "Яку тварину з народних вірувань НЕ згадано в баладі?",
      options: [
        { id: "A", text: "кінь" },
        { id: "B", text: "кішку" },
        { id: "C", text: "півень" },
        { id: "D", text: "сич" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
