import { text_mod_30_1, text_mod_30_2 } from "../text/Text.js";

export const module30 = {
  title: "Тема 30. ЛІТЕРАТУРА БАРОКО",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_30_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_30_2,
    },
    {
      id: 3,
      question: "Батьківщиною Бароко вважається",
      options: [
        { id: "А", text: "Франція" },
        { id: "Б", text: "Італія" },
        { id: "В", text: "Німеччина" },
        { id: "Г", text: "Україна" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "Розповсюдженню бароко в інші країни сприяли представники Ордену",
      options: [
        { id: "А", text: "кларисок" },
        { id: "Б", text: "бенедиктинців" },
        { id: "В", text: "єзуїтів" },
        { id: "Г", text: "домініканців" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "Не відповідає дійсності твердження про добу Бароко",
      options: [
        { id: "А", text: "Простоту форми твору замінюють вигадливістю, пишномовністю." },
        { id: "Б", text: "Прослідковується релігійне забарвлення усіх царин культури." },
        { id: "В", text: "Характерним є змалювання руху, мандрівки, сміливих авантюр." },
        { id: "Г", text: "Античність є виключним ідеалом краси і гармонії." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "Бароко підтримує увагу до природи (і в науці, й у мистецтві), однак природа трактується як",
      options: [
        { id: "А", text: "Бог" },
        { id: "Б", text: "протистояння Богові" },
        { id: "В", text: "шлях пізнання досконалості Бога" },
        { id: "Г", text: "шлях пізнання досконалості людини" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "Важливого значення в літературі Бароко набуває",
      options: [
        { id: "А", text: "вигадливий сюжет" },
        { id: "Б", text: "зовнішній вигляд" },
        { id: "В", text: "внутрішній конфлікт характеру персонажа" },
        { id: "Г", text: "любовний трикутник" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "У культурі доби Бароко НЕ представлені",
      options: [
        { id: "А", text: "пишні форми" },
        { id: "Б", text: "дорогі матеріали" },
        { id: "В", text: "скульптурні елементи, дзеркала" },
        { id: "Г", text: "зображення людей у спокійному стані" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
  ],
};
