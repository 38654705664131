import { text_mod_7_1, text_mod_7_2 } from "../text/Text.js";

export const module07 = {
  title: "Тема 7. Іван Білик «Дарунки скіфів»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_7_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_7_2,
    },
    {
      id: 3,
      question: "1. Події, зображені у творі «Дарунки скіфів», розгортаються в",
      options: [
        { id: "А", text: "ІV ст. до н. е." },
        { id: "Б", text: "VІ ст. до н. е." },
        { id: "В", text: "VІ ст. н. е." },
        { id: "Г", text: "VІ ст. н. е." },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "2. Цар Дар'явауш очолював військо",
      options: [
        { id: "А", text: "скіфів" },
        { id: "Б", text: "сарматів" },
        { id: "В", text: "персів" },
        { id: "Г", text: "єгиптян" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "3. Справжньою причиною, що змусила Дар'явауша піти походом на скіфів є",
      options: [
        { id: "А", text: "образа, завдана йому в храмі Птаха" },
        { id: "Б", text: "помста скіфам за двадцятивосьмирічне панування в Азії" },
        { id: "В", text: "поневолення Скіфії – єдиної вільної на той час території" },
        { id: "Г", text: "Дар'явауш хотів стати царем царів" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "4. Передове військо царя Дар’явауша називалося полком",
      options: [
        { id: "А", text: "«хоробрих»" },
        { id: "Б", text: "«безсмертних»" },
        { id: "В", text: "«непереможних»" },
        { id: "Г", text: "«найсміливіших»" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "5. Що означала для скіфів вимога царя про дари: «воду в срібному глекові та землю на золотій тарелі»?",
      options: [
        { id: "А", text: "Початок перемовин між персами й скіфами." },
        { id: "Б", text: "Укладання мирного договору між персами й скіфами." },
        { id: "В", text: "Безумовне підпорядкування завойовнику." },
        { id: "Г", text: "Збір данини завойовниками." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "6. Якими були дарунки скіфів?",
      options: [
        { id: "А", text: "срібний глек і золота тареля" },
        { id: "Б", text: "вода та земля" },
        { id: "В", text: "шуліка, миша, жаба та стріли" },
        { id: "Г", text: "золота пектораль" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
  ],
};
