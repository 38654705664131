export const module31 = {
  title: "Кулінарний рецепт",
  questions: [
    {
      id: 1,
      question: "Яка мета кулінарного рецепта?",
      options: [
        { id: "A", text: "Залишити кулінарну традицію" },
        { id: "B", text: "Показати, як приготувати страву" },
        { id: "C", text: "Зібрати інгредієнти" },
        { id: "D", text: "Визначити час приготування" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Які складники є обов'язковими в кулінарному рецепті?",
      options: [
        { id: "A", text: "Лише основні інгредієнти" },
        { id: "B", text: "Основні інгредієнти та спосіб приготування" },
        { id: "C", text: "Лише спосіб приготування" },
        { id: "D", text: "Лише час приготування" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з наведених страв є традиційною для української кухні?",
      options: [
        { id: "A", text: "Піца" },
        { id: "B", text: "Суші" },
        { id: "C", text: "Вареники" },
        { id: "D", text: "Бургер" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 4,
      question: "Які з наведених елементів не є частиною кулінарного рецепта?",
      options: [
        { id: "A", text: "Список інгредієнтів" },
        { id: "B", text: "Спосіб приготування" },
        { id: "C", text: "Історія походження страви" },
        { id: "D", text: "Час приготування" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "Чому важливо дотримуватись мовних правил при написанні рецепту?",
      options: [
        { id: "A", text: "Щоб зробити текст більш цікавим" },
        { id: "B", text: "Щоб уникнути непорозумінь" },
        { id: "C", text: "Щоб спростити читання" },
        { id: "D", text: "Щоб додати більше деталей" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка з цих фраз найкраще описує важливість кулінарного рецепта в культурі?",
      options: [
        { id: "A", text: "Рецепти лише для кухарів" },
        { id: "B", text: "Рецепти зберігають традиції і передають знання" },
        { id: "C", text: "Рецепти не мають значення" },
        { id: "D", text: "Рецепти – це просто інструкції" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
