import React from "react";
import { useParams, Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { modulesData } from "./modulesData";
import Header from "../header_h/Header";
import Footer from "../footer/Footer";
import BreadcrumbsModulePage from "../breadcrumbs/BreadcrumbsModulePage";

const AuthorPage = ({ userName }) => {
  const { id, subject, author } = useParams();

  // Извлекаем данные модулей на основе параметров
  const modules = modulesData[id]?.[subject]?.[author] || [];

  return (
    <>
      <Header userName={userName} />
      <BreadcrumbsModulePage />
      <div className="classContainer">
        <ListGroup>
          {modules.length > 0 ? (
            modules.map((module) => (
              <ListGroup.Item
                key={module.path}
                action
                className="list-item"
                variant="warning"
                href={`/class/${id}/${subject}/${author}/modules/${module.path}`}
              >
                {module.title}
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item className="list-item">
              Модули не найдены
            </ListGroup.Item>
          )}
        </ListGroup>
      </div>
      <Footer />
    </>
  );
};

export default AuthorPage;
