import {text_mod_10, } from "../text/Text.js";
export const module10 = {
  title:
    "Розділ 2.4. Теми 77-85. Розв’язання задач. Обчислення виразів з іменованими числами, вираженими одиницями маси ",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_10, },
    ]
  };




  