import { text_mod_8_1, text_mod_8_2 } from "../text/Text.js";

export const module08 = {
  title: "Тема 8. Мотиви античності в українській літературі. Валентин Чемерис «Ольвія»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_8_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_8_2,
    },
    {
      id: 3,
      question: "1. Який сон бачила Ольвія напередодні приходу скіфів у місто?",
      options: [
        { id: "А", text: "Їхню з Ясоном зустріч розірвала жахлива кам’яна баба" },
        { id: "Б", text: "Вони з Ясоном одружилися" },
        { id: "В", text: "Ясон переміг жахливе чудовисько" },
        { id: "Г", text: "Вони з Ясоном пливли на кораблі посеред бурхливих хвиль" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "2. Чому ольвіополіти були занепокоєні приїздом скіфів?",
      options: [
        { id: "А", text: "Підозрювали, що орда відбере хліб." },
        { id: "Б", text: "Боялися, що Тапур стане їхнім правителем." },
        { id: "В", text: "Бо кочовики ордою ходять нападати" },
        { id: "Г", text: "Не хотіли укладати зі скіфами торгівельні угоди." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "3. Як Ольвія відреагувала на новину про прибуття скіфів?",
      options: [
        { id: "А", text: "Зраділа та вирішила піти на зустріч із ними." },
        { id: "Б", text: "Залишалася спокійною та не звернула на це уваги." },
        { id: "В", text: "Вибігла на вулицю, щоб дізнатися більше." },
        { id: "Г", text: "Почала готуватися до самозахисту." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "4. Яка причина прибуття Тапура з тисячею вершників до міста Ольвія?",
      options: [
        { id: "А", text: "Укладання угоди про торгівлю зерном." },
        { id: "Б", text: "Завоювання греків" },
        { id: "В", text: "Ритуальне святкування" },
        { id: "Г", text: "Сватання до архонтової доньки" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 7,
      question: "5. Що в перекладі з грецької означає назва «Ольвія»?",
      options: [
        { id: "А", text: "багата" },
        { id: "Б", text: "щаслива" },
        { id: "В", text: "радісна" },
        { id: "Г", text: "достойна" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "6. Яким ремеслом володіли предки Родона?",
      options: [
        { id: "А", text: "гончарством" },
        { id: "Б", text: "кравецтвом" },
        { id: "В", text: "шевством" },
        { id: "Г", text: "кожум’яцтвом" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
  ],
};
