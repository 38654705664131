import { text_mod_41_1, text_mod_41_2 } from "../text/Text.js";

export const module41 = {
  title: "Тема 41. Даніель Дефо «Життя і незвичайні та дивовижні пригоди Робінзона Крузо»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_41_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_41_2,
    },
    {
      id: 3,
      question: "Прототипом Робінзона був",
      options: [
        { id: "А", text: "сам автор" },
        { id: "Б", text: "шотландський моряк Селькірк" },
        { id: "В", text: "моряк Роджерс" },
        { id: "Г", text: "капітан Едуард Кук" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "Що, за словами Робінзона, лікувало його душу?",
      options: [
        { id: "А", text: "заняття справами" },
        { id: "Б", text: "спілкування з тваринами" },
        { id: "В", text: "читання Біблії" },
        { id: "Г", text: "ведення щоденника" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "У романі розкрито всі проблеми, ОКРІМ:",
      options: [
        { id: "А", text: "самотності й дружби" },
        { id: "Б", text: "людської праці" },
        { id: "В", text: "людини й природи" },
        { id: "Г", text: "соціальної нерівності" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "Що з переліченого для Робінзона виявилося непотрібним на острові?",
      options: [
        { id: "А", text: "інструменти" },
        { id: "Б", text: "книги" },
        { id: "В", text: "гроші" },
        { id: "Г", text: "одяг" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "Робінзон Крузо був упевнений, що “не буває зла без …”",
      options: [
        { id: "А", text: "покарання" },
        { id: "Б", text: "добра" },
        { id: "В", text: "наслідків" },
        { id: "Г", text: "причини" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "Автором української робінзонади є",
      options: [
        { id: "А", text: "Всеволод Нестайко" },
        { id: "Б", text: "Тарас Шевченко" },
        { id: "В", text: "Григорій Сковорода" },
        { id: "Г", text: "Григорій Квітка-Основ'яненко" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
  ],
};
