import {text_mod_15, } from "../text/Text.js";
export const module15 = {
  title:
    "Розділ 3.5. Теми 124-131. Закріплення віднімання чисел виду 54 – 20. Розв’язання задач.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_15, },
    ]
  };




