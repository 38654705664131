import { text_mod_28_1, text_mod_28_2, text_mod_28_3 } from "../text/Text.js";

export const module28 = {
  title: "З української драматургії ХІХ століття",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_28_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_28_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_28_3,
    },
    {
      id: 4,
      question: "Розлогі описи, авторські відступи у драматичних творах замінюють на:",
      options: [
        { id: "A", text: "авторські пояснення" },
        { id: "B", text: "ремарки" },
        { id: "C", text: "репліки" },
        { id: "D", text: "монологи" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Визнач вид мистецтва, що не задіяний у створенні сценічного образу:",
      options: [
        { id: "A", text: "живопис" },
        { id: "B", text: "музика" },
        { id: "C", text: "кіно" },
        { id: "D", text: "хореографія" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 6,
      question: "Основними зображально-виражальними засобами розкриття характеру героїв у п'єсах є:",
      options: [
        { id: "A", text: "мова персонажів, інтонація, жести, міміка" },
        { id: "B", text: "авторські ремарки" },
        { id: "C", text: "портрети персонажів" },
        { id: "D", text: "метафори, епітети, порівняння" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 7,
      question: "Роль режисера у підготовці вистави:",
      options: [
        { id: "A", text: "дослівно передати текст драматичного твору" },
        { id: "B", text: "встановити емоційний контакт із глядачами" },
        { id: "C", text: "видозмінити, осучаснити текст п’єси" },
        { id: "D", text: "знайти ефективні та оригінальні прийоми, щоб передати задум письменника" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 8,
      question: "Розташуйте в хронологічному порядку етапи становлення українського театру:",
      options: [
        { id: "A", text: "стаціонарні театри" },
        { id: "B", text: "театралізовані народні свята та обряди" },
        { id: "C", text: "театральні гуртки (трупи)" },
        { id: "D", text: "мандрівні лялькові театри (вертепи)" },
      ],
      correctAnswer: "1-B; 2-D; 3-C; 4-A",
      isTest: true,
    },
    {
      id: 9,
      question: "Правило трьох єдностей у класичній виставі – це єдність:",
      options: [
        { id: "A", text: "персонажа, місця, дії" },
        { id: "B", text: "місця, часу, дії" },
        { id: "C", text: "теми, дії, сюжету" },
        { id: "D", text: "ідеї, сюжету, дії" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
