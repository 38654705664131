import { text_mod_19_1, text_mod_19_2 } from "../text/Text.js";

export const module19 = {
  title: "Тема 19. Особливості середньовічної доби на Сході. Омар Хаям. Рубаї",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_19_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_19_2,
    },
    {
      id: 3,
      question: "1. Яку форму поезії вдосконалив Омар Хаям?",
      options: [
        { id: "А", text: "газель" },
        { id: "Б", text: "касиду" },
        { id: "В", text: "рубаї" },
        { id: "Г", text: "маснаві" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "2. Який внесок Омар Хаям зробив у розвиток математики?",
      options: [
        { id: "А", text: "Винайшов арабські цифри" },
        { id: "Б", text: "Відкрив біном, пізніше повторно виведений Ньютоном" },
        { id: "В", text: "Створив теорію ймовірності" },
        { id: "Г", text: "Розробив основи тригонометрії" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "3. Чому Омар Хаям змушений був часто переїжджати?",
      options: [
        { id: "А", text: "Через політичні переслідування" },
        { id: "Б", text: "У пошуках кращої роботи" },
        { id: "В", text: "Через вільнодумство, яке дратувало консервативну спільноту" },
        { id: "Г", text: "Щоб уникнути сімейних обов'язків" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "4. Який календар розробив Омар Хаям?",
      options: [
        { id: "А", text: "місячний" },
        { id: "Б", text: "григоріанський" },
        { id: "В", text: "юліанський" },
        { id: "Г", text: "сонячний" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 7,
      question: "5. Як можна охарактеризувати філософські погляди Омара Хаяма?",
      options: [
        { id: "А", text: "суворо релігійні" },
        { id: "Б", text: "поєднання розуму та віри, природи й Бога" },
        { id: "В", text: "повністю атеїстичні" },
        { id: "Г", text: "офіційна доктрина ісламу" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "6. Як твори Омара Хаяма стали відомими у світі?",
      options: [
        { id: "А", text: "через арабські переклади в середні віки" },
        { id: "Б", text: "завдяки перекладам Едварда Фіцджеральда у XIX столітті" },
        { id: "В", text: "через турецькі переклади в епоху Відродження" },
        { id: "Г", text: "завдяки французьким перекладам у XVIII столітті" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
