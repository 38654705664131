import Table from "react-bootstrap/Table";

import "./StudentsTable.css";

function StudentsTable() {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Ім'я</th>
          <th>Прізвище</th>
          <th>№1</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Тарас</td>
          <td>Опанасенко</td>
          <td style={{ color: "green" }}>+</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Марія</td>
          <td>Зозуля</td>
          <td style={{ color: "green" }}>+</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Анастасія</td>
          <td>Буремісто</td>
          <td style={{ color: "red" }}>-</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Володимир</td>
          <td>Мельничук</td>
          <td style={{ color: "green" }}>+</td>
        </tr>
      </tbody>
    </Table>
  );
}

export default StudentsTable;
