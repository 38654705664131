import { text_mod_18_1, text_mod_18_2, text_mod_18_3 } from "../text/Text.js";

export const module18 = {
  title: "Кримські татари та українці в сучасній українській літературі",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_18_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_18_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_18_3,
    },
    {
      id: 4,
      question: "Грицик і Санько обдурили слуг пана Кобильського",
      options: [
        { id: "A", text: "вказавши їм неправильну дорогу до села" },
        { id: "B", text: "оголосивши про набіг татар" },
        { id: "C", text: "викравши їхніх коней" },
        { id: "D", text: "попередивши односельців" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 5,
      question: "Санько має надзвичайні здібності –",
      options: [
        { id: "A", text: "розуміє мову тварин" },
        { id: "B", text: "вміє рухати предмети" },
        { id: "C", text: "навіює думки іншим істотам" },
        { id: "D", text: "відвертає стріли ворога" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 6,
      question: "Дід Кудьма був",
      options: [
        { id: "A", text: "кошовим отаманом" },
        { id: "B", text: "вивідником" },
        { id: "C", text: "сотником" },
        { id: "D", text: "знахарем" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 7,
      question: "Грицик засмутився, що Санько стане характерником, бо",
      options: [
        { id: "A", text: "боявся ворожбитів" },
        { id: "B", text: "заздрив товаришеві" },
        { id: "C", text: "не хотів втрачати справжнього друга" },
        { id: "D", text: "не вірив у здібності хлопця" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 8,
      question: "Швайка довіряв Рашиту, бо",
      options: [
        { id: "A", text: "вони були давніми знайомими" },
        { id: "B", text: "вони разом воювали" },
        { id: "C", text: "їх обох виховувала мати Рашита" },
        { id: "D", text: "їх матері були разом у полоні" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 9,
      question: "“...невловимий, хоробрий і всюдисущий…” – так автор описує",
      options: [
        { id: "A", text: "Рашита" },
        { id: "B", text: "Грицика" },
        { id: "C", text: "діда Кудьму" },
        { id: "D", text: "Пилипа Швайку" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
  ],
};
