import { text_mod_45_1, text_mod_45_2 } from "../text/Text.js";

export const module45 = {
  title: "Тема 45. Генріх Гейне «Книга пісень»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_45_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_45_2,
    },
    {
      id: 3,
      question: "Назву для своєї збірки  “Книга пісень” Гайне запозичив у",
      options: [
        { id: "А", text: "Байрона" },
        { id: "Б", text: "Петрарки" },
        { id: "В", text: "Біблії" },
        { id: "Г", text: "Шекспіра" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "Кохання у творах Гайне в основному",
      options: [
        { id: "А", text: "щире" },
        { id: "Б", text: "взаємне" },
        { id: "В", text: "нерозділене" },
        { id: "Г", text: "божевільне" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "Як елемент композиції в поезії “Коли розлучаються двоє…” автор використав",
      options: [
        { id: "А", text: "порівняння" },
        { id: "Б", text: "контраст" },
        { id: "В", text: "алегорію" },
        { id: "Г", text: "антитезу" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "“Коли розлучаються двоє…” входить до",
      options: [
        { id: "А", text: "першої частини “Юнацькі страждання”" },
        { id: "Б", text: "другої частини “Ліричне інтермецо”" },
        { id: "В", text: "третьої частини “Знову на Батьківщині”" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "Особливими ознаками романтизму Гайне є всі названі, ОКРІМ:",
      options: [
        { id: "А", text: "Автор звертається до фольклорних мотивів." },
        { id: "Б", text: "Тема кохання в його творчості має соціальне забарвлення." },
        { id: "В", text: "Лірика Гайне пройнята музикальністю, закоханістю, єдністю з природою." },
        { id: "Г", text: "Гайне писав тільки про кохання і природу." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 8,
      question: "Мелодію до вірша “Коли розлучаються двоє…” написав",
      options: [
        { id: "А", text: "Микола Лисенко" },
        { id: "Б", text: "Іван Козловський" },
        { id: "В", text: "Іван Паторжинський" },
        { id: "Г", text: "Максим Стависький" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
  ],
};
