import {text_mod_4, } from "../text/Text.js";
export const module04 = {
  title:
    "Розділ 1.4. Теми 26-32. Склад чисел. Віднімання чисел. Вимірювання довжин. Узагальнення і систематизація знань. ",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_4, },
    ]
  };