import { text_mod_24_1, text_mod_24_2 } from "../text/Text.js";

export const module24 = {
  title: "Тема 24. Мігель де Сервантес Сааведра «Дон Кіхот», I частина",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_24_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_24_2,
    },
    {
      id: 3,
      question: "1. Яка головна причина того, що Дон Кіхот вирішив стати мандрівним лицарем?",
      options: [
        { id: "А", text: "бажання розбагатіти" },
        { id: "Б", text: "прагнення до слави" },
        { id: "В", text: "надмірне захоплення лицарськими романами" },
        { id: "Г", text: "наказ короля" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "2. Що символізують вітряки, з якими бореться Дон Кіхот?",
      options: [
        { id: "А", text: "реальних ворогів" },
        { id: "Б", text: "уявні перешкоди" },
        { id: "В", text: "природні сили" },
        { id: "Г", text: "технічний прогрес" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "3. Яку роль відіграє Санчо Панса у романі?",
      options: [
        { id: "А", text: "антагоніста Дон Кіхота" },
        { id: "Б", text: "вірного слуги та супутника" },
        { id: "В", text: "мудрого наставника" },
        { id: "Г", text: "комічного персонажа" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "4. Як можна охарактеризувати стиль оповіді у романі?",
      options: [
        { id: "А", text: "реалістичний" },
        { id: "Б", text: "трагічний" },
        { id: "В", text: "іронічно-гумористичний" },
        { id: "Г", text: "романтичний" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "5. Яку головну ідею втілює образ Дон Кіхота?",
      options: [
        { id: "А", text: "критику лицарства" },
        { id: "Б", text: "протистояння ідеалізму та реальності" },
        { id: "В", text: "засудження божевілля" },
        { id: "Г", text: "уславлення героїзму" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "6. Чому роман 'Дон Кіхот' вважається одним із перших зразків сучасного роману?",
      options: [
        { id: "А", text: "Через велику популярність" },
        { id: "Б", text: "Має складну композицію" },
        { id: "В", text: "У ньому поєднано реальне й фантастичне" },
        { id: "Г", text: "Через психологізм та багатоплановість образів" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
  ],
};
