export const module14 = {
  title: "Однорідні додатки. Скарби України",
  questions: [
    {
      id: 1,
      question: "У якому реченні є однорідні додатки?",
      options: [
        { id: "А", text: "Україна славиться своїми чудовими пейзажами та історичними пам'ятками." },
        { id: "Б", text: "Скіфське золото зберігається в музеях України та за кордоном." },
        { id: "В", text: "Археологи знайшли стародавні монети, прикраси та зброю в курганах України." },
        { id: "Г", text: "Карпатські гори приваблюють туристів своєю красою." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question: "Визначте речення з однорідними додатками, вираженими різними частинами мови:",
      options: [
        { id: "А", text: "Печерні міста Криму вражають своєю архітектурою та історією." },
        { id: "Б", text: "Софія Київська зберігає фрески, мозаїки та унікальні артефакти." },
        { id: "В", text: "Дослідники вивчають трипільську культуру та її вплив на розвиток України." },
        { id: "Г", text: "Скарби України включають матеріальні цінності та духовну спадщину." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 3,
      question: "У якому реченні однорідні додатки з'єднані повторюваним сполучником?",
      options: [
        { id: "А", text: "Туристи відвідують і замки, і фортеці, і палаци України." },
        { id: "Б", text: "Скарбами України є чорноземи, ліси та річки." },
        { id: "В", text: "Музеї зберігають картини, скульптури, старовинні книги." },
        { id: "Г", text: "Археологи знайшли золото, срібло та бронзу в стародавніх похованнях." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "Яке речення містить однорідні додатки з узагальнюючим словом?",
      options: [
        { id: "А", text: "Скіфське золото, трипільська кераміка, давньоруські ікони – все це зберігається в музеях України." },
        { id: "Б", text: "Україна багата на корисні копалини: вугілля, залізну руду, газ." },
        { id: "В", text: "Карпати славляться мінеральними джерелами та чистим повітрям." },
        { id: "Г", text: "Дністровський каньйон вражає туристів своєю красою та величчю." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "У якому реченні однорідні додатки виражені іменниками в різних відмінках?",
      options: [
        { id: "А", text: "Скарби України включають золото скіфів, трипільську кераміку та козацьку зброю." },
        { id: "Б", text: "Археологи знайшли монети, прикраси та зброю в курганах України." },
        { id: "В", text: "Туристи захоплюються красою Карпат, величчю Дніпра та багатством українських музеїв." },
        { id: "Г", text: "Україна пишається своїми історичними пам'ятками та природними багатствами." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "Визначте речення, в якому однорідні додатки відокремлені комами:",
      options: [
        { id: "А", text: "Скарби України включають як матеріальні цінності так і духовну спадщину." },
        { id: "Б", text: "Археологи досліджують кургани, древні поселення, печерні міста України." },
        { id: "В", text: "Туристи відвідують і музеї і замки і природні парки України." },
        { id: "Г", text: "Україна багата на золото срібло бурштин та інші корисні копалини." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
