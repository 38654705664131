export const text_mod_1 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYJEb7T8a4TfOcs2rA?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_2 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYJFELO3bSV8KkYmEA?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_3 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYJIN-CjFJdvfeRO1g?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_4 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYJG5IH6YWYvo92cvg?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_5 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYJH0emyyy7RodcNBA?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_6 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYJH0emyyy7RodcNBA?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_7 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYJK2wIQ7eLQ1R0RIg?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_8 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYJLSpa4bCYW_3oUwA?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_9 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYI9dHNJFL8yPrviUw?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;



export const text_mod_10 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYI-hlx-S99bNFqaUQ?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;


export const text_mod_11 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYI83Jg86phb3LmhSA?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_12 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYI_8wUOS6htto65_w?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_13 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYJA7JmTXt1F1jMShA?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_14 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYJBzucDusFLrZ8FKg?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_15 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYJCa80fxxMQ1Z03yw?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_16 = `<p>1. Ознайомся з матеріалом і виконай завдання у зошиті.</p> <p></p>
<iframe
src="https://1drv.ms/p/s!ApWcD4FjFxOLgYJD5XxXkuE3GopxOg?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;
