export const module33 = {
  title: "Блог",
  questions: [
    {
      id: 1,
      question: "Яка основна тема тексту про блоги?",
      options: [
        { id: "A", text: "Види соціальних мереж" },
        { id: "B", text: "Як створити успішний блог" },
        { id: "C", text: "Важливість позитивного мислення" },
        { id: "D", text: "Різновиди відео контенту" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з наведених характеристик не відноситься до блогу?",
      options: [
        { id: "A", text: "Регулярне оновлення контенту" },
        { id: "B", text: "Відсутність можливості коментування" },
        { id: "C", text: "Включення мультимедійних елементів" },
        { id: "D", text: "Наявність заголовка та дати" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з наведених порад є найбільш важливою для залучення підписників до блогу?",
      options: [
        { id: "A", text: "Викладати контент рідко" },
        { id: "B", text: "Використовувати складні терміни" },
        { id: "C", text: "Регулярно публікувати нові пости" },
        { id: "D", text: "Ігнорувати коментарі від підписників" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка з наведених фраз найкраще ілюструє важливість позитивного спілкування в соцмережах?",
      options: [
        { id: "A", text: "Не святі горшки ліплять." },
        { id: "B", text: "Добрий початок – половина діла." },
        { id: "C", text: "Знання людині, що крила пташині." },
        { id: "D", text: "Людей питай, а свій розум май!" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Які з наведених стратегій можуть допомогти у боротьбі з негативними коментарями в блозі?",
      options: [
        { id: "A", text: "Ігнорувати всі коментарі" },
        { id: "B", text: "Відповідати з агресією" },
        { id: "C", text: "Ввічливо відповідати та пояснювати свою точку зору" },
        { id: "D", text: "Видаляти всі негативні коментарі" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 6,
      question: "Як позитивне мислення може вплинути на ведення блогу?",
      options: [
        { id: "A", text: "Знижує креативність і мотивацію" },
        { id: "B", text: "Допомагає залучити більше читачів і підписників" },
        { id: "C", text: "Призводить до частого конфлікту з читачами" },
        { id: "D", text: "Не має жодного впливу на блог" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
