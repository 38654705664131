export const module11 = {
  title: "БЕЗОСОБОВІ РЕЧЕННЯ. З любов’ю до свого",
  questions: [
    {
      id: 1,
      question: "Яке з наступних речень є безособовим і виражає любов до української природи?",
      options: [
        { id: "А", text: "Я люблю українські поля." },
        { id: "Б", text: "Мені подобається гуляти лісами України." },
        { id: "В", text: "Захоплює красою українське небо." },
        { id: "Г", text: "Ми захоплюємося краєвидами України." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question: "У якому безособовому реченні йдеться про українську культуру?",
      options: [
        { id: "А", text: "Нам пощастило народитися в Україні." },
        { id: "Б", text: "Українські традиції передаються з покоління в покоління." },
        { id: "В", text: "Пишаюся бути українцем." },
        { id: "Г", text: "Дихається легко українським повітрям." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 3,
      question: "Визначте безособове речення, що виражає патріотичні почуття:",
      options: [
        { id: "А", text: "Україна - це наша Батьківщина." },
        { id: "Б", text: "Серце сповнюється гордістю за Україну." },
        { id: "В", text: "Ми любимо нашу країну." },
        { id: "Г", text: "Українці - волелюбний народ." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "Яке безособове речення найкраще описує атмосферу української гостинності?",
      options: [
        { id: "А", text: "В українській хаті завжди затишно." },
        { id: "Б", text: "Українці дуже гостинні." },
        { id: "В", text: "Ми радо зустрічаємо гостей." },
        { id: "Г", text: "В Україні люблять приймати гостей." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "Виберіть безособове речення, що передає емоційний стан, пов'язаний з любов'ю до України:",
      options: [
        { id: "А", text: "Я пишаюся своєю країною." },
        { id: "Б", text: "Нам радісно жити в Україні." },
        { id: "В", text: "Українці люблять свою Батьківщину." },
        { id: "Г", text: "Щемить у серці від любові до рідної землі." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "Яке з наступних безособових речень найкраще виражає почуття єдності з Україною?",
      options: [
        { id: "А", text: "Ми - частина великої української родини." },
        { id: "Б", text: "Українці завжди тримаються разом." },
        { id: "В", text: "Відчувається міцний зв'язок з рідною землею." },
        { id: "Г", text: "Я належу до українського народу." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
  ],
};
