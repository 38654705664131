import {text_mod_3, } from "../text/Text.js";

export const module05 = {

  title: "Урок 5. Безпека в населеному пункті. Вплив забрудненого середовища на життя і здоров’я. Значення води для життя, здоров’я та добробуту людини",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_3, },
      {
        id: 2,
        question:
          "Оберіть правильні та неправильні відповіді. Щоб зменшити забруднення води, потрібно:",
        isGame: true,
  
        initialWords: [
          {
            id: 1,
            text: "Не використовувати синтетичні добрива й отрутохімікати.",
            type: "leftBucket",
          },
          {
            id: 2,
            text: "Використовувати якомога менше хімічних засобів у побуті.",
            type: "leftBucket",
          },
          {
            id: 3,
            text: "Змивати ліки тільки в каналізацію.",
            type: "rightBucket",
          },
          {
            id: 4,
            text: "Зменшення використання пластика.",
            type: "leftBucket",
          },
  
          {
            id: 5,
            text: "Змивати побутові відходи у каналізацію.",
            type: "rightBucket",
          },
          {
            id: 6,
            text: "Економити воду.",
            type: "leftBucket",
          },
        ],
        leftBucketTitle: "Правильно",
        rightBucketTitle: "Неправильно",
      },
    
      {
        id: 3,
        question:
          "Розташуйте правильні та неправильні відповіді у відповідні групи. Щоб поліпшити якість питної води у децентралізованих джерелах водопостачання, потрібно:",
        isGame: true,
  
        initialWords: [
          {
            id: 1,
            text: "Проводити дезінфекцію колодязів 1 раз на рік.",
            type: "leftBucket",
          },
          {
            id: 2,
            text: "Проводити дезінфекцію колодязів 2 рази на рік.",
            type: "leftBucket",
          },
          {
            id: 3,
            text: "Проводити дезінфекцію колодязів 1 раз на 5 років.",
            type: "rightBucket",
          },
          {
            id: 4,
            text: "Ніколи не проводити дезінфекцію колодязів.",
            type: "rightBucket",
          },
        ],
        leftBucketTitle: "Правильно",
        rightBucketTitle: "Неправильно",
      },

      {
        id: 4,
        question:
          "Визначте правильні та неправильні відповіді. Джерела забруднення води – це:",
        isGame: true,
  
        initialWords: [
          {
            id: 1,
            text: "Хімікати й добрива з полів.",
            type: "leftBucket",
          },
          {
            id: 2,
            text: "Побутові стічні води.",
            type: "leftBucket",
          },
          {
            id: 3,
            text: "Веслування на байдарках, каное.",
            type: "rightBucket",
          },
          {
            id: 4,
            text: "Плавання, рухливі ігри на воді.",
            type: "rightBucket",
          },
          {
            id: 5,
            text: "Сміття на березі водойм.",
            type: "leftBucket",
          },
          {
            id: 6,
            text: "Виливи пального від водного транспорту.",
            type: "leftBucket",
          },
          {
            id: 7,
            text: "Футбольні, волейбольні майданчики біля річок на пляжах.",
            type: "rightBucket",
          },
        ],
        leftBucketTitle: "Правильні",
        rightBucketTitle: "Неправильні",
      },
  ],
};
