import {text_mod_16, } from "../text/Text.js";
export const module16 = {
  title:
    "Розділ 3.6. Теми 132-140. Нумерація чисел у межах 100.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_16, },
    ]
  };




