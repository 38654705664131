import React, { useEffect, useRef } from "react";
import "./MathModulePage.css";

const MathModulePage = ({ path }) => {
  const iframeRef = useRef(null);

  const updateIframeHeight = () => {
    if (iframeRef.current) {
      // Получаем документ iframe
      const iframeDocument =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow.document;
      // Устанавливаем высоту iframe равной высоте содержимого
      iframeRef.current.style.height = `${iframeDocument.body.scrollHeight}px`;
    }
  };

  useEffect(() => {
    const iframe = iframeRef.current;

    // Обновляем высоту при загрузке содержимого
    iframe.addEventListener("load", updateIframeHeight);

    // Убираем обработчик события при размонтировании компонента
    return () => {
      iframe.removeEventListener("load", updateIframeHeight);
    };
  }, []);
  return (
    <iframe
      ref={iframeRef}
      className="math_ifr"
      src={path}
      style={{ border: "none" }}
      title="Mathematics"
    ></iframe>
  );
};

export default MathModulePage;
