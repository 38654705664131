export const modulesData = {
  1: {
    math: {
      prosh: [
        { path: "module01", title: "Розділ 1.1. Теми 1-9. Властивості та відношення предметів" },
        { path: "module02", title: "Розділ 1.2. Теми 10-18. Числа 1 - 10" },
        { path: "module03", title: "Розділ 1.3. Теми 19-25. Склад чисел. Додавання чисел" },
        { path: "module04", title: "Розділ 1.4. Теми 26-32. Склад чисел. Віднімання чисел. Вимірювання довжин. Узагальнення і систематизація знань" },
        { path: "module05", title: "Розділ 1.5. Теми 33-36. Додавання і віднімання в межах 10" },
        { path: "module06", title: "Розділ 1.6. Теми 37-47. Додавання і віднімання в межах 10" },
        { path: "module07", title: "Розділ 2.1. Теми 48-57. Задачі" },
        { path: "module08", title: "Розділ 2.2. Теми 58-66. Маса. Одиниця вимірювання маси. Додавання і віднімання іменованих чисел" },
        { path: "module09", title: "Розділ 2.3. Теми 67-76. Розв’язання задач. Обчислення виразів" },
        { path: "module10", title: "Розділ 2.4. Теми 77-85. Розв’язання задач. Обчислення виразів з іменованими числами, вираженими одиницями маси" },
        { path: "module11", title: "Розділ 2.5. Теми 86-94. Порівняння чисел другого десятка. Вимірювання і порівняння довжин відрізків" },
        { path: "module12", title: "Розділ 3.1. Теми 95-102. Лічба десятками в межах 100. Обчислення виразів. Розв’язання задач" },
        { path: "module13", title: "Розділ 3.2. Теми 103-113. Віднімання чисел виду 48 – 8, 48 – 40. Взаємозв’язок дій додавання і віднімання" },
        { path: "module14", title: "Розділ 3.4. Теми 114-123. Рік. Місяць. Тиждень. Доба. Календар" },
        { path: "module15", title: "Розділ 3.5. Теми 124-131. Закріплення віднімання чисел виду 54 – 20. Розв’язання задач" },
        { path: "module16", title: "Розділ 3.6. Теми 132-140. Нумерація чисел у межах 100" },
        { path: "task01", title: "Додаткове завдання тип 1" },
        { path: "task02", title: "Додаткове завдання тип 2" },
        { path: "task03", title: "Додаткове завдання тип 3" },
        { path: "task04", title: "Додаткове завдання тип 4" },
        { path: "task05", title: "Додаткове завдання тип 5" },
      ],
    },
  },
  8: {
    ukrmova: {
      avr: [
        { path: "module01", title: "Завдання до 01" },
        { path: "module02", title: "Завдання до 02" },
        { path: "module03", title: "Завдання до 03" },
        { path: "module2", title: "Завдання до §2" },
        { path: "module3", title: "Завдання до §3" },
        { path: "module4", title: "Завдання до §4" },
        { path: "module5", title: "Завдання до §5" },
        { path: "module6", title: "Завдання до §6" },
        { path: "module7", title: "Завдання до §7" },
        { path: "module8", title: "Завдання до §8" },
        { path: "module9", title: "Завдання до §9" },
        { path: "module18_19", title: "Завдання до §18-19" },
        { path: "module20_21", title: "Завдання до §20-21" },
        { path: "module23", title: "Завдання до §23" },
        { path: "module41", title: "Завдання до §41" },
        { path: "module42_43", title: "Завдання до §42-43" },
        { path: "module45", title: "Завдання до §45" },
        { path: "module47", title: "Завдання до §47" },
        { path: "module48", title: "Завдання до §48" },
        { path: "module50", title: "Завдання до §50" },
        { path: "module52_53", title: "Завдання до §52-53" },
        { path: "module54_55", title: "Завдання до §54-55" },
        { path: "module57", title: "Завдання до §57" },
        { path: "module59_60", title: "Завдання до §59-60" },
        { path: "module62_63", title: "Завдання до §62-63" },
        { path: "module65_66", title: "Завдання до §65-66" },
        { path: "module69", title: "Завдання до §69" },
        { path: "module70_71", title: "Завдання до §70-71" },
        { path: "module73", title: "Завдання до §73" },
        { path: "module74_75", title: "Завдання до §74-75" },
        { path: "module77", title: "Завдання до §77" },
        { path: "module78_79", title: "Завдання до §78-79" },
        { path: "module80", title: "Завдання до §80" },
        { path: "module81", title: "Завдання до §81" },
        { path: "module82", title: "Завдання до §82" },
      ],

      gol: [
        { path: "module07", title: "Завдання 1" },
        { path: "module08", title: "Завдання 2" },
        { path: "module09", title: "Завдання 3" },
        { path: "module10", title: "Завдання 4" },
        { path: "module11", title: "Завдання 5" },
        { path: "module12", title: "Завдання 6" },
        { path: "module13", title: "Завдання 7" },
        { path: "module14", title: "Завдання 8" },
        { path: "module15", title: "Завдання 9" },
        { path: "module16", title: "Завдання 10" },
        { path: "module17", title: "Завдання 11" },
        { path: "module18", title: "Завдання 12" },
        { path: "module19", title: "Завдання 13" },
        { path: "module20", title: "Завдання 14" },
        { path: "module21", title: "Завдання 15" },
        { path: "module22", title: "Завдання 16" },
        { path: "module23", title: "Завдання 17" },
        { path: "module24", title: "Завдання 18" },
        { path: "module25", title: "Завдання 19" },
        { path: "module26", title: "Завдання 20" },
        { path: "module27", title: "Завдання 21" },
        { path: "module28", title: "Завдання 22" },
        { path: "module29", title: "Завдання 23" },
        { path: "module30", title: "Завдання 24" },
        { path: "module31", title: "Завдання 25" },
        { path: "module32", title: "Завдання 26" },
        { path: "module33", title: "Завдання 27" },
        { path: "module34", title: "Завдання 28" },
        { path: "module35", title: "Завдання 29" },
        { path: "module36", title: "Завдання 30" },
        { path: "module37", title: "Завдання 31" },
        { path: "module38", title: "Завдання 32" },
        { path: "module39", title: "Завдання 33" },
        { path: "module40", title: "Завдання 34" },
        { path: "module41", title: "Завдання 35" },
        { path: "module42", title: "Завдання 36" },
        { path: "module43", title: "Завдання 37" },
        { path: "module44", title: "Завдання 38" },
        { path: "module01", title: "Додаткове завдання 1" },
        { path: "module02", title: "Додаткове завдання 2" },
        { path: "module03", title: "Додаткове завдання 3" },
        { path: "module04", title: "Додаткове завдання 4" },
        { path: "module05", title: "Додаткове завдання 5" },
        { path: "module06", title: "Додаткове завдання 6" },
      ],
    },
    ukrlit: {
      avr: [
        { path: "module01", title: "Завдання до §1" },
        { path: "module02", title: "Завдання до §2-3" },
        { path: "module03", title: "Завдання до §4-5" },
        { path: "module04", title: "Завдання до §6-7" },
        { path: "module05", title: "Завдання до §8-9" },
        { path: "module06", title: "Завдання до §10-11" },
        { path: "module07", title: "Завдання до $12-14" },
        { path: "module08", title: "Завдання до §15" },
        { path: "module09", title: "Завдання до §16-17" },
        { path: "module10", title: "Завдання до §18" },
        { path: "module11", title: "Завдання до §19-20" },
        { path: "module22_23", title: "Завдання до §22-23" },
        { path: "module24_25", title: "Завдання до §24-25" },
        { path: "module26_27", title: "Завдання до §26-27" },
        { path: "module29_31", title: "Завдання до §29-31" },
        { path: "module32_34", title: "Завдання до §32-34" },
        { path: "module35_38", title: "Завдання до §35-38" },
        { path: "module39_41", title: "Завдання до §39-41" },
      ],
      yac: [
        { path: "module01", title: "Завдання до §1" },
        { path: "module02", title: "Завдання до §2" },
        { path: "module03", title: "Завдання до §3" },
        { path: "module04", title: "Завдання до §4" },
        { path: "module05", title: "Завдання до §5" },
        { path: "module06", title: "Завдання до §6" },
        { path: "module07", title: "Завдання до $7" },
        { path: "module08", title: "Завдання до §8" },
        { path: "module09", title: "Завдання до §9" },
        { path: "module10", title: "Завдання до §10" },
        { path: "module11", title: "Завдання до §11" },
        { path: "module12", title: "Завдання до §12" },
        { path: "module13", title: "Завдання до §13" },
        { path: "module14", title: "Завдання до §14" },
        { path: "module15", title: "Завдання до §15" },
        { path: "module16", title: "Завдання до §16" },
        { path: "module17", title: "Завдання до §17" },
        { path: "module18", title: "Завдання до §18" },
        { path: "module19", title: "Завдання до §19" },
        { path: "module20", title: "Завдання до §20" },
        { path: "module21", title: "Завдання до §21" },
        { path: "module22", title: "Завдання до §22" },
        { path: "module23", title: "Завдання до §23" },
        { path: "module24", title: "Завдання до §24" },
        { path: "module25", title: "Завдання до §25" },
        { path: "module26", title: "Завдання до §26" },
        { path: "module27", title: "Завдання до §27" },
        { path: "module28", title: "Завдання до §28" },
        { path: "module29", title: "Завдання до §29" },
        { path: "module30", title: "Завдання до §30" },
      ],
    },
    int: {
      yac: [
        { path: "module01", title: "Завдання до §1" },
        { path: "module02", title: "Завдання до §2" },
        { path: "module03", title: "Завдання до §3" },
        { path: "module04", title: "Завдання до §4" },
        { path: "module05", title: "Завдання до §5" },
        { path: "module06", title: "Завдання до §6" },
        { path: "module07", title: "Завдання до $7" },
        { path: "module08", title: "Завдання до §8" },
        { path: "module09", title: "Завдання до §9" },
        { path: "module10", title: "Завдання до §10" },
        { path: "module11", title: "Завдання до §11" },
        { path: "module12", title: "Завдання до §12" },
        { path: "module13", title: "Завдання до §13" },
        { path: "module14", title: "Завдання до §14" },
        { path: "module15", title: "Завдання до §15" },
        { path: "module16", title: "Завдання до §16" },
        { path: "module17", title: "Завдання до §17" },
        { path: "module18", title: "Завдання до §18" },
        { path: "module19", title: "Завдання до §19" },
        { path: "module20", title: "Завдання до §20" },
        { path: "module21", title: "Завдання до §21" },
        { path: "module22", title: "Завдання до $22" },
        { path: "module23", title: "Завдання до §23" },
        { path: "module24", title: "Завдання до §24" },
        { path: "module25", title: "Завдання до §25" },
        { path: "module26", title: "Завдання до §26" },
        { path: "module27", title: "Завдання до §27" },
        { path: "module28", title: "Завдання до §28" },
        { path: "module29", title: "Завдання до §29" },
        { path: "module30", title: "Завдання до §30" },
        { path: "module31", title: "Завдання до §31" },
        { path: "module32", title: "Завдання до §32" },
        { path: "module33", title: "Завдання до §33" },
        { path: "module34", title: "Завдання до §34" },
        { path: "module35", title: "Завдання до §35" },
        { path: "module36", title: "Завдання до §36" },
        { path: "module37", title: "Завдання до §37" },
        { path: "module38", title: "Завдання до §38" },
        { path: "module39", title: "Завдання до §39" },
        { path: "module40", title: "Завдання до §40" },
        { path: "module41", title: "Завдання до §41" },
        { path: "module42", title: "Завдання до §42" },
        { path: "module43", title: "Завдання до §43" },
        { path: "module44", title: "Завдання до §44" },
        { path: "module45", title: "Завдання до §45" },
        { path: "module46", title: "Завдання до §46" },
        { path: "module47", title: "Завдання до §47" },
        { path: "module48", title: "Завдання до §48" },
        { path: "module49", title: "Завдання до §49" },
        { path: "module50", title: "Завдання до §50" },
        { path: "module51", title: "Завдання до §51" },
      ],
    },
    zbd: {
      pol: [
        { path: "module01_02", title: "Завдання до уроку 1-2" },
        { path: "module03_04", title: "Завдання до уроку 3-4" },
        { path: "module05", title: "Завдання до уроку 5" },
        { path: "module06", title: "Завдання до уроку 6" },
        { path: "module07_08", title: "Завдання до уроку 7-8" },
        { path: "module09", title: "Завдання до уроку 9" },
        { path: "module15", title: "Завдання до уроку 15" },
        { path: "module17_18", title: "Завдання до уроку 17-18" },
      ],
    },
  },
  3: {
    math: {
      prosh: [
        { path: "module01", title: "§1. Нумерація першої сотні. Додавання і віднімання в межах сотні." },
        { path: "module02", title: "§2. Грошові одиниці. Додавання і віднімання частинами." },
      ]
    },
    chutannya: {
      zah: [
        { path: "module01", title: "Завдання до §1" },
        { path: "module02", title: "Завдання до §2" },
        { path: "module03", title: "Завдання до §3" },
        { path: "module04", title: "Завдання до §4" },
        { path: "module05", title: "Завдання до §5" },
        { path: "module06", title: "Завдання до §6" },
        { path: "module07", title: "Завдання до §7" },
        { path: "module08", title: "Завдання до §8" },
        { path: "module09", title: "Завдання до §9" },
        { path: "module10", title: "Завдання до §10" },
        { path: "module11", title: "Завдання до §11" },
        { path: "module12", title: "Завдання до §12" },
        { path: "module13", title: "Завдання до §13" },
        { path: "module14", title: "Завдання до §14" },
        { path: "module15", title: "Завдання до §15" },
        { path: "module16", title: "Завдання до §16" },
        { path: "module17", title: "Завдання до §17" },
        { path: "module18", title: "Завдання до §18" },
        { path: "module19", title: "Завдання до §19" },
        { path: "module20", title: "Завдання до §20" },
        { path: "module21", title: "Завдання до §21" },
        { path: "module22", title: "Завдання до §22" },
        { path: "module23", title: "Завдання до §23" },
        { path: "module24", title: "Завдання до §24" },
        { path: "module25", title: "Завдання до §25" },
        { path: "module26", title: "Завдання до §26" },
        { path: "module27", title: "Завдання до §27" },
        { path: "module28", title: "Завдання до §28" },
        { path: "module29", title: "Завдання до §29" },
        { path: "module30", title: "Завдання до §30" },
      ],
    },
  },
  2: {
    ukrmova: {
      zah: [
        { path: "module01", title: "Завдання до §1" },
      ],
    },
  },
};