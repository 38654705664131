export const module43 = {
  title: "Адресат спілкування. Звертання у спілкуванні",
  questions: [
    {
      id: 1,
      question: "Яка роль має слово 'кому́' у терміні 'комунікація'?",
      options: [
        { id: "A", text: "Вказує на важливість адресата." },
        { id: "B", text: "Підкреслює, що спілкування завжди формальне." },
        { id: "C", text: "Вказує на те, що спілкування не має значення." },
        { id: "D", text: "Немає жодної ролі." },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 2,
      question: "Яке з наведених звертань є найбільш формальним?",
      options: [
        { id: "A", text: "'Привіт, друже!'" },
        { id: "B", text: "'Добрий день, пане!'" },
        { id: "C", text: "'Гей, брате!'" },
        { id: "D", text: "'Салют, сестричко!'" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Чому важливо звертатися до людей по імені?",
      options: [
        { id: "A", text: "Це робить спілкування більш особистим." },
        { id: "B", text: "Це підкреслює вашу владу." },
        { id: "C", text: "Це знижує рівень поваги." },
        { id: "D", text: "Це не має значення." },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 4,
      question: "Яке з наступних звертань є прийнятним для незнайомої людини?",
      options: [
        { id: "A", text: "'Привіт, друже!'" },
        { id: "B", text: "'Слухай, ти!'" },
        { id: "C", text: "'Пане, чи можу я вас запитати?'" },
        { id: "D", text: "'Ей, ти!'" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "Яке з наведених тверджень є правильним щодо звертань?",
      options: [
        { id: "A", text: "Звертання не впливають на спілкування." },
        { id: "B", text: "Звертання завжди повинні бути неформальними." },
        { id: "C", text: "Звертання можуть показати повагу до співрозмовника." },
        { id: "D", text: "Звертання не є важливими в спілкуванні." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 6,
      question: "Яке з наведених звертань є найбільш неформальним?",
      options: [
        { id: "A", text: "'Шановний пане!'" },
        { id: "B", text: "'Доброго дня, пані!'" },
        { id: "C", text: "'Привіт, друже!'" },
        { id: "D", text: "'Вітаю, добродію!'" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
  ],
};
