import { text_mod_46_1, text_mod_46_2 } from "../text/Text.js";

export const module46 = {
  title: "Тема 46. Особливості української романтичної поезії. Михайло Петренко «Дивлюсь я на небо»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_46_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_46_2,
    },
    {
      id: 3,
      question: "Чому романтизм в українській літературі утвердився пізніше, ніж в європейському письменстві?",
      options: [
        { id: "А", text: "через жорстокий імперський гніт" },
        { id: "Б", text: "не було літературної мови" },
        { id: "В", text: "у мистецтві переважали фольклорні мотиви" },
        { id: "Г", text: "не було високоосвічених митців" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "Романтизм як художній напрям найбільше відобразився в українській",
      options: [
        { id: "А", text: "поезії" },
        { id: "Б", text: "прозі" },
        { id: "В", text: "драматургії" },
        { id: "Г", text: "фольклорі" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "Назва гуртка «Руська трійця» виникла за",
      options: [
        { id: "А", text: "національною приналежністю учасників" },
        { id: "Б", text: "кількістю учасників" },
        { id: "В", text: "однойменним альманахом" },
        { id: "Г", text: "першою колективною збіркою фольклору" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "Небо в поезії М. Петренка є символом",
      options: [
        { id: "А", text: "безтурботності" },
        { id: "Б", text: "самотності" },
        { id: "В", text: "духовної свободи" },
        { id: "Г", text: "самостійності" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "Мотив вірша “Дивлюсь я на небо…” –",
      options: [
        { id: "А", text: "прагнення духовно багатої натури до абсолютної краси та досконалості." },
        { id: "Б", text: "патріотичне захоплення красою рідної землі." },
        { id: "В", text: "трагічне кохання, розлука закоханих." },
        { id: "Г", text: "митець і мистецтво в суспільстві." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 8,
      question: "Яким чином мрія автора про небо частково реалізувалася?",
      options: [
        { id: "А", text: "Онук М. Петренка став космонавтом." },
        { id: "Б", text: "Іменем М. Петренка названо астероїд." },
        { id: "В", text: "Іменем М. Петренка названо космічний корабель." },
        { id: "Г", text: "Вірш “Дивлюсь я на небо…” став славнем космонавтів." },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
