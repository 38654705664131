import {text_mod_29, } from "../text/Text.js";
export const module29 = {
  title:
    "Тема 29. Пряме й переносне значення слова​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_29, },
    ]
  }
