export const module19 = {
  title: "Українські знаки-символи",
  questions: [
    {
      id: 1,
      question: "Яка з наведених рослин є символом плодючості в українській культурі?",
      options: [
        { id: "A", text: "Верба" },
        { id: "B", text: "Ромашка" },
        { id: "C", text: "Троянда" },
        { id: "D", text: "Лаванда" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 2,
      question: "Який з наведених символів вважається символом вірності в українській культурі?",
      options: [
        { id: "A", text: "Соловей" },
        { id: "B", text: "Горлиця" },
        { id: "C", text: "Дуб" },
        { id: "D", text: "Калина" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з наведених інформацій є новою для вас?",
      options: [
        { id: "A", text: "Вода символізує кров землі" },
        { id: "B", text: "Зоря символізує ніч" },
        { id: "C", text: "Верба не має значення" },
        { id: "D", text: "Соловей не асоціюється з українською культурою" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 4,
      question: "Який із символів в українській культурі асоціюється з весняною росою?",
      options: [
        { id: "A", text: "Верба" },
        { id: "B", text: "Дуб" },
        { id: "C", text: "Соловей" },
        { id: "D", text: "Святий Юрій" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених фраз є прикладом фразеологізму, що містить народний символ?",
      options: [
        { id: "A", text: "Літа пливуть, як вода" },
        { id: "B", text: "Паляниця – хлібові сестриця" },
        { id: "C", text: "Земля пухом" },
        { id: "D", text: "Будь здорова, як вода" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка з наведених легенд пов'язана з весняною росою?",
      options: [
        { id: "A", text: "Легенда про Дажбога" },
        { id: "B", text: "Легенда про святого Юрія" },
        { id: "C", text: "Легенда про Солов'я" },
        { id: "D", text: "Легенда про Вербу" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
