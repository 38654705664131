export const module10 = {
  title: "Узагальнено-особове речення. Пізнавай себе",
  questions: [
    {
      id: 1,
      question: "Яке з наведених речень є узагальнено-особовим і стосується самопізнання?",
      options: [
        { id: "А", text: "Людина повинна постійно вдосконалюватися." },
        { id: "Б", text: "Пізнаєш себе - пізнаєш світ." },
        { id: "В", text: "Вони завжди прагнули до самовдосконалення." },
        { id: "Г", text: "Ми часто не розуміємо своїх справжніх бажань." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 2,
      question: "У якому узагальнено-особовому реченні йдеться про важливість самоаналізу?",
      options: [
        { id: "А", text: "Без труда нічого не досягнеш у житті." },
        { id: "Б", text: "Завжди знайдеш виправдання своїм помилкам." },
        { id: "В", text: "Аналізуй свої вчинки щодня, і станеш мудрішим." },
        { id: "Г", text: "Люди часто бояться зазирнути в глибини свого 'я'." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question: "Визначте узагальнено-особове речення, яке відображає процес самопізнання:",
      options: [
        { id: "А", text: "Ніколи не дізнаєшся всього про себе." },
        { id: "Б", text: "Вони постійно працюють над собою." },
        { id: "В", text: "Ми повинні вчитися на власних помилках." },
        { id: "Г", text: "Людина - це загадка для самої себе." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "Яке узагальнено-особове речення найкраще описує шлях до самовдосконалення?",
      options: [
        { id: "А", text: "Не зупиняйся на досягнутому, рухайся вперед." },
        { id: "Б", text: "Вони завжди прагнуть до нових знань." },
        { id: "В", text: "Ми постійно вчимося чомусь новому." },
        { id: "Г", text: "Кожен день приносить нові відкриття." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "У якому узагальнено-особовому реченні міститься порада щодо самопізнання?",
      options: [
        { id: "А", text: "Вони завжди прислухаються до внутрішнього голосу." },
        { id: "Б", text: "Прислухайся до свого серця, воно підкаже правильний шлях." },
        { id: "В", text: "Ми часто ігноруємо свої справжні почуття." },
        { id: "Г", text: "Людина здатна на великі звершення." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "Визначте узагальнено-особове речення, яке стосується подолання внутрішніх перешкод:",
      options: [
        { id: "А", text: "Долаєш свої страхи - стаєш сильнішим." },
        { id: "Б", text: "Вони завжди борються зі своїми слабкостями." },
        { id: "В", text: "Ми повинні вміти протистояти труднощам." },
        { id: "Г", text: "Кожна людина має свої недоліки." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
  ],
};
