export const module21 = {
  title: "Естетика тексту і інформації",
  questions: [
    {
      id: 1,
      question: "Що означає термін 'естетика'?",
      options: [
        { id: "A", text: "Вивчення мови" },
        { id: "B", text: "Наука про красу і художні цінності" },
        { id: "C", text: "Система комунікації" },
        { id: "D", text: "Спосіб передачі інформації" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з наведених функцій мови є найбільш важливою для формування громадської думки?",
      options: [
        { id: "A", text: "Комунікативна" },
        { id: "B", text: "Емоційна" },
        { id: "C", text: "Державотвірна" },
        { id: "D", text: "Мислетвірна" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 3,
      question: "Які з наведених засобів мови сприяють реалізації естетичної функції?",
      options: [
        { id: "A", text: "Граматика та синтаксис" },
        { id: "B", text: "Наголоси, паузи, інтонація" },
        { id: "C", text: "Лексика" },
        { id: "D", text: "Всі наведені вище" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 4,
      question: "Які художні засоби використовуються для створення естетичного тексту?",
      options: [
        { id: "A", text: "Синоніми та антоніми" },
        { id: "B", text: "Епітет, метафора, порівняння" },
        { id: "C", text: "Тільки прикметники" },
        { id: "D", text: "Лише дієслова" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених функцій мови допомагає виразити емоції?",
      options: [
        { id: "A", text: "Пізнавальна" },
        { id: "B", text: "Емоційна" },
        { id: "C", text: "Ідентифікаційна" },
        { id: "D", text: "Називальна" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка з наведених характеристик є важливою для формування естетичного смаку?",
      options: [
        { id: "A", text: "Здатність сприймати і відчувати прекрасне" },
        { id: "B", text: "Знання граматики" },
        { id: "C", text: "Вміння складати речення" },
        { id: "D", text: "Володіння словниковим запасом" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
  ],
};
