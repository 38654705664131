import { text_mod_47_1, text_mod_47_2 } from "../text/Text.js";

export const module47 = {
  title: "Тема 47. Жанр балади в літературі романтизму. Адам Міцкевич «Світязь»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_47_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_47_2,
    },
    {
      id: 3,
      question: "За основу балади «Світязь» Адам Міцкевич взяв",
      options: [
        { id: "А", text: "народну легенду" },
        { id: "Б", text: "народну пісню" },
        { id: "В", text: "записи з літописів" },
        { id: "Г", text: "наукові дослідження" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "Рядки “Пан у Плужинах багатий, / Ці таємниці діставши у спадок, / Вирішив їх розгадати” у сюжеті балади є",
      options: [
        { id: "А", text: "розв'язка" },
        { id: "Б", text: "розвиток дії" },
        { id: "В", text: "зав'язка" },
        { id: "Г", text: "кульмінація" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "Ознаками романтизму в баладі є всі названі, ОКРІМ:",
      options: [
        { id: "А", text: "в основі розповіді – героїчне минуле" },
        { id: "Б", text: "драматична несподівана розв'язка" },
        { id: "В", text: "традиційні образи-символи" },
        { id: "Г", text: "великий обсяг" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "Сильне емоційне потрясіння у рядках “Лють погасила тремтіння тривоги!” автор передав за допомогою",
      options: [
        { id: "А", text: "епітетів" },
        { id: "Б", text: "метафори" },
        { id: "В", text: "порівняння" },
        { id: "Г", text: "фразеологізму" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "Символом чистоти, краси, але водночас і символом смерті, відданості, помсти у баладі є",
      options: [
        { id: "А", text: "купава" },
        { id: "Б", text: "вода" },
        { id: "В", text: "зілля" },
        { id: "Г", text: "аїр" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 8,
      question: "Автор уславив жителів міста, бо вони",
      options: [
        { id: "А", text: "змогли перехитрити ворога" },
        { id: "Б", text: "вибрали смерть, аби не потрапити в неволю" },
        { id: "В", text: "знищили ворогів" },
        { id: "Г", text: "навіть після поразки продовжували битву" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
