import { text_mod_31_1, text_mod_31_2 } from "../text/Text.js";

export const module31 = {
  title: "Тема 31. Педро Кальдерон де ла Барка «Життя – це сон»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_31_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_31_2,
    },
    {
      id: 3,
      question: "Педро Кальдерон займав високу посаду в",
      options: [
        { id: "А", text: "іспанському війську" },
        { id: "Б", text: "Ордені Святого Франциска" },
        { id: "В", text: "канцелярії короля Іспанії Філіпа IV" },
        { id: "Г", text: "Саламанкському університеті" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "“Життя – це сон” за жанром",
      options: [
        { id: "А", text: "історична драма" },
        { id: "Б", text: "соціально-побутова драма" },
        { id: "В", text: "філософська драма" },
        { id: "Г", text: "любовна драма" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "Не відповідає дійсності твердження про п'єсу",
      options: [
        { id: "А", text: "Твір складається із трьох хорнад." },
        { id: "Б", text: "Дія в п'єсі відбувається впродовж трьох днів." },
        { id: "В", text: "У драмі розкрито філософські проблеми буття людини." },
        { id: "Г", text: "У творі одна сюжетна лінія." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "Шлях, який герой пройшов за один день – це",
      options: [
        { id: "А", text: "хорнада" },
        { id: "Б", text: "фрагмент" },
        { id: "В", text: "епізод" },
        { id: "Г", text: "кадр" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 7,
      question: "В образі Сехисмундо розкрито головний конфлікт твору –",
      options: [
        { id: "А", text: "людина і влада" },
        { id: "Б", text: "людина і доля" },
        { id: "В", text: "боротьба за владу" },
        { id: "Г", text: "любовний трикутник" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "Характерними художніми особливостями бароко у п’єсі «Життя – це сон» є всі, ОКРІМ:",
      options: [
        { id: "А", text: "проста метафоричність" },
        { id: "Б", text: "контрастність, що втілена у протиставленні" },
        { id: "В", text: "емблематичність" },
        { id: "Г", text: "твір має фігурну форму" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
  ],
};
