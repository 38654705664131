export const module27 = {
  title: "Види критичного читання",
  questions: [
    {
      id: 1,
      question: "Яка основна мета критичного читання?",
      options: [
        { id: "A", text: "Швидко прочитати текст" },
        { id: "B", text: "Зрозуміти та оцінити інформацію в тексті" },
        { id: "C", text: "Запам'ятати всі деталі тексту" },
        { id: "D", text: "Написати власну думку про текст" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Який з наведених видів читання передбачає швидке ознайомлення з текстом?",
      options: [
        { id: "A", text: "Читання для розуміння" },
        { id: "B", text: "Читання для анотування" },
        { id: "C", text: "Читання для ознайомлення" },
        { id: "D", text: "Глибоке читання" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 3,
      question: "Який з наступних етапів критичного читання вимагає уважного аналізу аргументів автора?",
      options: [
        { id: "A", text: "Читання для ознайомлення" },
        { id: "B", text: "Читання для розуміння" },
        { id: "C", text: "Читання для анотування" },
        { id: "D", text: "Читання для розваги" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 4,
      question: "Що є важливим складником читання для анотування?",
      options: [
        { id: "A", text: "Швидке читання тексту" },
        { id: "B", text: "Формулювання уточнювальних запитань" },
        { id: "C", text: "Ігнорування деталей" },
        { id: "D", text: "Читання тексту лише один раз" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Чому критичне читання потребує часу та майстерності?",
      options: [
        { id: "A", text: "Тому що потрібно запам'ятати всі деталі" },
        { id: "B", text: "Тому що воно вимагає глибокого аналізу та оцінювання" },
        { id: "C", text: "Тому що потрібно читати лише один раз" },
        { id: "D", text: "Тому що це процес, який не потребує зусиль" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "Який з наведених підходів до читання є найбільш ефективним для повного розуміння тексту?",
      options: [
        { id: "A", text: "Читання лише для ознайомлення" },
        { id: "B", text: "Читання тричі, використовуючи всі види читання" },
        { id: "C", text: "Читання лише для анотування" },
        { id: "D", text: "Читання без нотаток" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
