import { text_mod_42_1, text_mod_42_2 } from "../text/Text.js";

export const module42 = {
  title: "Тема 42. Романтизм ХІХ століття як мистецький напрям",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_42_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_42_2,
    },
    {
      id: 3,
      question: "Романтизму як художньому напряму притаманні всі ознаки, ОКРІМ",
      options: [
        { id: "А", text: "символізму" },
        { id: "Б", text: "винятковості" },
        { id: "В", text: "чітких норм, правил" },
        { id: "Г", text: "послідовного націоналізму" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "Від класицизму романтизм НЕ успадкував",
      options: [
        { id: "А", text: "чіткий поділ на високі й низькі жанри" },
        { id: "Б", text: "використання народної мови в художніх творах" },
        { id: "В", text: "возвеличення людського духу" },
        { id: "Г", text: "увагу до фольклору" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "Філософ Імануїл Кант переконує, що поведінка людини зумовлена її особистою свободою і …",
      options: [
        { id: "А", text: "вихованням" },
        { id: "Б", text: "совістю" },
        { id: "В", text: "уподобаннями" },
        { id: "Г", text: "віросповіданням" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "Мотиви меланхолії, розчарування, неможливості розв’язати конфлікт між людиною і світом характерні для ….. стильової течії",
      options: [
        { id: "А", text: "фольклорної" },
        { id: "Б", text: "байронічної" },
        { id: "В", text: "гофманівської" },
        { id: "Г", text: "утопійної" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "Яка основна причина того, що в українській літературі романтизм утвердився пізніше, ніж на Заході",
      options: [
        { id: "А", text: "нерозвиненість національної мови" },
        { id: "Б", text: "культурна ізольованість" },
        { id: "В", text: "колоніальний гніт" },
        { id: "Г", text: "революція" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "Особливими типами романтичних героїв в українській літературі є",
      options: [
        { id: "А", text: "селяни" },
        { id: "Б", text: "козак і кобзар" },
        { id: "В", text: "парубок і дівчина" },
        { id: "Г", text: "повстанці, бунтівники" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
