export const module16 = {
  title: "Узагальнювальні слова в реченнях з однорідними членами та розділові знаки при них. Усе, що породжує позитивні емоції",
  questions: [
    {
      id: 1,
      question: "У якому реченні правильно розставлено розділові знаки при узагальнювальному слові?",
      options: [
        { id: "А", text: "Усе навкруги: квіти, дерева, небо - наповнювало серце радістю." },
        { id: "Б", text: "Усе навкруги - квіти, дерева, небо: наповнювало серце радістю." },
        { id: "В", text: "Усе навкруги: квіти, дерева, небо - наповнювало серце радістю." },
        { id: "Г", text: "Усе навкруги - квіти, дерева, небо - наповнювало серце радістю." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 2,
      question: 'Яке узагальнювальне слово можна вставити в речення: "________: сміх дітей, спів птахів, шелест листя - створювало атмосферу щастя"?',
      options: [
        { id: "А", text: "Усе це" },
        { id: "Б", text: "Ці звуки" },
        { id: "В", text: "Кожен елемент" },
        { id: "Г", text: "Загалом" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 3,
      question: "Де потрібно поставити двокрапку в реченні з узагальнювальним словом?\n'Різні види мистецтва живопис музика література танець можуть викликати позитивні емоції'",
      options: [
        { id: "А", text: "Після слова 'мистецтва'" },
        { id: "Б", text: "Після слова 'живопис'" },
        { id: "В", text: "Після слова 'література'" },
        { id: "Г", text: "Двокрапка не потрібна" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "У якому реченні узагальнювальне слово стоїть після однорідних членів?",
      options: [
        { id: "А", text: "Радість, щастя, любов - усе це робить наше життя яскравим." },
        { id: "Б", text: "Усе це: радість, щастя, любов - робить наше життя яскравим." },
        { id: "В", text: "Радість, щастя, любов - роблять наше життя яскравим." },
        { id: "Г", text: "Усе робить наше життя яскравим: радість, щастя, любов." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "Яке речення містить узагальнювальне слово перед однорідними членами?",
      options: [
        { id: "А", text: "Сонячне проміння, теплий вітерець, аромат квітів - усе це створювало чудовий настрій." },
        { id: "Б", text: "Чудовий настрій створювали сонячне проміння, теплий вітерець, аромат квітів." },
        { id: "В", text: "Багато чинників: сонячне проміння, теплий вітерець, аромат квітів - створювали чудовий настрій." },
        { id: "Г", text: "Сонячне проміння, теплий вітерець, аромат квітів створювали чудовий настрій." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "Яке речення потребує виправлення розділових знаків при узагальнювальному слові?",
      options: [
        { id: "А", text: "Усі ці речі: книги, музика, подорожі - наповнюють життя сенсом." },
        { id: "Б", text: "Книги, музика, подорожі - усе це наповнює життя сенсом." },
        { id: "В", text: "Життя наповнюють сенсом різні речі: книги, музика, подорожі." },
        { id: "Г", text: "Різні речі - книги, музика, подорожі: наповнюють життя сенсом." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
  ],
};
