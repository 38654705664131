export const module22 = {
  title: "Засоби передавання інформації",
  questions: [
    {
      id: 1,
      question: "Які з наведених засобів є усними?",
      options: [
        { id: "A", text: "Текстові повідомлення" },
        { id: "B", text: "Музика та мовлення" },
        { id: "C", text: "Графічні зображення" },
        { id: "D", text: "Жести та міміка" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з наведених ситуацій є прикладом неактуальної інформації?",
      options: [
        { id: "A", text: "Список речей для походу, наданий заздалегідь" },
        { id: "B", text: "Інформація про новий смартфон, отримана після покупки" },
        { id: "C", text: "Інструкція з безпеки на залізниці" },
        { id: "D", text: "Анонс події, що відбудеться наступного тижня" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Які з наведених способів є письмовими?",
      options: [
        { id: "A", text: "Мовлення" },
        { id: "B", text: "Жести" },
        { id: "C", text: "Графічні зображення" },
        { id: "D", text: "Всі наведені" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка з наведених характеристик є важливою для надійності джерел інформації?",
      options: [
        { id: "A", text: "Анонімність" },
        { id: "B", text: "Достовірність" },
        { id: "C", text: "Секретність" },
        { id: "D", text: "Суб'єктивність" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених інформацій є актуальною для сучасного життя?",
      options: [
        { id: "A", text: "Інформація про стародавні цивілізації" },
        { id: "B", text: "Новини про екологічні проблеми" },
        { id: "C", text: "Рецепти страв з минулого" },
        { id: "D", text: "Літературні твори" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "Які з наведених засобів можуть бути використані для комбінованого повідомлення?",
      options: [
        { id: "A", text: "Лише текст" },
        { id: "B", text: "Лише графіка" },
        { id: "C", text: "Текст і малюнки" },
        { id: "D", text: "Лише усні комунікації" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
  ],
};
