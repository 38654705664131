export const module15 = {
  title: "Однорідні обставини. Мандрівка в географію",
  questions: [
    {
      id: 1,
      question: "У якому реченні є однорідні обставини?",
      options: [
        { id: "А", text: "Ми подорожували через гори, долини та ліси." },
        { id: "Б", text: "Експедиція досліджувала Амазонку швидко і ефективно." },
        { id: "В", text: "Туристи відвідали найвищу гору Європи - Монблан." },
        { id: "Г", text: "Археологи знайшли стародавні артефакти в пустелі." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 2,
      question: "Виберіть речення з правильно розставленими розділовими знаками при однорідних обставинах:",
      options: [
        { id: "А", text: "Мандрівники йшли, через джунглі обережно і повільно." },
        { id: "Б", text: "Вчені спостерігали за вулканом, як вдень так і вночі." },
        { id: "В", text: "Дослідники вивчали флору і фауну Галапагоських островів швидко, але ретельно." },
        { id: "Г", text: "Альпіністи піднімалися на Еверест, важко дихаючи і долаючи сильний вітер." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з наведених груп слів може бути використана як однорідні обставини в реченні про географічну експедицію?",
      options: [
        { id: "А", text: "Цікавий, захоплюючий, небезпечний" },
        { id: "Б", text: "Швидко, обережно, уважно" },
        { id: "В", text: "Гори, річки, пустелі" },
        { id: "Г", text: "Компас, карта, GPS-навігатор" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "У якому реченні однорідні обставини з'єднані парним сполучником?",
      options: [
        { id: "А", text: "Науковці досліджували океанічне дно як за допомогою роботів, так і за допомогою підводних човнів." },
        { id: "Б", text: "Мандрівники пересувалися то пішки, то на джипах по африканській савані." },
        { id: "В", text: "Експедиція вивчала клімат Антарктиди влітку та взимку." },
        { id: "Г", text: "Географи досліджували ландшафт не лише візуально, але й за допомогою спеціального обладнання." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "Визначте, скільки груп однорідних обставин є в реченні:\nПротягом експедиції вчені старанно і методично збирали зразки ґрунту, уважно аналізували склад повітря, а також ретельно вимірювали температуру води в гейзерах.",
      options: [
        { id: "А", text: "Одна група" },
        { id: "Б", text: "Дві групи" },
        { id: "В", text: "Три групи" },
        { id: "Г", text: "Чотири групи" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "Яке з наведених речень містить узагальнююче слово при однорідних обставинах?",
      options: [
        { id: "А", text: "Дослідники вивчали клімат регіону всебічно: влітку і взимку, вдень і вночі." },
        { id: "Б", text: "Експедиція зібрала зразки рослин у тропічних лісах, саванах та пустелях." },
        { id: "В", text: "Науковці досліджували вулкан, використовуючи сейсмографи та термометри." },
        { id: "Г", text: "Мандрівники подолали складний маршрут, піднявшись на вершину гори." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
  ],
};
