import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import "./ClassCard.css";
import cardImg from "./1b.png";

function ClassCard() {
  return (
    <div>
      <Card style={{ width: "18rem" }}>
        <Card.Img
          variant="top"
          src={cardImg}
          style={{ width: "100%", height: "200px" }}
        />
        <Card.Body className="card_title">
          <Card.Title>1-Б клас</Card.Title>
          <Card.Text></Card.Text>
          <Link to="/teacher/class/1b">
            <Button variant="primary">Перейти до класу</Button>
          </Link>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ClassCard;
