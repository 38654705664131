export const module44 = {
  title: "Культура приватного спілкування. Засоби впливу на слухача",
  questions: [
    {
      id: 1,
      question: "Яка з наведених характеристик найбільше підкреслює багатство мовлення?",
      options: [
        { id: "A", text: "Використання простих слів." },
        { id: "B", text: "Використання синонімів та перифраз." },
        { id: "C", text: "Повторення одних і тих же слів." },
        { id: "D", text: "Уникнення складних конструкцій." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з наведених причин пояснює, чому важливо мати багатий словниковий запас?",
      options: [
        { id: "A", text: "Це робить мовлення важким для розуміння." },
        { id: "B", text: "Це дозволяє уникати повторень." },
        { id: "C", text: "Це не має значення для спілкування." },
        { id: "D", text: "Це ускладнює спілкування." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з наведених фраз є прикладом перифрази?",
      options: [
        { id: "A", text: "Він хороший друг." },
        { id: "B", text: "Він завжди готовий допомогти." },
        { id: "C", text: "Він - майстер сцени." },
        { id: "D", text: "Він любить спорт." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 4,
      question: "Чому велика кількість синонімів в українській мові є важливою?",
      options: [
        { id: "A", text: "Вони ускладнюють спілкування." },
        { id: "B", text: "Вони дозволяють точніше висловлювати думки." },
        { id: "C", text: "Вони не мають жодного значення." },
        { id: "D", text: "Вони завжди використовуються в офіційній мові." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених стратегій може допомогти зробити мовлення більш різноманітним?",
      options: [
        { id: "A", text: "Використання тільки одного типу речень." },
        { id: "B", text: "Уникнення нових слів." },
        { id: "C", text: "Використання синонімів та різних конструкцій." },
        { id: "D", text: "Постійне повторення одних і тих же слів." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 6,
      question: "Що може стати наслідком використання одноманітних слів у мовленні?",
      options: [
        { id: "A", text: "Підвищення інтересу до теми." },
        { id: "B", text: "Зниження уваги слухачів." },
        { id: "C", text: "Поліпшення комунікації." },
        { id: "D", text: "Збільшення кількості слухачів." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
