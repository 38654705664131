import { text_mod_18_1, text_mod_18_2 } from "../text/Text.js";

export const module18 = {
  title: "Тема 18. Італійське середньовіччя. Аліґ’єрі Данте Сонет 11",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_18_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_18_2,
    },
    {
      id: 3,
      question: "1. Ким вважають Данте Аліґ'єрі для італійської літератури?",
      options: [
        { id: "А", text: "батьком італійської літератури" },
        { id: "Б", text: "останнім поетом епохи Відродження" },
        { id: "В", text: "першим поетом доби Середньовіччя" },
        { id: "Г", text: "творцем італійську мову" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "2. Як Данте-гуманіст розглядає кохання?",
      options: [
        { id: "А", text: "гріх, який треба засуджувати" },
        { id: "Б", text: "найвище щастя і шлях пізнання" },
        { id: "В", text: "перешкода на шляху до духовного розвитку" },
        { id: "Г", text: "суто фізичне явище" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "3. Що незвичайного в зображенні жінки у сонетах Данте?",
      options: [
        { id: "А", text: "описана як звичайна земна жінка" },
        { id: "Б", text: "зображена як недосяжний ідеал" },
        { id: "В", text: "наділена одночасно земною та небесною красою" },
        { id: "Г", text: "описана лише її реальна зовнішність" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "4. Який вплив коханої жінки Данте на людей описано в його сонетах?",
      options: [
        { id: "А", text: "викликає страх і тривогу" },
        { id: "Б", text: "робить людей щасливими і смиренними" },
        { id: "В", text: "провокує заздрість і ненависть" },
        { id: "Г", text: "не справляє жодного ефекту" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "5. Яку реакцію викликає усмішка коханої у ліричного героя?",
      options: [
        { id: "А", text: "радість і захоплення" },
        { id: "Б", text: "гнів і роздратування" },
        { id: "В", text: "байдужість і апатію" },
        { id: "Г", text: "втрату здатності мислити і говорити" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 8,
      question: "6. Яке нове бачення людини пропонує Данте у своєму сонеті?",
      options: [
        { id: "А", text: "Людина як грішна істота" },
        { id: "Б", text: "Людина як божественне створіння" },
        { id: "В", text: "Людина як раціональна істота" },
        { id: "Г", text: "Людина як соціальна істота" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
