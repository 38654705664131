export const module42_43 = {
  title: "§ 42–43. Означено-особові речення. Неозначено-особові речення",
  questions: [
    {
      id: 1,
      question: "1. Односкладним є речення",
      options: [
        { id: "А", text: "Чорна галузка шкрябає шибку." },
        { id: "Б", text: "Я пісню з тобою високо нестиму." },
        { id: "В", text: "Помилуюсь скарбом в тиші наодинці." },
        { id: "Г", text: "Від рівного дерева рівна й тінь." }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Означено-особовим є речення",
      options: [
        { id: "А", text: "Ми завжди любили рожеві серпанки." },
        { id: "Б", text: "Перейшли ярочок, вийшли в молодий гайок." },
        { id: "В", text: "Непохитно тримаємося вишиваних традицій." },
        { id: "Г", text: "Хати замело майже під стріхами." }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Означено-особовими є всі речення, ОКРІМ",
      options: [
        { id: "А", text: "Навчіть дитину любити себе." },
        { id: "Б", text: "Передавайте дитині свої знання." },
        { id: "В", text: "Не говоріть і не думайте про дитину погано." },
        { id: "Г", text: "Треба визнавати й виправляти власні помилки." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Неозначено-особовим є речення",
      options: [
        { id: "А", text: "У народних піснях козаків часто порівнювали з орлами." },
        { id: "Б", text: "Люблю втомлено-впокорений шерхіт списаного аркуша." },
        { id: "В", text: "З вогнем не жартуй, воді не вір, із вітром не дружи." },
        { id: "Г", text: "Сонце затягло суцільною ковдрою сірих хмар." }
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Неозначено-особовими є всі речення, ОКРІМ",
      options: [
        { id: "А", text: "Мене везуть у царство трав, річок і таємничих озер." },
        { id: "Б", text: "Ще немає ні пролісків, ні лісового сну, ні фіалок на галявах." },
        { id: "В", text: "У народній творчості дівчат порівнювали з горлицями." },
        { id: "Г", text: "Ласували солодкими коренями ситнягу чи диким щавлем." }
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Доберіть до кожного способу вираження головного члена приклад речення.",
      dropdownQuestionParts: [
        "<i> <b>Спосіб вираження головного члена</b> </br> 1)   ",
        { id: "dropdown1", text: "дієслово 1 особи однини " },
        "<br /> <i> 2) ",
        { id: "dropdown2", text: "дієслово 2 особи однини " },
        "<i><br />3) ",
        { id: "dropdown3", text: "дієслово теперішнього часу у формі 3-ї особи множини " },
        "<i><br />4) ",
        { id: "dropdown4", text: "дієслово минулого часу у формі множини" },
        "<i> <br/></i>",
      ],
      options: [ 
        { id: "А", text: "Увечері дісталися до невеличкого вибалку." },
        { id: "Б", text: "Справжні книжки перечитують." },
        { id: "В", text: "Ношу дрова до куреня, розводжу огонь." },
        { id: "Г", text: "Від дощу під борону не сховаєшся." },
        { id: "Д", text: "Або будемо на Русі, або пропадемо усі." },
      ],
      correctAnswers: {
        dropdown1: "В",
        dropdown2: "Г",
        dropdown3: "Б",
        dropdown4: "А",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 7,
      question:
        '7. Розподіліть односкладні речення за типами: <i> означено-особові речення</i> та <i>неозначено-особові речення</i>.',
      isGame: true,
      initialWords: [
        { id: 1, text: "1. Найчастіше відпустки дають у травні й у червні.", type: "leftBucket" },
        { id: 2, text: "2. Після вечері зразу ж лягали спати.", type: "leftBucket" },
        { id: 3, text: "3. Барвінкуйся, земле, й барвінкуй нашу думу й пісню до зорі. ", type: "rightBucket" },
        { id: 4, text: "4. Чай подали з медом. ", type: "leftBucket" },
        { id: 5, text: "5. Намагаюся остаточно струсити із себе сон, протираю очі. ", type: "rightBucket" },
        { id: 6, text: "6. Про українську вишиванку знають у всьому світі. ", type: "leftBucket" },
        { id: 7, text: "7. Навчіться любити воду.", type: "rightBucket" },
        { id: 8, text: "8. У школі від звістки про екскурсію зрадіють.", type: "leftBucket" },
        { id: 9, text: "9. Стою, чекаю, розглядаюсь довкола поміж деревами. ", type: "rightBucket" },
        { id: 10, text: "10. Не забудьмо пречудовий наш край, солов’їв дзвінкі серенади. ", type: "rightBucket" },
        { id: 11, text: "11. Заварювали окропом корінь дикої смородини. ", type: "rightBucket" },
        { id: 12, text: "12. Роздмухуєш жаринку життя свідомості.", type: "rightBucket" },
   
      ],
      leftBucketTitle: "Неозначено-особові речення",
      rightBucketTitle: "Означено-особові речення",
    },
    // Додати нові питання сюди
  ],
};
