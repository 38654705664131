export const module74_75 = {
  title: "§ 74–75. Відокремлені обставини",
  questions: [
    {
      id: 1,
      question: "1. Одиничний дієприслівник треба відокремлювати комами в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Бійці пересуваються катакомбами згорбившись." },
        { id: "Б", text: "Одні сиділи на буреломі інші слухали стоячи." },
        { id: "В", text: "Попрацювавши мої друзі пішли грати у футбол." },
        { id: "Г", text: "Чорна качка тримається на воді розпластавшись." }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Одиничний дієприслівник НЕ ТРЕБА відокремлювати комами в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Усміхнувшись сонце вмить висушило росу." },
        { id: "Б", text: "Не оравши й не сіявши не будеш хліба їсти." },
        { id: "В", text: "Красуючись біла хмаринка тріпоче вгорі." },
        { id: "Г", text: "Ворог під Калинівкою тікав не оглядаючись." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Обставину НЕ ТРЕБА відокремлювати комами в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Дівчина все ще стоїть заломивши руки." },
        { id: "Б", text: "Білий кінь повіддя попустивши летить у степи." },
        { id: "В", text: "Степ зігнавши із себе ранкову прохолоду виграє зеленими барвами." },
        { id: "Г", text: "Над озером холонув вечір зануривши в мілкі береги далеке полум’я хмар." }
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Обставину НЕ ТРЕБА відокремлювати комами в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Забувши сміх ми слухали нового вчителя схилившись на парти." },
        { id: "Б", text: "Ми сидимо під кручею поклавши вудочки на хиткі рогачики й гомонимо." },
        { id: "В", text: "Уже за Лебединським лісом вороги бігли не чуючи ніг." },
        { id: "Г", text: "Весело фиркаючи машина вирвалася на пагорбок помчала у видолинок." }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Правильно поставлено розділові знаки у варіанті",
      options: [
        { id: "А", text: "Недалеко від берега над найбільшою скелею, літали чайки, плавно розрізаючи крильми повітря й вистежуючи свою здобич." },
        { id: "Б", text: "Недалеко від берега, над найбільшою скелею літали чайки, плавно розрізаючи крильми повітря й вистежуючи свою здобич." },
        { id: "В", text: "Недалеко від берега, над найбільшою скелею, літали чайки, плавно розрізаючи крильми повітря, й вистежуючи свою здобич." },
        { id: "Г", text: "Недалеко від берега, над найбільшою скелею, літали чайки, плавно розрізаючи крильми повітря й вистежуючи свою здобич." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Доберіть до кожної умови відокремлення обставин приклад речення.",
      dropdownQuestionParts: [
        "<i><b>Умови відокремлення обставин</b></i> <br> 1) одиничний дієприслівник ",
        { id: "dropdown1", text: " - " },
        "<br> 2) дієприслівниковий зворот ",
        { id: "dropdown2", text: " - " },
        "<br> 3) іменник із прийменником ",
        { id: "dropdown3", text: " - " },
        "<br> 4) порівняльний зворот ",
        { id: "dropdown4", text: " - " },
      ],
      options: [
        { id: "А", text: "Каша, засмажена зі шкварками, була напрочуд смачна." },
        { id: "Б", text: "Незважаючи на спекотне літо, ми зібрали гарний врожай." },
        { id: "В", text: "Подув південний вітерець, розвіюючи туман." },
        { id: "Г", text: "Руда кицька, злякавшись, кудись втекла." },
        { id: "Д", text: "Нехай цвіте, як соняшник, мій рідний край." },
      ],
      correctAnswers: {
        dropdown1: "Г",
        dropdown2: "В",
        dropdown3: "Б",
        dropdown4: "Д",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за особливостями: <i><b>речення, у яких треба відокремлювати обставину; речення, у яких не треба відокремлювати обставину.</b></i>',
      isGame: true,
      initialWords: [
        { id: 1, text: "Поклавши крила на велику спеку стоїть над степом сірий журавель.", type: "leftBucket" },
        { id: 2, text: "Отаман незважаючи на невдачу був веселий і хвацько підкручував вуса.", type: "leftBucket" },
        { id: 3, text: "Прокинувшись побратими хутко поснідали й поїхали на ринок.", type: "leftBucket" },
        { id: 4, text: "Порошинки кружляли в розігрітому повітрі та іскрилися наче коштовності.", type: "leftBucket" },
        { id: 5, text: "Мандрівники занурившись у власні думи почали вкладатися на ночівлю.", type: "leftBucket" },
        { id: 6, text: "Далі до озера друзі йшли пригнувшись.", type: "rightBucket" },
      ],
      leftBucketTitle: "Речення, у яких треба відокремлювати обставину",
      rightBucketTitle: "Речення, у яких не треба відокремлювати обставину",
    },
    {
      id: 8,
      question:
        '8. Розподіліть речення за особливостями: <i><b>речення, у яких треба відокремлювати обставину; речення, у яких не треба відокремлювати обставину.</b></i>',
      isGame: true,
      initialWords: [
        { id: 1, text: "Наївшись полягали горілиць у траві на зоряне небо задивились.", type: "leftBucket" },
        { id: 2, text: "Кіт скрадається обережно й низько пригинаючись.", type: "rightBucket" },
        { id: 3, text: "Змоклому до нитки дощовик потрібен як глухому музика.", type: "rightBucket" },
        { id: 4, text: "У кутку кімнати на ослоні спав сидячи сивий дідок із чудернацькою борідкою та довгим волоссям.", type: "rightBucket" },
        { id: 5, text: "Анатолій здогадався про вміст надісланого й не розгортаючи пакунка.", type: "rightBucket" },
        { id: 6, text: "Далі до озера друзі йшли пригнувшись.", type: "rightBucket" },
      ],
      leftBucketTitle: "Речення, у яких треба відокремлювати обставину",
      rightBucketTitle: "Речення, у яких не треба відокремлювати обставину",
    },
  ],
};
