export const module65_66 = {
  title: "§ 65–66. Вставні слова, словосполучення та речення",
  questions:
  [
    {
      id: 1,
      question: "1. Вставним словом ускладнено речення (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Раніше озеро здавалося безмежним сьогодні ж бачу озерце." },
        { id: "Б", text: "Тиша стояла якась неспокійна а світле безмежжя здавалося крихким." },
        { id: "В", text: "Раптом земля затряслась і ось-ось здавалося будинки заваляться." },
        { id: "Г", text: "Нам здавалося наче з дерев сиплеться золотий дощ осені." }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question: "2. НЕ Є вставним виділене слово в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "На щастя злива вже вщухала а крізь хмари пробивалося сонце." },
        { id: "Б", text: "На щастя потяг було затримано через технічні проблеми." },
        { id: "В", text: "Усі іспити сестра на щастя склала на високі бали." },
        { id: "Г", text: "Відчиняли люди широко ворота поливали на щастя дорогу." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 3,
      question:
        "3. Якщо поставити на місці пропусків слово <b><i><u>МОЖЕ</b></i></u>, то ускладненим стане речення",
      options: [
        { id: "А", text: "Наш тренер … Дніпро перепливти." },
        { id: "Б", text: "… весна вже настала?" },
        { id: "В", text: "Кожен … мріяти про велике." },
        { id: "Г", text: "… орел здійнятися вище хмар?" }
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Правильно поставлено розділові знаки в реченні",
      options: [
        { id: "А", text: "Вже почалось, мабуть, майбутнє, оце либонь вже почалось." },
        { id: "Б", text: "Вже почалось мабуть майбутнє, оце, либонь, вже почалось." },
        { id: "В", text: "Вже почалось, мабуть, майбутнє, оце, либонь, вже почалось." },
        { id: "Г", text: "Вже почалось, мабуть, майбутнє, оце либонь, вже почалось." }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Виділене слово є вставним у реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Мар’яна невдоволено хитнула головою проте взяла зі столу сорочку." },
        { id: "Б", text: "Хоча до вокзалу було й недалеко проте я поспішив сісти в авто й приїхати." },
        { id: "В", text: "Гори нібито стояли поруч проте до них іти довелося більше як дві доби." },
        { id: "Г", text: "Народу були зав’язані очі а він проте повертав обличчя туди де рокотали грізні струни Тараса Шевченка." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question:
        "6. Установіть відповідність. Значення вставного слова",
      dropdownQuestionParts: [
        "<i><b>Значення вставного слова</b></i> <br> 1) невпевненість ",
        { id: "dropdown1", text: " - " },
        "<br> 2) джерело інформації ",
        { id: "dropdown2", text: " - " },
        "<br> 3) активізація уваги ",
        { id: "dropdown3", text: " - " },
        "<br> 4) емоційна оцінка ",
        { id: "dropdown4", text: " - " },
      ],
      options: [
        { id: "А", text: "Кімната, за його свідченням, була просторою та світлою." },
        { id: "Б", text: "А завтра в мене, знаєш, важливі справи в місті." },
        { id: "В", text: "Ніжність, наприклад, описати математично не можна." },
        { id: "Г", text: "Може, то птахи приносять на своїх крилах весну?" },
        { id: "Д", text: "Як на лихо, потяга на станції вже не було." },
      ],
      correctAnswers: {
        dropdown1: "Г",
        dropdown2: "А",
        dropdown3: "Б",
        dropdown4: "Д",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за особливостями: </br><i><b>речення зі вставними конструкціями; </br>речення без вставних конструкцій.</b></i>',
      isGame: true,
      initialWords: [
        { id: 1, text: "Люди кажуть що під лежачий камінь вода не тече.", type: "rightBucket" },
        { id: 2, text: "Кажуть юність нерозсудлива й тривожна.", type: "leftBucket" },
        { id: 3, text: "Здається часу і не гаю а не встигаю не встигаю!", type: "leftBucket" },
        { id: 4, text: "Здається дивом кожне слово що з серця лине у віки.", type: "rightBucket" },
        { id: 5, text: "На думку спало відвідати міський парк.", type: "rightBucket" },
        { id: 6, text: "На думку професора сучасна молодь цікава й талановита.", type: "leftBucket" },
      ],
      leftBucketTitle: "Речення зі вставними конструкціями",
      rightBucketTitle: "Речення без вставних конструкцій",
    },
    {
      id: 8,
      question:
        '8. Розподіліть речення за особливостями: </br><i><b>речення зі вставними конструкціями; </br>речення без вставних конструкцій.</b></i>',
      isGame: true,
      initialWords: [
        { id: 1, text: "Скажімо чемерицю слід заготовляти у серпні-вересні чи у квітні.", type: "leftBucket" },
        { id: 2, text: "Друзі скажімо батькам про літню мандрівку до Канева.", type: "rightBucket" },
        { id: 3, text: "Князь час від часу озирався видно когось шукав.", type: "leftBucket" },
        { id: 4, text: "Здалеку добре видно опоряджені садки й квітники.", type: "rightBucket" },
        { id: 5, text: "Свої курені пластуни облаштували за козацьким звичаєм.", type: "rightBucket" },
        { id: 6, text: "За козацьким звичаєм годилося б гостей пригостити.", type: "leftBucket" },
      ],
      leftBucketTitle: "Речення зі вставними конструкціями",
      rightBucketTitle: "Речення без вставних конструкцій",
    },
    
    
    // Додати нові питання сюди
  ],
};
