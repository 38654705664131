export const module39 = {
  title: "Роль запитань у спілкуванні. Уточнювальні запитання",
  questions: [
    {
      id: 1,
      question: "Яка з характеристик методу Сократа полягає в тому, що він ставить запитання?",
      options: [
        { id: "A", text: "Відкриті запитання" },
        { id: "B", text: "Закриті запитання" },
        { id: "C", text: "Уточнювальні запитання" },
        { id: "D", text: "Всі наведені вище" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з тем є найбільш спірною для обговорення?",
      options: [
        { id: "A", text: "Чи варто вивчати мови?" },
        { id: "B", text: "Чи етично використовувати штучний інтелект у навчанні?" },
        { id: "C", text: "Яка погода сьогодні?" },
        { id: "D", text: "Яка у вас улюблена їжа?" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з характеристик закритих запитань?",
      options: [
        { id: "A", text: "Вимагають розгорнутої відповіді" },
        { id: "B", text: "Можна відповісти «так» або «ні»" },
        { id: "C", text: "Вимагають уточнення" },
        { id: "D", text: "Всі наведені вище" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка з наведених фраз є прикладом уточнювального запитання?",
      options: [
        { id: "A", text: "Як ти себе почуваєш?" },
        { id: "B", text: "Чи можеш ти розповісти більше про це?" },
        { id: "C", text: "Тобі сподобалось?" },
        { id: "D", text: "Чи вважаєш ти це важливим?" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених фраз є відкритим запитанням?",
      options: [
        { id: "A", text: "Тобі сподобалася книга?" },
        { id: "B", text: "Яка твоя улюблена книга?" },
        { id: "C", text: "Чи була книга цікавою?" },
        { id: "D", text: "Чи читав ти цю книгу?" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка з наведених ситуацій є найбільш підходящою для моделювання бесіди?",
      options: [
        { id: "A", text: "Чи подобається тобі їжа?" },
        { id: "B", text: "Яка твоя думка про екологію?" },
        { id: "C", text: "Чи подобається тобі музика?" },
        { id: "D", text: "Яка погода сьогодні?" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
