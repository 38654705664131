import {text_mod_12, } from "../text/Text.js";
export const module12 = {
  title:
    "Розділ 3.1. Теми 95-102. Лічба десятками в межах 100. Обчислення виразів. Розв’язання задач.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_12, },
    ]
  };




