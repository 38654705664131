export const module78_79 = {
  title: "§ 78–79. Відокремлені уточнювальні члени речення",
  questions: [
    {
      id: 1,
      question: "1. Уточнювальним членом ускладнено речення",
      options: [
        { id: "А", text: "Ранок, продутий свіжістю, терпкий, колючий." },
        { id: "Б", text: "Над ставком лунали захоплені вигуки, голосний регіт." },
        { id: "В", text: "Коня прив’язали до клена, а самі пішли шукати горіхи." },
        { id: "Г", text: "Варвара дивилася на песика довірливо, по-дитячому." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Уточнювальним членом ускладнено речення (розділові знаки пропущено)",
      options: [
        { id: "А", text: "У спеку на ялтинських пляжах тьма-тьмуща людей." },
        { id: "Б", text: "Увечері за ставком як солодко співає соловейко!" },
        { id: "В", text: "Я колись мріяв стати льотчиком ще в п’ятому класі." },
        { id: "Г", text: "У травні на Хрещатику каштани білим цвітом пишаються." }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question: "3. НЕМАЄ відокремленого уточнювального члена в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Небо розцвітало вогняними квітами павичевими хвостами з феєрверків." },
        { id: "Б", text: "За островом аж до самісінького небокраю виблискувала вода." },
        { id: "В", text: "У низовині довкола маленької ямки росло кілька кущиків очерету." },
        { id: "Г", text: "Обідом були задоволені всі подорожні особливо Мурка з Барсиком." }
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Правильно поставлено розділові знаки у варіанті",
      options: [
        { id: "А", text: "Недалеко від берега над найбільшою скелею, літали чайки, плавно розрізаючи крильми повітря й вистежуючи свою здобич." },
        { id: "Б", text: "Недалеко від берега, над найбільшою скелею літали чайки, плавно розрізаючи крильми повітря й вистежуючи свою здобич." },
        { id: "В", text: "Недалеко від берега, над найбільшою скелею, літали чайки, плавно розрізаючи крильми повітря, й вистежуючи свою здобич." },
        { id: "Г", text: "Недалеко від берега, над найбільшою скелею, літали чайки, плавно розрізаючи крильми повітря й вистежуючи свою здобич." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Правильно поставлено розділові знаки у варіанті",
      options: [
        { id: "А", text: "Хлопчики сиділи на крутому березі, звісивши ноги, і стежачи за вудочками, вели тиху розмову." },
        { id: "Б", text: "Хлопчики сиділи на крутому березі, звісивши ноги, і, стежачи за вудочками, вели тиху розмову." },
        { id: "В", text: "Хлопчики сиділи на крутому березі, звісивши ноги, і, стежачи за вудочками, вели тиху розмову." },
        { id: "Г", text: "Хлопчики сиділи на крутому березі звісивши ноги і, стежачи за вудочками, вели тиху розмову." }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Доберіть до кожного відокремленого члена речення приклад.",
      dropdownQuestionParts: [
        "<i><b>Відокремлений член речення</b></i> <br> 1) додаток ",
        { id: "dropdown1", text: " - " },
        "<br> 2) обставина ",
        { id: "dropdown2", text: " - " },
        "<br> 3) означення ",
        { id: "dropdown3", text: " - " },
        "<br> 4) прикладка ",
        { id: "dropdown4", text: " - " },
      ],
      options: [
        { id: "А", text: "Сонце вже наближалося до горизонту, сердито червоніючи." },
        { id: "Б", text: "Висока, струнка, вона гордо ступала стежиною." },
        { id: "В", text: "Є в нього, у сонця, ще причини для усмішки." },
        { id: "Г", text: "Скинулася велика, вигнута колесом темно-зелена рибина." },
        { id: "Д", text: "На відміну від воронівців, новаки ще не звикли до плавнів." },
      ],
      correctAnswers: {
        dropdown1: "Д",
        dropdown2: "А",
        dropdown3: "Б",
        dropdown4: "В",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за особливостями: <i><b>речення, ускладнені відокремленими уточнювальними членами; речення, не ускладнені відокремленими уточнювальними членами.</b></i>',
      isGame: true,
      initialWords: [
        { id: 1, text: "Раніше, у дитинстві, річка була маленька.", type: "leftBucket" },
        { id: 2, text: "У глибині хащі гусла тиша, якась похмура, аж крижана.", type: "leftBucket" },
        { id: 3, text: "Худобу випасало троє хлопців, зокрема Грицик.", type: "leftBucket" },
        { id: 4, text: "Нічна фіалка, або вечірниця духмяна, наповнила сад приємним ароматом.", type: "leftBucket" },
        { id: 5, text: "По-котячому обережно виліз зі сховку наш Мурко.", type: "rightBucket" },
        { id: 6, text: "Бровко зустрів їх на галявині ввечері.", type: "rightBucket" },
      ],
      leftBucketTitle: "Речення, ускладнені відокремленими уточнювальними членами",
      rightBucketTitle: "Речення, не ускладнені відокремленими уточнювальними членами",
    },
    {
      id: 8,
      question:
        '8. Розподіліть речення за особливостями: <i><b>речення, ускладнені відокремленими уточнювальними членами; речення, не ускладнені відокремленими уточнювальними членами.</b></i>',
      isGame: true,
      initialWords: [
        { id: 1, text: "На ярмарку впали в око прикраси з бісеру, особливо браслети.", type: "leftBucket" },
        { id: 2, text: "Там, за горами, вже давно день, а тут, на дні міжгір’я, ще ніч.", type: "leftBucket" },
        { id: 3, text: "Зустріч із поетом відбудеться о дванадцятій годині в суботу.", type: "rightBucket" },
        { id: 4, text: "У новому театральному сезоні плануємо переглянути «Конотопську відьму» або «Наталку Полтавку».", type: "rightBucket" },
        { id: 5, text: "Під вечір на самому обрії Швайка угледів вершника.", type: "rightBucket" },
        { id: 6, text: "Захоплююся сучасною літературою, створеною письменниками-інтелектуалами.", type: "rightBucket" },
      ],
      leftBucketTitle: "Речення, ускладнені відокремленими уточнювальними членами",
      rightBucketTitle: "Речення, не ускладнені відокремленими уточнювальними членами",
    },
  ],
};
