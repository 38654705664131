export const module50 = {
  title: "§ 50. Повні і неповні речення. Тире в неповних реченнях",
  questions: [
    {
      id: 1,
      question: "1. Повним є речення",
      options: [
        { id: "А", text: "Канікули!" },
        { id: "Б", text: "На хаті – трон лелеки…" },
        { id: "В", text: "Заблукав між трьох дубів." },
        { id: "Г", text: "Замість серця в мене Україна." }
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Неповним є речення",
      options: [
        { id: "А", text: "Сонце визбирує свої промені." },
        { id: "Б", text: "З дерев. З притихлої ріки. З мене." },
        { id: "В", text: "Бачу літо у високім цвіті, янтареві до́ли і поля." },
        { id: "Г", text: "Зронивши ягоду, кущі ба́рвили свій лист." }
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Повним у вірші є речення",
      options: [
        { id: "А", text: "Правда в кожного була своя:" },
        { id: "Б", text: "В одних – глибока течія," },
        { id: "В", text: "А в інших – куца та горбата," },
        { id: "Г", text: "Продатись ладна за дуката." }
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Неповним є речення",
      options: [
        { id: "А", text: "Веселий гість – дому радість." },
        { id: "Б", text: "Грамоти вчиться – завжди пригодиться." },
        { id: "В", text: "У душі – щасливий зорепад." },
        { id: "Г", text: "Краса любові – найвища на землі краса." }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Повним є речення",
      options: [
        { id: "А", text: "Береженого Бог береже, а козака – шабля." },
        { id: "Б", text: "Зліва – Софія, праворуч – Лавра…" },
        { id: "В", text: "Над лиманом горб, за горбом – степ." },
        { id: "Г", text: "Найбільше багатство – духовний скарб." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
  
    {
      id: 6,
      question: "6. Доберіть до кожного типу речення приклад. ",
      dropdownQuestionParts: [
        "<i> <b>Тип речення</b> </br> 1) односкладне означено-особове  ",
        { id: "dropdown1", text: " - " },
        "<br /> <i> 2) односкладне неозначено-особове ",
        { id: "dropdown2", text: " - " },
        "<i><br />3) односкладне узагальнено-особове ",
        { id: "dropdown3", text: " - " },
        "<i><br />4) двоскладне неповне ",
        { id: "dropdown4", text: " - " },
        "<i> <br/></i>",
      ],
      options: [ 
        { id: "А", text: "Хочеш їсти калачі – не сиди на печі." },
        { id: "Б", text: "Біля ніг – омріяне, шалене море." },
        { id: "В", text: "Бракороб і нероба – одна хвороба." },
        { id: "Г", text: "У лісовика грибів випрошую." },
        { id: "Д", text: "Кохання виліковують коханням!" },
      ],
      correctAnswers: {
        dropdown1: "Г",
        dropdown2: "Д",
        dropdown3: "А",
        dropdown4: "Б",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за типами: <i><b>повні речення; неповні речення.</b> </br>(Перетягни номер речення у відповідний кошик)</i>.',
      isGame: true,
      initialWords: [
        { id: 1, text: "Тихі зорі, повні во́ди – це, дитя, твоя земля!", type: "leftBucket" },
        { id: 2, text: "Міжсезоння.", type: "leftBucket" },
        { id: 3, text: "Не хотілося ні говорити, ні слухати.", type: "leftBucket" },
        { id: 4, text: "Шатро липи в комашиному гудінні.", type: "rightBucket" },
        { id: 5, text: "Прийшов, побачив, переміг!", type: "rightBucket" },
        { id: 6, text: "Навчіться любити воду.", type: "leftBucket" },
      ],
      leftBucketTitle: "Повні речення",
      rightBucketTitle: "Неповні речення",
    },
    {
      id: 8,
      question:
        '8. Розподіліть речення за типами: <i><b>повні речення; неповні речення.</b> </br>(Перетягни номер речення у відповідний кошик)</i>.',
      isGame: true,
      initialWords: [
        { id: 1, text: "На столі – Біблія в шкіряній палітурці.", type: "rightBucket" },
        { id: 2, text: "Заблукав між трьох дубів.", type: "rightBucket" },
        { id: 3, text: "Мовчання – найкраща музика.", type: "leftBucket" },
        { id: 4, text: "Навіщо зорям ніч?", type: "rightBucket" },
        { id: 5, text: "Збоку церква із зеленкуватою кам’яною огорожею.", type: "rightBucket" },
        { id: 6, text: "Час золотої лагідності сонця.", type: "leftBucket" },
      ],
      leftBucketTitle: "Повні речення",
      rightBucketTitle: "Неповні речення",
    },
    // Додати нові питання сюди
  ],
};
