import {text_mod_14, } from "../text/Text.js";
export const module14 = {
  title:
    "Тема 14. Створюю зв’язну розповідь про ситуацію із життя. ​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_14, },
    ]
  }
