export const module13 = {
  title: "Однорідні й неоднорідні означення. Відомі невідомі країни",
  questions: [
    {
      id: 1,
      question: "У якому реченні про маловідому країну використано однорідні означення?",
      options: [
        { id: "А", text: "Бутан - загадкова, гірська країна в Гімалаях." },
        { id: "Б", text: "Бутан - маленька буддистська країна між Індією та Китаєм." },
        { id: "В", text: "Бутан - таємнича, захоплююча, неповторна країна-казка." },
        { id: "Г", text: "Бутан - остання незалежна гімалайська держава." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question: "Визначте речення з неоднорідними означеннями про Вануату:",
      options: [
        { id: "А", text: "Вануату - екзотична, тропічна, райська держава в Тихому океані." },
        { id: "Б", text: "Вануату - далека острівна країна в Меланезії." },
        { id: "В", text: "Вануату - маленька, бідна, але щаслива нація." },
        { id: "Г", text: "Вануату - дивовижна, неповторна, загадкова країна вулканів." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 3,
      question: "Яке речення містить однорідні означення, що характеризують Монголію?",
      options: [
        { id: "А", text: "Монголія - велика степова країна в Центральній Азії." },
        { id: "Б", text: "Монголія - сувора, безмежна, малонаселена країна номадів." },
        { id: "В", text: "Монголія - древня азіатська держава з багатою історією." },
        { id: "Г", text: "Монголія - незвичайна країна з унікальною культурою кочівників." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "У якому реченні про Палау вжито неоднорідні означення?",
      options: [
        { id: "А", text: "Палау - мальовнича острівна держава в Тихому океані." },
        { id: "Б", text: "Палау - райська, екзотична, незаймана країна-курорт." },
        { id: "В", text: "Палау - крихітна, але багата природними ресурсами країна." },
        { id: "Г", text: "Палау - тропічна, сонячна, приваблива туристична країна." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "Визначте речення з однорідними означеннями про Ліхтенштейн:",
      options: [
        { id: "А", text: "Ліхтенштейн - маленьке європейське князівство між Швейцарією та Австрією." },
        { id: "Б", text: "Ліхтенштейн - багата, розвинена, процвітаюча альпійська країна." },
        { id: "В", text: "Ліхтенштейн - мініатюрна німецькомовна держава в Альпах." },
        { id: "Г", text: "Ліхтенштейн - гірська країна з конституційною монархією." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "У якому реченні про Тувалу використано неоднорідні означення?",
      options: [
        { id: "А", text: "Тувалу - крихітна полінезійська держава в Тихому океані." },
        { id: "Б", text: "Тувалу - віддалена, ізольована, маловідома острівна країна." },
        { id: "В", text: "Тувалу - екзотична, тропічна, райська країна-мрія." },
        { id: "Г", text: "Тувалу - бідна, але щаслива нація коралових атолів." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
  ],
};
