export const text_mod_1 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYEbKhg4WlcKdu2xJQ?embed=1&em=2&wdAr=1.7777777777777777&wdEaaCheck=1&wdHideHeaders=true&wdHideToolbars=true"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;" <!-- Тут додаємо стиль курсора -->
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_2 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYEq_eGsbciT0KRG9A?embed=1&em=2&wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_3 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYEpv4KLtibMTw_TEA?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_4 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYEntkkUs31tr2GnjA?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_5 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYEom_xj1eBQi1Lenw?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_6 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYElOIaJQGJDfNB9yw?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_7 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYEmdxiPGTZNoEQ1Cg?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_8 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYEkmR_p6v_xz4_a7g?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_9 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYEiQym_DWnEVwUoGw?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_10 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYEj0qORxEELin15Vw?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_11 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYEgnOfoVM8AXYkJkw?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_12 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYEf16yertPPIDO8dg?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_13 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYEhqbe7nJNS8u10uQ?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_14 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFGBVabCZ16ilfRjQ?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_15 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFjOnouyvbfQ23XHg?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_16 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFknjH6z-xyjO5MMg?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_17 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFiPW-C5gCY0XI_4w?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_18 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFfvvt51CvvTbxOnw?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_19 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFgFqgnOvYj7gp2bA?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_20 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFeM5kPH5bkvPbkVw?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_21 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFcNY79_7gUaol0wg?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_22 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFht-hYZaMF7CvaPA?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_23 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFdgXaz547Y1AeSnQ?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_24 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFbGHB_LRqTEclfRw?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_25 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFaunySnOpK2Xdu_A?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_26 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFZVwcPhu2aMUn5sw?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_27 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFYu2RkxAMMGBIYow?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_28 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFXHIhoqRwAdRzs-g?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_29 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFVBD29koRZaI1Oww?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_30 = `Виконай завдання. Натисни на екран, щоб побачити всі завдання.
<p></p><iframe
  src="https://1drv.ms/p/s!ApWcD4FjFxOLgYFU9v19a-vIsvI9ng?embed=1&amp;em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;