import {text_mod_16, } from "../text/Text.js";
export const module16 = {
  title:
    "Тема 16. Українська абетка: звуки та букви​. ​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_16, },
    ]
  }
