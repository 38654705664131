export const module07 = {
  title: "Односкладні речення. Односкладне називне речення. Дрібниці мають значення.",
  questions: [
    {
      id: 1,
      question: "Що таке односкладне речення?",
      options: [
        { id: "А", text: "Речення, що складається з двох головних членів" },
        { id: "Б", text: "Речення, граматична основа якого складається лише з одного головного члена" },
        { id: "В", text: "Речення без підмета" },
        { id: "Г", text: "Речення без присудка" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 2,
      question: "Який головний член може бути присутнім в односкладному реченні?",
      options: [
        { id: "А", text: "Тільки підмет" },
        { id: "Б", text: "Тільки присудок" },
        { id: "В", text: "Або підмет, або присудок" },
        { id: "Г", text: "Обов'язково і підмет, і присудок" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question: "Яке з наведених речень є односкладним?",
      options: [
        { id: "А", text: "Сонце світить яскраво." },
        { id: "Б", text: "Діти граються у дворі." },
        { id: "В", text: "Мені не віриться." },
        { id: "Г", text: "Вона любить читати книги." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "Що таке називне речення?",
      options: [
        { id: "А", text: "Речення, в якому є тільки присудок" },
        { id: "Б", text: "Речення, в якому головний член виражений дієсловом" },
        { id: "В", text: "Речення, в якому головний член має форму підмета у називному відмінку" },
        { id: "Г", text: "Речення, яке називає дію" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "Яким членом речення може бути поширене називне речення?",
      options: [
        { id: "А", text: "Додатком" },
        { id: "Б", text: "Обставиною" },
        { id: "В", text: "Означенням" },
        { id: "Г", text: "Присудком" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "Де найчастіше вживаються називні речення?",
      options: [
        { id: "А", text: "У наукових текстах" },
        { id: "Б", text: "У художній літературі та журналістиці" },
        { id: "В", text: "У офіційних документах" },
        { id: "Г", text: "У розмовній мові" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "Яке з наведених речень є називним?",
      options: [
        { id: "А", text: "Швидко темніє." },
        { id: "Б", text: "Осінній день." },
        { id: "В", text: "Люблю життя." },
        { id: "Г", text: "Шила в мішку не сховаєш." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
