export const module57 = {
  title: "§ 57. Однорідні й неоднорідні означення",
  questions: [
    {
      id: 1,
      question: "1. Однорідними членами ускладнено речення",
      options: [
        { id: "А", text: "Крізь широкі вікна ллється яскраве сонячне світло." },
        { id: "Б", text: "Лютніст поклав руки на струни – й одразу полинула мелодія." },
        { id: "В", text: "Скрізь на нашій путі виростали великі й малі замети." },
        { id: "Г", text: "Вранішня прохолода бадьорила мене, як джерельна вода." }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Однорідними означеннями ускладнено речення",
      options: [
        { id: "А", text: "Палке серпневе небо стояло майже в зеніті." },
        { id: "Б", text: "Плечистий, ошатний дуб стоїть на околиці села." },
        { id: "В", text: "Земля на тих полях була чиста, чорна та сита." },
        { id: "Г", text: "Я піду в далекі гори, на широкі полонини…" }
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Неоднорідні означення вжито в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "У кімнату війнув весняний свіжий вітерець." },
        { id: "Б", text: "За лісом сонце сходило розсіювалося біле плетиво хмар." },
        { id: "В", text: "Діти смакували варениками гречаними галушками киселе́м." },
        { id: "Г", text: "У теплій лагідній темряві цвіркуни творили симфонію літа." }
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Однорідними означеннями ускладнено речення (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Раптовий присмерк уже заткав високе київське небо." },
        { id: "Б", text: "У гарячий літній день ліс на горах сяє а в долинах чорніє." },
        { id: "В", text: "Зненацька блідий випадковий промінь випав із-за хмарини." },
        { id: "Г", text: "Кращі крафтові продукти зберігають у дубових липових діжках." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Ко́му пропущено в реченні",
      options: [
        { id: "А", text: "Несподівано налетів вітер дужий, поривчастий, колючий." },
        { id: "Б", text: "Увечері група подорожан увійшла в старий високий храм." },
        { id: "В", text: "Моїми найулюбленішими ягодами є малина та лісова суниця." },
        { id: "Г", text: "Сонце мало ось-ось кресну́ти далекий повитий серпанком небокрай." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    
  
    {
      id: 6,
      question: "6. Доберіть до особливостей однорідних означень приклади.",
      dropdownQuestionParts: [
        "<i> <b>Особливості однорідних означень</b> </br> 1) непоширене + поширене ",
        { id: "dropdown1", text: " - " },
        "<br /> <i> 2) однобічна характеристика ",
        { id: "dropdown2", text: " - " },
        "<i><br />3) суто позитивні за значенням ",
        { id: "dropdown3", text: " - " },
        "<i><br />4) суто негативні за значенням ",
        { id: "dropdown4", text: " - " },
        "<i> <br/></i>",
      ],
      options: [
        { id: "А", text: "На ґанок чепурної, свіжовибіленої хати вийшов легінь." },
        { id: "Б", text: "Нарешті ми виїхали на свою, житомирську трасу." },
        { id: "В", text: "Зелені, жовті, сині ку́льки зриваються в небо." },
        { id: "Г", text: "Друзі йшли в лісовій, пропахлій глицею прохолоді." },
        { id: "Д", text: "Понурі, посмутнілі ліси стояли в дощовій задумі." }
      ],
      correctAnswers: {
        dropdown1: "Г",
        dropdown2: "В",
        dropdown3: "А",
        dropdown4: "Д",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за особливостями <i>(розділові знаки пропущено): <i><b>речення з однорідними означеннями; речення з неоднорідними означеннями.</b></i> <br>(Перетягніть номер речення у відповідний кошик)',
      isGame: true,
      initialWords: [
        { id: 1, text: "У скрині лежали дбайливо складені вишиті рушники.", type: "rightBucket" },
        { id: 2, text: "По знайомій протоптаній у небі стежці яструб повертався додому.", type: "leftBucket" },
        { id: 3, text: "Наша родинна зустріч була теплою зворушливою.", type: "rightBucket" },
        { id: 4, text: "Тепла зворушлива родинна зустріч мене дуже наснажила.", type: "leftBucket" },
        { id: 5, text: "Огрядний вельможа стояв у пишному гаптованому золотом камзолі.", type: "leftBucket" },
        { id: 6, text: "Огрядний вельможа стояв у гаптованому золотом пишному камзолі.", type: "rightBucket" },
      ],
      leftBucketTitle: "Речення з однорідними означеннями",
      rightBucketTitle: "Речення з неоднорідними означеннями",
    },
    {
      id: 8,
      question:
      '8. Розподіліть речення за особливостями <i>(розділові знаки пропущено): <i><b>речення з однорідними означеннями; речення з неоднорідними означеннями.</b></i> <br>(Перетягніть номер речення у відповідний кошик)',
      isGame: true,
      initialWords: [
        { id: 1, text: "Сонце хилилося до обрію підсвічувало все світлом легким оранжевим.", type: "leftBucket" },
        { id: 2, text: "Сонце хилилося до обрію підсвічувало все легким оранжевим світлом.", type: "rightBucket" },
        { id: 3, text: "Короткий зимовий день прощаючись пригасав.", type: "rightBucket" },
        { id: 4, text: "Під весняний капіж мій миленький котик тихо муркотів.", type: "rightBucket" },
        { id: 5, text: "Під весняний капіж мій миленький дорогенький котик тихо муркотів.", type: "leftBucket" },
        { id: 6, text: "Пес Пірат повернувся зморений худючий але щасливий.", type: "leftBucket" },
      ],
      leftBucketTitle: "Речення з однорідними означеннями",
      rightBucketTitle: "Речення з неоднорідними означеннями",
    },
    // Додати нові питання сюди
  ],
};
