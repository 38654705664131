import { Component } from "react";

import ".//ModuleContainer.css";

class ModuleContainer extends Component {
  render() {
    const { component: Component, module, path } = this.props;
    return (
      <section className="module-background">
        <div className="module-container">
          <Component module={module} path={path} />
        </div>
      </section>
    );
  }
}

export default ModuleContainer;
