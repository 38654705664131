import { text_mod_40_1, text_mod_40_2 } from "../text/Text.js";

export const module40 = {
  title: "Тема 40. Просвітництво як світоглядна основа класицизму",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_40_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_40_2,
    },
    {
      id: 3,
      question: "Початком епохи Просвітництва вважається",
      options: [
        { id: "А", text: "Нідерландська революція 1556-1566 рр." },
        { id: "Б", text: "Англійська революція 1642–1651 рр." },
        { id: "В", text: "Французька революція 1789-1799 рр." },
        { id: "Г", text: "Німецька революція 1848-1849 рр." },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "Перший просвітницький трактат написав",
      options: [
        { id: "А", text: "Лоренс Стерн" },
        { id: "Б", text: "Іммануїл Кант" },
        { id: "В", text: "Джон Локк" },
        { id: "Г", text: "Жан Жак Руссо" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "Просвітництво НЕ було основою",
      options: [
        { id: "А", text: "класицизму" },
        { id: "Б", text: "сентименталізму" },
        { id: "В", text: "рококо" },
        { id: "Г", text: "романтизму" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "Який жанр мав особливу популярність у літературі XVIII ст.?",
      options: [
        { id: "А", text: "сонет" },
        { id: "Б", text: "поема" },
        { id: "В", text: "роман" },
        { id: "Г", text: "новела" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "Який жанр мав особливу популярність у живописі XVIII ст.?",
      options: [
        { id: "А", text: "пейзаж" },
        { id: "Б", text: "портрет" },
        { id: "В", text: "натюрморт" },
        { id: "Г", text: "іконопис" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "Просвітницькі ідеї знайшли своє відображення у творах українського письменника",
      options: [
        { id: "А", text: "Григорія Сковороди" },
        { id: "Б", text: "Івана Котляревського" },
        { id: "В", text: "Тараса Шевченка" },
        { id: "Г", text: "Семена Климовського" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
  ],
};
