export const module17 = {
  title: "Поняття про відокремлення. Відокремлення узгоджених означень. Цілюща сила і привабливість природи",
  questions: [
    {
      id: 1,
      question: "У якому реченні відокремлене означення виражає причину дії головного речення?",
      options: [
        { id: "А", text: "Зелені, пишні дерева шелестіли на вітрі." },
        { id: "Б", text: "Стомлені довгою подорожжю, туристи зупинилися біля гірського озера." },
        { id: "В", text: "Квіти, политі ранковою росою, яскраво сяяли на сонці." },
        { id: "Г", text: "Високі гори, вкриті снігом, величаво здіймалися над долиною." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 2,
      question: "Визначте речення з правильно відокремленим означенням:",
      options: [
        { id: "А", text: "Сосни, оповиті, туманом тихо шуміли в лісі." },
        { id: "Б", text: "Річка, швидка та прозора, весело дзюрчала по камінцях." },
        { id: "В", text: "Зачаровані красою заходу сонця, ми довго стояли на березі моря." },
        { id: "Г", text: "Поле, засіяне, пшеницею золотилося під променями сонця." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question: "Яке з наведених означень НЕ треба відокремлювати?",
      options: [
        { id: "А", text: "Захоплені чудовим краєвидом туристи зробили багато фотографій." },
        { id: "Б", text: "Повні життєдайної вологи хмари пропливали над полями." },
        { id: "В", text: "Стежка вкрита опалим листям вела вглиб лісу." },
        { id: "Г", text: "Зігріта теплим сонцем земля дихала весняними ароматами." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "У якому реченні допущено пунктуаційну помилку при відокремленні означення?",
      options: [
        { id: "А", text: "Оповиті легким туманом, гори здавалися казковими." },
        { id: "Б", text: "Втомлені, але щасливі ми поверталися з походу." },
        { id: "В", text: "Густа трава, посріблена росою блищала під сонцем." },
        { id: "Г", text: "Зачудовані красою природи, діти притихли." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "Яке з наведених означень є невідокремленим?",
      options: [
        { id: "А", text: "Осяяна сонцем галявина манила своєю красою." },
        { id: "Б", text: "Зелені схили гір радували око своєю свіжістю." },
        { id: "В", text: "Зворушені до глибини душі, ми милувалися краєвидом." },
        { id: "Г", text: "Повітря, насичене ароматом квітів, п'янило." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "У якому реченні відокремлене означення стоїть перед означуваним словом?",
      options: [
        { id: "А", text: "Долина, оточена горами, потопала в зелені." },
        { id: "Б", text: "Наповнені чистою водою, струмки весело дзюрчали." },
        { id: "В", text: "Ми йшли стежкою, вкритою м'яким мохом." },
        { id: "Г", text: "Захоплені красою природи, туристи забули про втому." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
