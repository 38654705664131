export const module42 = {
  title: "Комунікативні наміри. Переконати",
  questions: [
    {
      id: 1,
      question: "Яка з наведених ситуацій найкраще ілюструє силу переконання?",
      options: [
        { id: "A", text: "Друзі вирішують, куди піти на вечірку." },
        { id: "B", text: "Учитель призначає учня відповідальним за проект." },
        { id: "C", text: "Батьки купують новий телевізор." },
        { id: "D", text: "Сусіди обговорюють погоду." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Який з наведених варіантів найкраще описує, що означає 'переконувати'?",
      options: [
        { id: "A", text: "Висловлювати свою думку." },
        { id: "B", text: "Змушувати когось діяти за власним бажанням." },
        { id: "C", text: "Обговорювати різні точки зору." },
        { id: "D", text: "Слухати думки інших." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Чому важливо вміти переконувати інших?",
      options: [
        { id: "A", text: "Це допомагає уникати конфліктів." },
        { id: "B", text: "Це підвищує вашу популярність." },
        { id: "C", text: "Це може допомогти досягти своїх цілей." },
        { id: "D", text: "Це робить вас більш авторитетним." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка з наведених фраз є прикладом переконання?",
      options: [
        { id: "A", text: "Я думаю, що це хороша ідея." },
        { id: "B", text: "Ти можеш зробити це, якщо захочеш." },
        { id: "C", text: "Я впевнений, що це принесе користь." },
        { id: "D", text: "Це не так важливо." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених стратегій є найбільш ефективною для переконання?",
      options: [
        { id: "A", text: "Використання агресивних аргументів." },
        { id: "B", text: "Залучення емоцій та особистих прикладів." },
        { id: "C", text: "Ігнорування думки співрозмовника." },
        { id: "D", text: "Постійне повторення своїх слів." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка з наведених ситуацій може свідчити про недостатню силу переконання?",
      options: [
        { id: "A", text: "Люди слухають вас уважно." },
        { id: "B", text: "Співрозмовник не погоджується з вашою думкою." },
        { id: "C", text: "Ви отримали позитивні відгуки." },
        { id: "D", text: "Ви змогли вплинути на рішення групи." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};

