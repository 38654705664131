import { text_mod_27_1, text_mod_27_2, text_mod_27_3 } from "../text/Text.js";

export const module27 = {
  title: "Україна ХІХ століття в сучасній літературі. Олександр Гаврош «Врятувати Тараса Шевченка»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_27_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_27_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_27_3,
    },
    {
      id: 4,
      question: "У своїх книжках на історичні теми Олександр Гаврош здебільшого пише про видатних особистостей",
      options: [
        { id: "A", text: "Львова" },
        { id: "B", text: "Києва" },
        { id: "C", text: "Слобожанщини" },
        { id: "D", text: "Закарпаття" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 5,
      question: "Особливістю повісті «Врятувати Тараса Шевченка» НЕ є",
      options: [
        { id: "A", text: "великий обсяг" },
        { id: "B", text: "наявність детективної інтриги" },
        { id: "C", text: "вигадані фантастичні події" },
        { id: "D", text: "згадка про відомих особистостей минулого" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 6,
      question: "Цитата «У великих людей великі й недоліки» є у повісті «Врятувати Тараса Шевченка»",
      options: [
        { id: "A", text: "епіграфом" },
        { id: "B", text: "назвою розділу" },
        { id: "C", text: "ілюстрацією ідеї" },
        { id: "D", text: "міркуванням директора музею" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 7,
      question: "Персонажі повісті «Врятувати Тараса Шевченка» мандрували в минуле за допомогою порталу у вигляді",
      options: [
        { id: "A", text: "картини Тараса Шевченка" },
        { id: "B", text: "книжкової шафи" },
        { id: "C", text: "крісла-гойдалки" },
        { id: "D", text: "парусинового костюма" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 8,
      question: "Одна з реальних історичних осіб ХІХ століття, які стали прототипами персонажів повісті «Врятувати Тараса Шевченка», це",
      options: [
        { id: "A", text: "солдат Кузьмич" },
        { id: "B", text: "письменник Афанасьєв-Чужбинський" },
        { id: "C", text: "директор музею Попадинець" },
        { id: "D", text: "музейна доглядачка Марина Андріївна" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 9,
      question: "Яка праця про біографію Шевченка НЕ згадується у повісті «Врятувати Тараса Шевченка»?",
      options: [
        { id: "A", text: "Шевченківський словник" },
        { id: "B", text: "«Труди і дні Кобзаря»" },
        { id: "C", text: "альбом-путівник «Національний музей Тараса Шевченка»" },
        { id: "D", text: "«Енциклопедія життя і творчості Тараса Шевченка»" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
  ],
};
