export const module23 = {
  title: "Трансформування текстової інформації у графічну",
  questions: [
    {
      id: 1,
      question: "Яка інформація називається текстовою?",
      options: [
        { id: "A", text: "Інформація, що містить графічні елементи" },
        { id: "B", text: "Інформація, що передається у письмовій формі" },
        { id: "C", text: "Інформація, що передається усно" },
        { id: "D", text: "Інформація, що містить лише цифри" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Який спосіб подання інформації є найкращим для сприйняття?",
      options: [
        { id: "A", text: "Тільки текст" },
        { id: "B", text: "Тільки графіка" },
        { id: "C", text: "Комбінування тексту і графіки" },
        { id: "D", text: "Лише усні повідомлення" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 3,
      question: "Які з наведених елементів можуть бути використані для графічного подання інформації?",
      options: [
        { id: "A", text: "Лише таблиці" },
        { id: "B", text: "Лише малюнки" },
        { id: "C", text: "Схеми, таблиці, діаграми" },
        { id: "D", text: "Лише текст" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка з наведених характеристик є важливою для графічного подання інформації?",
      options: [
        { id: "A", text: "Складність" },
        { id: "B", text: "Зрозумілість" },
        { id: "C", text: "Довжина" },
        { id: "D", text: "Тільки емоційність" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених інформацій є прикладом графічної?",
      options: [
        { id: "A", text: "Стаття в журналі" },
        { id: "B", text: "Діаграма" },
        { id: "C", text: "Розмова" },
        { id: "D", text: "Лист" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "Які з наведених етапів є важливими для трансформації тексту в графіку?",
      options: [
        { id: "A", text: "Тільки написання тексту" },
        { id: "B", text: "Аналіз та структуризація" },
        { id: "C", text: "Лише малювання" },
        { id: "D", text: "Тільки усне пояснення" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
