import {text_mod_5, } from "../text/Text.js";

export const module07_08 = {

  title: "Урок 7-8. Безпека в населеному пункті. Вплив воєнних дій та терористичних актів на навколишнє середовище. Види небезпечних промислових об’єктів.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_5, },
    // Додати нові питання сюди
  ],
};
