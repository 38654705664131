export const module26 = {
  title: "Елементи маніпуляції та пропаганди",
  questions: [
    {
      id: 1,
      question: "Яка з наведених характеристик найкраще описує маніпуляцію?",
      options: [
        { id: "A", text: "Чесне поширення інформації" },
        { id: "B", text: "Викривлення інформації для досягнення цілей" },
        { id: "C", text: "Об’єктивний аналіз фактів" },
        { id: "D", text: "Поширення ідей, що відповідають інтересам суспільства" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з наведених фраз є прикладом пропаганди?",
      options: [
        { id: "A", text: "Експерти вважають, що…" },
        { id: "B", text: "Влада України прибрала з тилу майже усю ППО" },
        { id: "C", text: "Україна бомбардує Запорізьку АЕС" },
        { id: "D", text: "Усі, кого я знаю в Криму, підтримали референдум" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з наведених дій є прикладом маніпуляції зі статистикою?",
      options: [
        { id: "A", text: "Завищення цифр щодо кількості учасників прощі" },
        { id: "B", text: "Вибірковий характер новин" },
        { id: "C", text: "Створення неправдивої інформації" },
        { id: "D", text: "Замовчування інформації" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка з наведених фраз є прикладом висмикування з контексту?",
      options: [
        { id: "A", text: "Президент України погрожує авіаударами Росії" },
        { id: "B", text: "Усі знають, що Україна не здатна самостійно що-небудь вирішувати" },
        { id: "C", text: "Польська зброя, що потрапила в Україну, виявилася бракованою" },
        { id: "D", text: "Ти ж хочеш бути в тренді, купуй ці джинси!" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених фраз є прикладом перебільшення?",
      options: [
        { id: "A", text: "Бабуся не розуміє етикеток українською мовою" },
        { id: "B", text: "У Маріуполі триває будівництво соціальних об’єктів" },
        { id: "C", text: "Вісім років Росія вигадувала способи порятунку цілісності України" },
        { id: "D", text: "Усі ці нововведення будуть марними, якщо суспільство не змінить ставлення до освіти" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка з наведених фраз є прикладом покликання на недостовірні джерела?",
      options: [
        { id: "A", text: "Як усім відомо…" },
        { id: "B", text: "Усі знають, що…" },
        { id: "C", text: "Експерти вважають…" },
        { id: "D", text: "Влада України прибрала з тилу…" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
  ],
};
