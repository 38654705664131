import {text_mod_27, } from "../text/Text.js";
export const module27 = {
  title:
    "Тема 27. Однозначні й багатозначні слова​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_27, },
    ]
  }
