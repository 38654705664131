import React from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './LoginAs.css';

const LoginAs = () => {
    return (
        <Container fluid className="d-flex vh-100">
            <Row className="m-auto login-container">
                <Col className="text-center">
                    <h2>Вхід</h2>
                    <Link to="/teacher">
                        <Button variant="primary" size="lg" block className="login-button">
                            Увійти як вчитель
                        </Button>
                    </Link>
                    <Link to="/home">
                    <Button variant="success" size="lg" block className="login-button">
                        Увійти як учень
                    </Button>
                    </Link>
                    <Link to="/parents">
                    <Button variant="warning" size="lg" block className="login-button">
                        Увійти як батьки
                    </Button>
                    </Link>
                </Col>
            </Row>
        </Container>
    );
};

export default LoginAs;
