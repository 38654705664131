export const module28 = {
  title: "Одиничний і множинний тексти",
  questions: [
    {
      id: 1,
      question: "Яка з наведених характеристик найкраще описує одиничний текст?",
      options: [
        { id: "A", text: "Охоплює кілька аспектів однієї теми." },
        { id: "B", text: "Є частиною серії публікацій." },
        { id: "C", text: "Самостійно розкриває певну наукову проблему або подію." },
        { id: "D", text: "Включає різні точки зору на одну подію." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з наведених публікацій є прикладом множинного тексту?",
      options: [
        { id: "A", text: "Новинна стаття про конкретну подію." },
        { id: "B", text: "Серія статей про екологічні проблеми одного регіону." },
        { id: "C", text: "Оглядова стаття про досягнення науки." },
        { id: "D", text: "Інтерв'ю з відомим вченим." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з наступних характеристик є перевагою множинних текстів?",
      options: [
        { id: "A", text: "Вони завжди коротші за одиничні тексти." },
        { id: "B", text: "Вони дозволяють розглянути тему з різних аспектів." },
        { id: "C", text: "Вони не потребують додаткових публікацій для розуміння." },
        { id: "D", text: "Вони містять лише фактичну інформацію." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка з наведених ситуацій найбільше підходить для використання одиничного тексту?",
      options: [
        { id: "A", text: "Аналіз різних точок зору на одну подію." },
        { id: "B", text: "Поглиблене дослідження теми через кілька статей." },
        { id: "C", text: "Опис конкретної події з усіма необхідними деталями." },
        { id: "D", text: "Порівняння різних авторів на одну тему." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наступних характеристик є типовою для одиничного тексту?",
      options: [
        { id: "A", text: "Містить кілька авторів." },
        { id: "B", text: "Розглядає тему в межах однієї публікації." },
        { id: "C", text: "Включає багато прикладів з різних джерел." },
        { id: "D", text: "Охоплює широкий контекст події." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка з наведених причин може бути основною для використання множинних текстів у дослідженнях?",
      options: [
        { id: "A", text: "Вони завжди легкі для читання." },
        { id: "B", text: "Вони дозволяють скласти чітке уявлення про складну тему." },
        { id: "C", text: "Вони завжди містять нову інформацію." },
        { id: "D", text: "Вони не потребують аналізу." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
