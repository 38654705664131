export const module32 = {
  title: "Привітання особисте",
  questions: [
    {
      id: 1,
      question: "Яка основна думка тексту про традицію вітання з Різдвом за допомогою листівок?",
      options: [
        { id: "A", text: "Листівки завжди були популярні в Україні" },
        { id: "B", text: "Листівки є важливим елементом святкування Різдва" },
        { id: "C", text: "Листівки втратили популярність через інтернет" },
        { id: "D", text: "Листівки не мають значення в сучасному світі" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Чому листівки швидко стали популярними в Європі?",
      options: [
        { id: "A", text: "Вони були дешевими" },
        { id: "B", text: "Вони мали різноманітні дизайни та матеріали" },
        { id: "C", text: "Листівки завжди були в моді" },
        { id: "D", text: "Їх почали надсилати лише в Америку" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Яке з наведених слів є синонімом до слова 'вітати'?",
      options: [
        { id: "A", text: "Бажати" },
        { id: "B", text: "Нагороджувати" },
        { id: "C", text: "Гратулювати" },
        { id: "D", text: "Услати" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 4,
      question: "Які прикметники з тексту мають ступені порівняння?",
      options: [
        { id: "A", text: "Найдорожча, найкраща" },
        { id: "B", text: "Швидкий, повільний" },
        { id: "C", text: "Високий, низький" },
        { id: "D", text: "Теплий, холодний" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка думка про щирість привітання, якщо ви отримали 5-7 однакових листівок?",
      options: [
        { id: "A", text: "Це свідчить про щирість" },
        { id: "B", text: "Це робить привітання унікальним" },
        { id: "C", text: "Це може виглядати нещиро" },
        { id: "D", text: "Це не має значення" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 6,
      question: "Що означає вислів 'з роси й води' у контексті привітань?",
      options: [
        { id: "A", text: "Бажання здоров'я і щастя" },
        { id: "B", text: "Бажання удачі" },
        { id: "C", text: "Бажання довгих років життя" },
        { id: "D", text: "Бажання багатства" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
  ],
};
