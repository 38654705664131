import { text_mod_32_1, text_mod_32_2 } from "../text/Text.js";

export const module32 = {
  title: "Тема 32. Українське бароко. Семен Климовський «Їхав козак за Дунай»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_32_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_32_2,
    },
    {
      id: 3,
      question: "Митці доби Бароко вражали сприймача за допомогою художніх засобів:",
      options: [
        { id: "А", text: "епітетів, метафор" },
        { id: "Б", text: "порівнянь, повторів, риторичних фігур" },
        { id: "В", text: "гіперболи, гротеску, антитези" },
        { id: "Г", text: "паралелізму, літоти" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "ХИБНИМ є твердження про добу Бароко",
      options: [
        { id: "А", text: "В Україні доба Бароко проіснувала понад 250 років." },
        { id: "Б", text: "Мистецькі твори доби Бароко характеризуються пишним декоратизмом." },
        { id: "В", text: "Всесвітню славу здобули українські художники доби Бароко – А. Лосенко, Д. Левицький, В. Боровиковський." },
        { id: "Г", text: "Іван Мазепа був яскравим представником доби Бароко." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "Автором теорії курйозного віршування в українській літературі є",
      options: [
        { id: "А", text: "Іван Мазепа" },
        { id: "Б", text: "Іван Величковський" },
        { id: "В", text: "Семен Климовський" },
        { id: "Г", text: "Іван Пінзель" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "НЕ належить до зоропоезії",
      options: [
        { id: "А", text: "вільний вірш" },
        { id: "Б", text: "акровірш" },
        { id: "В", text: "фігурний вірш" },
        { id: "Г", text: "азбучний вірш" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 7,
      question: "Одним з авторів музичної обробки пісні С. Климовського “Їхав козак за Дунай…” був",
      options: [
        { id: "А", text: "Й. С. Бах" },
        { id: "Б", text: "Ф. Шопен" },
        { id: "В", text: "В. А. Моцарт" },
        { id: "Г", text: "Л. ван Бетховен" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 8,
      question: "Ідея пісні “Їхав козак за Дунай” – це",
      options: [
        { id: "А", text: "захоплення звитяжною боротьбою козаків" },
        { id: "Б", text: "возвеличення щирого почуття кохання, яке випробовується розлукою" },
        { id: "В", text: "уславлення патріотизму козаків" },
        { id: "Г", text: "оспівування дівочої вірності" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
