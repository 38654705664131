export const text_mod_1 = `<p>Ознайомся з матеріалом і виконай завдання.</p>
<iframe
  src="https://onedrive.live.com/embed?resid=8B131763810F9C95%2115072&authkey=!AFzEBRb3dEr82sc&em=2"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  scrolling="no"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_2 = `<p>Ознайомся з матеріалом і виконай завдання.</p>
<iframe
  src="https://onedrive.live.com/embed?resid=8B131763810F9C95%2115071&authkey=!AEeOHW4_iyPss3w&em=2"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  scrolling="no"
  allowfullscreen="true">
</iframe>


<p>Подивись відео з сайту ДСНС, а також ознайомся з <a href ="https://dsns.gov.ua/abetka-bezpeki/diyi-naselennya-v-umovax-nadzvicainix-situacii-vojennogo-xarakteru" target="_blank">інформацією про дії населення в умовах надзвичайних ситуацій воєнного характеру</a>.</p>
<iframe width="100%" height="315" src="https://www.youtube.com/embed/glBjCxkPPn0?si=lZpNRSu4yrHCFrlE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
`;

export const text_mod_3 = `<p>Ознайомся з матеріалом і виконай завдання.</p>
<iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTqxI0XeA_gRpAKwN4SaK5oAdEoJi7vHGA5Ggxfkasr2Jo"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  scrolling="no"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_4 = `<p>Ознайомся з матеріалом і виконай завдання.</p>
<iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTdd54zQvz1Q55ZYgCKD4CiASFj-E1pULxHv2xJ-jsoCSg"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  scrolling="no"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_5 = `<p>Ознайомся з матеріалом і виконай завдання.</p>
<iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQCg329ak1ISKWGcMuRt-S2AVSz7xraLny2Phybh3wRBUg?em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_6 = `<p>Ознайомся з матеріалом і виконай завдання.</p>
<iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQQilpJkl1ClSZ2BDsJTMUmzATKOhQnWpp7-w03sdBJZO3g?em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_7 = `<p>Ознайомся з матеріалом і виконай завдання.</p>
<iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTzYAEss9H_SZ_r0GTGTdOeATstu2hSy3KiQrQ6DN3GS2c?em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;

export const text_mod_8 = `<p>Ознайомся з матеріалом і виконай завдання.</p>
<iframe
  src="https://1drv.ms/p/c/8b131763810f9c95/IQTVSujN1DPRSqxTwGe118QdAcGaRrul5A6msQf1OU2-TRw?em=2&amp;wdAr=1.7777777777777777"
  width="100%"
  height="auto"
  style="aspect-ratio: 16/9; cursor: pointer;"
  frameborder="0"
  allowfullscreen="true">
</iframe>
`;