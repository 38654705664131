import { text_mod_15_1, text_mod_15_2 } from "../text/Text.js";

export const module15 = {
  title: "Тема 15. Своєрідність давнього українського письменства. Володимир Мономах «Повчання дітям»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_15_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_15_2,
    },
    {
      id: 3,
      question: "1. У якому жанрі літератури Київської Русі основною метою було передати життєву мудрість нащадкам?",
      options: [
        { id: "А", text: "літопис" },
        { id: "Б", text: "проповідь" },
        { id: "В", text: "послання" },
        { id: "Г", text: "повчання" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "2. Причина численних військових походів Володимира Мономаха –",
      options: [
        { id: "А", text: "потреба зміцнення єдності держави та її захист від зовнішніх ворогів" },
        { id: "Б", text: "прагнення захоплення нових територій" },
        { id: "В", text: "особливості характеру князя, бажання самоствердитися" },
        { id: "Г", text: "вплив на прийняття його рішень монархів інших європейських країн" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "3. До якого стилю належить «Повчання дітям» Володимира Мономаха?",
      options: [
        { id: "А", text: "реалізм" },
        { id: "Б", text: "романтизм" },
        { id: "В", text: "орнаменталізм" },
        { id: "Г", text: "монументалізм" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "4. Як стверджує Володимир Мономах, “...мати всьому дурному” – це",
      options: [
        { id: "А", text: "заздрість" },
        { id: "Б", text: "лінощі" },
        { id: "В", text: "зневага" },
        { id: "Г", text: "лукавство" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "5. Відтворіть слова Володимира Мономаха: “Ніколи не майте … у своєму серці і в розумі”.",
      options: [
        { id: "А", text: "презирства" },
        { id: "Б", text: "страху" },
        { id: "В", text: "гордощів" },
        { id: "Г", text: "гніву" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "6. В українській культурі з “Повчання дітям” Володимира Мономаха започаткувалася",
      options: [
        { id: "А", text: "“філософія серця”" },
        { id: "Б", text: "“філософія розуму”" },
        { id: "В", text: "“філософія душі”" },
        { id: "Г", text: "“філософія гуманізму”" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
  ],
};
