import { text_mod_50_1, text_mod_50_2 } from "../text/Text.js";

export const module50 = {
  title: "Тема 50. Реалістична поезія. П’єр-Жан де Беранже «Священний союз народів»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_50_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_50_2,
    },
    {
      id: 3,
      question: "НЕ є ознакою реалізму твердження:",
      options: [
        { id: "А", text: "Письменники-реалісти зосередилися на злободенних проблемах сьогодення." },
        { id: "Б", text: "Засобом відтворення і дослідження дійсності в реалістів стає тип – збірний образ-персонаж." },
        { id: "В", text: "Реалісти розглядали світ крізь призму символів." },
        { id: "Г", text: "Природа для письменників-реалістів – насамперед тло, на якому відбуваються типові події." },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "Чиї принципи були основою виховання в школі, де навчався П’єр-Жан Беранже?",
      options: [
        { id: "А", text: "Жана Жака Руссо" },
        { id: "Б", text: "Іммануїла Канта" },
        { id: "В", text: "Джона Локка" },
        { id: "Г", text: "Вольтера" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "У розвитку якого жанру відіграла значну роль поезія Беранже?",
      options: [
        { id: "А", text: "оди" },
        { id: "Б", text: "шансону" },
        { id: "В", text: "поеми" },
        { id: "Г", text: "елегії" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "В образі «боговитого юнака» у вірші «Священний союз народів» постає",
      options: [
        { id: "А", text: "персоніфікація миру" },
        { id: "Б", text: "метафора дружби" },
        { id: "В", text: "гіпербола надзвичайних здібностей" },
        { id: "Г", text: "літота можливостей" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 7,
      question: "Перші переклади творів Беранже українською робив",
      options: [
        { id: "А", text: "Павло Грабовський" },
        { id: "Б", text: "Тарас Шевченко" },
        { id: "В", text: "Микола Костомаров" },
        { id: "Г", text: "Михайло Петренко" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 8,
      question: "Довгий час Беранже з його ідеями всесвітнього братерства вважали",
      options: [
        { id: "А", text: "романтиком" },
        { id: "Б", text: "революціонером" },
        { id: "В", text: "радикалом" },
        { id: "Г", text: "утопістом" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
  ],
};
