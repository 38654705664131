export const module45 = {
  title: "§ 45. Узагальнено-особові речення",
  questions: [
    {
      id: 1,
      question: "1. Односкладним є речення",
      options: [
        { id: "А", text: "Віти зморені кругом нахилились над ставком." },
        { id: "Б", text: "Вчи лінивого не молотом, а голодом." },
        { id: "В", text: "У закутку душі хай буде трохи сад." },
        { id: "Г", text: "За розчахнутими віконницями згасав день." }
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Узагальнено-особовим є речення",
      options: [
        { id: "А", text: "Учітесь, читайте, і чужому научайтесь, й свого не цурайтесь." },
        { id: "Б", text: "Злодій не розбагатіє, ненажера не розжиріє." },
        { id: "В", text: "Переказами вовк не ситий." },
        { id: "Г", text: "Без труда нема плода." }
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Узагальнено-особовими є всі речення, ОКРІМ",
      options: [
        { id: "А", text: "Гірко заробиш – солодко з’їси." },
        { id: "Б", text: "За битого двох небитих дають." },
        { id: "В", text: "Пам’ятатимеш до нових віників." },
        { id: "Г", text: "Правда правду перетягне." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Узагальнено-особовим є речення",
      options: [
        { id: "А", text: "Гладжу рукою соболину шерсть ячменів, шовк колосистої хвилі." },
        { id: "Б", text: "Чужого хорошого не гудь, а свого поганого не хвали." },
        { id: "В", text: "Краплинка музики. Росинка музики. Сльозинка музики." },
        { id: "Г", text: "Від минулого не втекти, не врятуватись і не сховатись." }
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Узагальнено-особовими є речення",
      options: [
        { id: "А", text: "Люблю світанкові співи верб." },
        { id: "Б", text: "Пора мініатюрних квітів і коників." },
        { id: "В", text: "Так тихо, спокійно в зелених берегах…" },
        { id: "Г", text: "Будь сильний духом, а не новим кожухом." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Доберіть до кожного типу односкладного речення приклад.",
      dropdownQuestionParts: [
        "<i> <b>Тип односкладного речення</b> </br> 1)   ",
        { id: "dropdown1", text: "означено-особове " },
        "<br /> <i> 2) ",
        { id: "dropdown2", text: "неозначено-особове " },
        "<i><br />3) ",
        { id: "dropdown3", text: "узагальнено-особове " },
        "<i><br />4) ",
        { id: "dropdown4", text: "безособове" },
        "<i> <br/></i>",
      ],
      options: [ 
        { id: "А", text: "Хотілось чогось святочного." },
        { id: "Б", text: "З балки до пагорба скрадалися без звуку." },
        { id: "В", text: "Червону руту не шукай вечорами." },
        { id: "Г", text: "Кожна жаба своє болото хвалить." },
        { id: "Д", text: "У чужому домі не порядкуй." },
      ],
      correctAnswers: {
        dropdown1: "В",
        dropdown2: "Б",
        dropdown3: "Д",
        dropdown4: "А",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за типами: <i><b>односкладні речення; двоскладні речення.</i></b> </br>Перетягни номер речення у відповідний кошик).',
      isGame: true,
      initialWords: [
        { id: 1, text: "У козацькому таборі панувало пожвавлення.", type: "rightBucket" },
        { id: 2, text: "Безшумне обертання сузір’їв. ", type: "leftBucket" },
        { id: 3, text: "Вухами лопухів слухаю мушлю тиші. ", type: "leftBucket" },
        { id: 4, text: "Вершник зупинив коня, спішився. ", type: "rightBucket" },
        { id: 5, text: "Поїхали вздовж річки шукати броду, оминаючи міські ворота. ", type: "leftBucket" },
        { id: 6, text: "Після дощу капелюх не потрібний. ", type: "rightBucket" },
   
      ],
      leftBucketTitle: "Односкладні речення",
      rightBucketTitle: "Двоскладні речення",
    },

    {
      id: 8,
      question:
      '8. Розподіліть речення за типами: <i><b>односкладні речення; двоскладні речення.</i></b> </br>Перетягни номер речення у відповідний кошик).',
      isGame: true,
      initialWords: [
        { id: 1, text: "Наука в ліс не веде, а з лісу виводить. ", type: "rightBucket" },
        { id: 2, text: "Клин клином вибивають. ", type: "leftBucket" },
        { id: 3, text: "Підскочили вгору брови Хмельницького. ", type: "rightBucket" },
        { id: 4, text: "Надворі почало сутеніти. ", type: "leftBucket" },
        { id: 5, text: "Надворі білосніжна зима. ", type: "rightBucket" },
        { id: 6, text: "Від своєї тіні не втечеш.", type: "leftBucket" },
   
      ],
      leftBucketTitle: "Односкладні речення",
      rightBucketTitle: "Двоскладні речення",
    },
    // Додати нові питання сюди
  ],
};
