import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./components/main-page/Main.js";
import { getUserName } from "./controller/UserController.js";
import "./App.css";
import "./css/responsive.css";
import ModulePage from "./components/modules/ModulePage.js";

import Module from "./components/modules/module/Module.js";
import TheoryPage from "./components/theory/TheoryPage.js";

// ==============================Українська мова 8 кл Авраменко================================================
import { module01 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module01.js";
import { module02 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module02.js";
import { module03 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module03.js";
import { module2 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module2.js";
import { module3 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module3.js";
import { module4 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module4.js";
import { module5 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module5.js";
import { module6 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module6.js";
import { module7 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module7.js";
import { module8 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module8.js";
import { module9 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module9.js";
import { module23 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module23.js";
import { module18_19 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module18_19.js";
import { module20_21 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module20_21.js";
import { module41 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module41.js";
import { module42_43 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module42_43.js";
import { module45 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module45.js";
import { module47 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module47.js";
import { module48 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module48.js";
import { module50 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module50.js";
import { module52_53 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module52_53.js";
import { module54_55 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module54_55.js";
import { module57 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module57.js";
import { module59_60 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module59_60.js";
import { module62_63 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module62_63.js";
import { module65_66 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module65_66.js";
import { module69 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module69.js";
import { module70_71 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module70_71.js";
import { module73 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module73.js";
import { module74_75 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module74_75.js";
import { module77 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module77.js";
import { module78_79 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module78_79.js";
import { module80 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module80.js";
import { module81 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module81.js";
import { module82 } from "./classes/class8/subjects/ukrmova/Avramenko/modules/module82.js";


// ==============================Українська література 8 кл Авраменко================================================

import { module01 as ukrlitModule01 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module01.js";
import { module02 as ukrlitModule02 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module02.js";
import { module03 as ukrlitModule03 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module03.js";
import { module04 as ukrlitModule04 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module04.js";
import { module05 as ukrlitModule05 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module05.js";
import { module06 as ukrlitModule06 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module06.js";
import { module07 as ukrlitModule07 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module07.js";
import { module08 as ukrlitModule08 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module08.js";
import { module09 as ukrlitModule09 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module09.js";
import { module10 as ukrlitModule10 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module10.js";
import { module11 as ukrlitModule11 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module11.js";
import { module22_23 as ukrlitModule22_23 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module22_23.js";
import { module24_25 as ukrlitModule24_25 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module24_25.js";
import { module26_27 as ukrlitModule26_27 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module26_27.js";
import { module29_31 as ukrlitModule29_31 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module29_31.js";
import { module32_34 as ukrlitModule32_34 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module32_34.js";
import { module35_38 as ukrlitModule35_38 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module35_38.js";
import { module39_41 as ukrlitModule39_41 } from "./classes/class8/subjects/ukrlit/Avramenko/modules/module39_41.js";


// ============================== Українська література 8 кл Яценко ================================================
import { module01 as ukrlitModule01Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module01.js";
import { module02 as ukrlitModule02Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module02.js";
import { module03 as ukrlitModule03Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module03.js";
import { module04 as ukrlitModule04Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module04.js";
import { module05 as ukrlitModule05Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module05.js";
import { module06 as ukrlitModule06Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module06.js";
import { module07 as ukrlitModule07Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module07.js";
import { module08 as ukrlitModule08Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module08.js";
import { module09 as ukrlitModule09Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module09.js";
import { module10 as ukrlitModule10Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module10.js";
import { module11 as ukrlitModule11Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module11.js";
import { module12 as ukrlitModule12Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module12.js";
import { module13 as ukrlitModule13Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module13.js";
import { module14 as ukrlitModule14Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module14.js";
import { module15 as ukrlitModule15Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module15.js";
import { module16 as ukrlitModule16Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module16.js";
import { module17 as ukrlitModule17Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module17.js";
import { module18 as ukrlitModule18Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module18.js";
import { module19 as ukrlitModule19Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module19.js";
import { module20 as ukrlitModule20Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module20.js";
import { module21 as ukrlitModule21Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module21.js";
import { module22 as ukrlitModule22Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module22.js";
import { module23 as ukrlitModule23Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module23.js";
import { module24 as ukrlitModule24Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module24.js";
import { module25 as ukrlitModule25Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module25.js";
import { module26 as ukrlitModule26Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module26.js";
import { module27 as ukrlitModule27Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module27.js";
import { module28 as ukrlitModule28Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module28.js";
import { module29 as ukrlitModule29Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module29.js";
import { module30 as ukrlitModule30Yac } from "./classes/class8/subjects/ukrlit/Yacenko/modules/module30.js";


// ============================== Інтегрований 8 кл Яценко ================================================
import { module01 as ukrlitModule1IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module01.js";
import { module02 as ukrlitModule2IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module02.js";
import { module03 as ukrlitModule3IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module03.js";
import { module04 as ukrlitModule4IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module04.js";
import { module05 as ukrlitModule5IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module05.js";
import { module06 as ukrlitModule6IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module06.js";
import { module07 as ukrlitModule7IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module07.js";
import { module08 as ukrlitModule8IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module08.js";
import { module09 as ukrlitModule9IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module09.js";
import { module10 as ukrlitModule10IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module10.js";
import { module11 as ukrlitModule11IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module11.js";
import { module12 as ukrlitModule12IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module12.js";
import { module13 as ukrlitModule13IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module13.js";
import { module14 as ukrlitModule14IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module14.js";
import { module15 as ukrlitModule15IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module15.js";
import { module16 as ukrlitModule16IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module16.js";
import { module17 as ukrlitModule17IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module17.js";
import { module18 as ukrlitModule18IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module18.js";
import { module19 as ukrlitModule19IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module19.js";
import { module20 as ukrlitModule20IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module20.js";
import { module21 as ukrlitModule21IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module21.js";
import { module22 as ukrlitModule22IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module22.js";
import { module23 as ukrlitModule23IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module23.js";
import { module24 as ukrlitModule24IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module24.js";
import { module25 as ukrlitModule25IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module25.js";
import { module26 as ukrlitModule26IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module26.js";
import { module27 as ukrlitModule27IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module27.js";
import { module28 as ukrlitModule28IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module28.js";
import { module29 as ukrlitModule29IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module29.js";
import { module30 as ukrlitModule30IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module30.js";
import { module31 as ukrlitModule31IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module31.js";
import { module32 as ukrlitModule32IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module32.js";
import { module33 as ukrlitModule33IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module33.js";
import { module34 as ukrlitModule34IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module34.js";
import { module35 as ukrlitModule35IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module35.js";
import { module36 as ukrlitModule36IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module36.js";
import { module37 as ukrlitModule37IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module37.js";
import { module38 as ukrlitModule38IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module38.js";
import { module39 as ukrlitModule39IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module39.js";
import { module40 as ukrlitModule40IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module40.js";
import { module41 as ukrlitModule41IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module41.js";
import { module42 as ukrlitModule42IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module42.js";
import { module43 as ukrlitModule43IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module43.js";
import { module44 as ukrlitModule44IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module44.js";
import { module45 as ukrlitModule45IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module45.js";
import { module46 as ukrlitModule46IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module46.js";
import { module47 as ukrlitModule47IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module47.js";
import { module48 as ukrlitModule48IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module48.js";
import { module49 as ukrlitModule49IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module49.js";
import { module50 as ukrlitModule50IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module50.js";
import { module51 as ukrlitModule51IntYac } from "./classes/class8/subjects/int/Yacenko/modules/module51.js";


// ============================== ОБЖ 8 кл Поліщук ================================================
import { module01_02 as zbdModule01_02Pol } from "./classes/class8/subjects/zbd/Polishuk/modules/module01_02.js";
import { module03_04 as zbdModule03_04Pol } from "./classes/class8/subjects/zbd/Polishuk/modules/module03_04.js";
import { module05 as zbdModule05Pol } from "./classes/class8/subjects/zbd/Polishuk/modules/module05.js";
import { module06 as zbdModule06Pol } from "./classes/class8/subjects/zbd/Polishuk/modules/module06.js";
import { module07_08 as zbdModule07_08Pol } from "./classes/class8/subjects/zbd/Polishuk/modules/module07_08.js";
import { module09 as zbdModule09Pol } from "./classes/class8/subjects/zbd/Polishuk/modules/module09.js";
import { module15 as zbdModule15Pol } from "./classes/class8/subjects/zbd/Polishuk/modules/module15.js";
import { module17_18 as zbdModule17_18Pol } from "./classes/class8/subjects/zbd/Polishuk/modules/module17_18.js";

// ============================== Укрмова 8 кл Голуб ================================================
import { module01 as ukrmovaModule1Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module01.js";
import { module02 as ukrmovaModule2Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module02.js";
import { module03 as ukrmovaModule3Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module03.js";
import { module04 as ukrmovaModule4Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module04.js";
import { module05 as ukrmovaModule5Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module05.js";
import { module06 as ukrmovaModule6Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module06.js";
import { module07 as ukrmovaModule7Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module07.js";
import { module08 as ukrmovaModule8Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module08.js";
import { module09 as ukrmovaModule9Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module09.js";
import { module10 as ukrmovaModule10Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module10.js";
import { module11 as ukrmovaModule11Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module11.js";
import { module12 as ukrmovaModule12Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module12.js";
import { module13 as ukrmovaModule13Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module13.js";
import { module14 as ukrmovaModule14Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module14.js";
import { module15 as ukrmovaModule15Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module15.js";
import { module16 as ukrmovaModule16Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module16.js";
import { module17 as ukrmovaModule17Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module17.js";
import { module18 as ukrmovaModule18Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module18.js";
import { module19 as ukrmovaModule19Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module19.js";
import { module20 as ukrmovaModule20Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module20.js";
import { module21 as ukrmovaModule21Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module21.js";
import { module22 as ukrmovaModule22Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module22.js";
import { module23 as ukrmovaModule23Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module23.js";
import { module24 as ukrmovaModule24Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module24.js";
import { module25 as ukrmovaModule25Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module25.js";
import { module26 as ukrmovaModule26Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module26.js";
import { module27 as ukrmovaModule27Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module27.js";
import { module28 as ukrmovaModule28Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module28.js";
import { module29 as ukrmovaModule29Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module29.js";
import { module30 as ukrmovaModule30Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module30.js";
import { module31 as ukrmovaModule31Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module31.js";
import { module32 as ukrmovaModule32Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module32.js";
import { module33 as ukrmovaModule33Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module33.js";
import { module34 as ukrmovaModule34Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module34.js";
import { module35 as ukrmovaModule35Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module35.js";
import { module36 as ukrmovaModule36Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module36.js";
import { module37 as ukrmovaModule37Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module37.js";
import { module38 as ukrmovaModule38Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module38.js";
import { module39 as ukrmovaModule39Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module39.js";
import { module40 as ukrmovaModule40Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module40.js";
import { module41 as ukrmovaModule41Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module41.js";
import { module42 as ukrmovaModule42Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module42.js";
import { module43 as ukrmovaModule43Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module43.js";
import { module44 as ukrmovaModule44Gol } from "./classes/class8/subjects/ukrmova/Golub/modules/module44.js";



import { module01 as chutannyaModule1Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module01.js";
import { module02 as chutannyaModule2Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module02.js";
import { module03 as chutannyaModule3Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module03.js";
import { module04 as chutannyaModule4Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module04.js";
import { module05 as chutannyaModule5Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module05.js";
import { module06 as chutannyaModule6Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module06.js";
import { module07 as chutannyaModule7Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module07.js";
import { module08 as chutannyaModule8Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module08.js";
import { module09 as chutannyaModule9Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module09.js";
import { module10 as chutannyaModule10Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module10.js";
import { module11 as chutannyaModule11Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module11.js";
import { module12 as chutannyaModule12Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module12.js";
import { module13 as chutannyaModule13Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module13.js";
import { module14 as chutannyaModule14Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module14.js";
import { module15 as chutannyaModule15Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module15.js";
import { module16 as chutannyaModule16Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module16.js";
import { module17 as chutannyaModule17Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module17.js";
import { module18 as chutannyaModule18Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module18.js";
import { module19 as chutannyaModule19Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module19.js";
import { module20 as chutannyaModule20Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module20.js";
import { module21 as chutannyaModule21Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module21.js";
import { module22 as chutannyaModule22Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module22.js";
import { module23 as chutannyaModule23Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module23.js";
import { module24 as chutannyaModule24Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module24.js";
import { module25 as chutannyaModule25Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module25.js";
import { module26 as chutannyaModule26Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module26.js";
import { module27 as chutannyaModule27Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module27.js";
import { module28 as chutannyaModule28Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module28.js";
import { module29 as chutannyaModule29Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module29.js";
import { module30 as chutannyaModule30Zah } from "./classes/class3/subjects/chutannya/Zaharijchuk/modules/module30.js";

import { module01 as mathModule01Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module01.js";
import { module02 as mathModule02Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module02.js";
import { module03 as mathModule03Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module03.js";
import { module04 as mathModule04Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module04.js";
import { module05 as mathModule05Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module05.js";
import { module06 as mathModule06Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module06.js";
import { module07 as mathModule07Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module07.js";
import { module08 as mathModule08Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module08.js";
import { module09 as mathModule09Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module09.js";
import { module10 as mathModule10Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module10.js";
import { module11 as mathModule11Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module11.js";
import { module12 as mathModule12Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module12.js";
import { module13 as mathModule13Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module13.js";
import { module14 as mathModule14Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module14.js";
import { module15 as mathModule15Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module15.js";
import { module16 as mathModule16Prosh } from "./classes/class1/subjects/math/Proshkuratova/modules/module16.js";

import { test_image } from "./classes/class8/subjects/ukrmova/Avramenko/modules/test_image.js";

import img1_1 from "./images/1_1.jpg";
import ClassPage from "./components/class_page/class_page.js";
import SubjectPage from "./components/subject-page/subject_page.js";
import AuthorPage from "./components/author_page/AuhtorPage.js";
import TeacherPage from "./components/teacher_page/TeacherPage.js";
import TeacherClassPage from "./components/teacher_class_page/TeacherClassPage.js";
import MathModulePage from "./components/modules/MathModulePage.js";
import LoginAs from "./components/login_as_page/LoginAs.js";
import PageInDev from "./components/page-in-dev/PageInDev.js";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      loading: true,
    };
  }

  componentDidMount() {
    this.loadUserName();
  }

  loadUserName = async () => {
    try {
      const userNameResponse = await getUserName();
      if (userNameResponse && userNameResponse.name) {
        this.setState({ userName: userNameResponse.name, loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
      this.setState({ loading: false });
    }
  };

  render() {
    const { userName, loading } = this.state;

    if (loading) {
      // return <Loader />;
    }

    //TODO
    if (!userName) {
      // window.location.href = "http://localhost/api/login";
      // window.location.href = "http://hramota.fun/api/login";
    }

    return (
      <BrowserRouter>
        <Routes>

          <Route path="/" element={<LoginAs />} />

          <Route path="/home" element={<Main userName={userName} />} />

          <Route path="/parents" element={<PageInDev />} />

          <Route path="/class/8/ukrmova/avr/modules/module01"
            element={<ModulePage component={Module} module={module01} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module02"
            element={<ModulePage component={Module} module={module02} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module03"
            element={<ModulePage component={Module} module={module03} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module2"
            element={<ModulePage component={Module} module={module2} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module3"
            element={<ModulePage component={Module} module={module3} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module4"
            element={<ModulePage component={Module} module={module4} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module5"
            element={<ModulePage component={Module} module={module5} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module6"
            element={<ModulePage component={Module} module={module6} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module7"
            element={<ModulePage component={Module} module={module7} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module8"
            element={<ModulePage component={Module} module={module8} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module9"
            element={<ModulePage component={Module} module={module9} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module18_19"
            element={<ModulePage component={Module} module={module18_19} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module20_21"
            element={<ModulePage component={Module} module={module20_21} />}
          />

          <Route
            path="/class/8/ukrmova/avr/modules/module23"
            element={<ModulePage component={Module} module={module23} />}
          />          
         
          <Route
          path="/class/8/ukrmova/avr/modules/module41"
          element={<ModulePage component={Module} module={module41} />}
        />

         <Route
          path="/class/8/ukrmova/avr/modules/module42_43"
          element={<ModulePage component={Module} module={module42_43} />}
        />

          <Route
          path="/class/8/ukrmova/avr/modules/module45"
          element={<ModulePage component={Module} module={module45} />}
        />

          <Route
          path="/class/8/ukrmova/avr/modules/module47"
          element={<ModulePage component={Module} module={module47} />}
        />
        
        <Route
          path="/class/8/ukrmova/avr/modules/module48"
          element={<ModulePage component={Module} module={module48} />}
        />

        <Route
          path="/class/8/ukrmova/avr/modules/module48"
          element={<ModulePage component={Module} module={module48} />}
        />

        <Route
          path="/class/8/ukrmova/avr/modules/module50"
          element={<ModulePage component={Module} module={module50} />}
        />
        <Route
          path="/class/8/ukrmova/avr/modules/module52_53"
          element={<ModulePage component={Module} module={module52_53} />}
        />
        <Route
          path="/class/8/ukrmova/avr/modules/module54_55"
          element={<ModulePage component={Module} module={module54_55} />}
        />
        <Route
          path="/class/8/ukrmova/avr/modules/module57"
          element={<ModulePage component={Module} module={module57} />}
        />
                <Route
          path="/class/8/ukrmova/avr/modules/module59_60"
          element={<ModulePage component={Module} module={module59_60} />}
        />
           <Route
          path="/class/8/ukrmova/avr/modules/module62_63"
          element={<ModulePage component={Module} module={module62_63} />}
        />
                   <Route
          path="/class/8/ukrmova/avr/modules/module65_66"
          element={<ModulePage component={Module} module={module65_66} />}
        />
                     <Route
          path="/class/8/ukrmova/avr/modules/module69"
          element={<ModulePage component={Module} module={module69} />}
        />
                          <Route
          path="/class/8/ukrmova/avr/modules/module70_71"
          element={<ModulePage component={Module} module={module70_71} />}
        />
                                <Route
          path="/class/8/ukrmova/avr/modules/module73"
          element={<ModulePage component={Module} module={module73} />}
        />
          
          <Route
          path="/class/8/ukrmova/avr/modules/module74_75"
          element={<ModulePage component={Module} module={module74_75} />}
        />
          
          
          
          
                           <Route
          path="/class/8/ukrmova/avr/modules/module77"
          element={<ModulePage component={Module} module={module77} />}
        />
                          <Route
          path="/class/8/ukrmova/avr/modules/module78_79"
          element={<ModulePage component={Module} module={module78_79} />}
        />


<Route
          path="/class/8/ukrmova/avr/modules/module80"
          element={<ModulePage component={Module} module={module80} />}
        />
        <Route
          path="/class/8/ukrmova/avr/modules/module81"
          element={<ModulePage component={Module} module={module81} />}
        />

        <Route
          path="/class/8/ukrmova/avr/modules/module82"
          element={<ModulePage component={Module} module={module82} />}
        />

          <Route
            path="/class/8/ukrmova/avr/modules/test"
            element={<ModulePage component={Module} module={test_image} />}
          />



{/* ============================================ Укр літ Авраменко 8 кл ======================================================== */}






          <Route
            path="/class/8/ukrlit/avr/modules/module01"
            element={<ModulePage component={Module} module={ukrlitModule01} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module02"
            element={<ModulePage component={Module} module={ukrlitModule02} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module03"
            element={<ModulePage component={Module} module={ukrlitModule03} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module04"
            element={<ModulePage component={Module} module={ukrlitModule04} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module05"
            element={<ModulePage component={Module} module={ukrlitModule05} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module06"
            element={<ModulePage component={Module} module={ukrlitModule06} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module07"
            element={<ModulePage component={Module} module={ukrlitModule07} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module08"
            element={<ModulePage component={Module} module={ukrlitModule08} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module09"
            element={<ModulePage component={Module} module={ukrlitModule09} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module10"
            element={<ModulePage component={Module} module={ukrlitModule10} />}
          />

          <Route
            path="/class/8/ukrlit/avr/modules/module11"
            element={<ModulePage component={Module} module={ukrlitModule11} />}
          />
          <Route
            path="/class/8/ukrlit/avr/modules/module22_23"
            element={<ModulePage component={Module} module={ukrlitModule22_23} />}
          />
          <Route
            path="/class/8/ukrlit/avr/modules/module24_25"
            element={<ModulePage component={Module} module={ukrlitModule24_25} />}
          />
  <Route
            path="/class/8/ukrlit/avr/modules/module26_27"
            element={<ModulePage component={Module} module={ukrlitModule26_27} />}
          />

<Route
            path="/class/8/ukrlit/avr/modules/module29_31"
            element={<ModulePage component={Module} module={ukrlitModule29_31} />}
          />
<Route
            path="/class/8/ukrlit/avr/modules/module32_34"
            element={<ModulePage component={Module} module={ukrlitModule32_34} />}
          />

<Route
            path="/class/8/ukrlit/avr/modules/module35_38"
            element={<ModulePage component={Module} module={ukrlitModule35_38} />}
          />
          <Route
            path="/class/8/ukrlit/avr/modules/module39_41"
            element={<ModulePage component={Module} module={ukrlitModule39_41} />}
          />


{/* ================================ Укрліт 8 клас Яценко ================================== */}
          <Route
            path="/class/8/ukrlit/yac/modules/module01"
            element={
              <ModulePage component={Module} module={ukrlitModule01Yac} />
            }
          />

          <Route
            path="/class/8/ukrlit/yac/modules/module02"
            element={
              <ModulePage component={Module} module={ukrlitModule02Yac} />
            }
          />

          <Route
            path="/class/8/ukrlit/yac/modules/module03"
            element={
              <ModulePage component={Module} module={ukrlitModule03Yac} />
            }
          />
          <Route
            path="/class/8/ukrlit/yac/modules/module04"
            element={
              <ModulePage component={Module} module={ukrlitModule04Yac} />
            }
          />
          <Route
            path="/class/8/ukrlit/yac/modules/module05"
            element={
              <ModulePage component={Module} module={ukrlitModule05Yac} />
            }
          />

          <Route
            path="/class/8/ukrlit/yac/modules/module06"
            element={
              <ModulePage component={Module} module={ukrlitModule06Yac} />
            }
          />
          <Route
            path="/class/8/ukrlit/yac/modules/module07"
            element={
              <ModulePage component={Module} module={ukrlitModule07Yac} />
            }
          />

          <Route
            path="/class/8/ukrlit/yac/modules/module08"
            element={
              <ModulePage component={Module} module={ukrlitModule08Yac} />
            }
          />

          <Route
            path="/class/8/ukrlit/yac/modules/module09"
            element={
              <ModulePage component={Module} module={ukrlitModule09Yac} />
            }
          />

          <Route
            path="/class/8/ukrlit/yac/modules/module10"
            element={
              <ModulePage component={Module} module={ukrlitModule10Yac} />
            }
          />
          <Route
            path="/class/8/ukrlit/yac/modules/module11"
            element={
              <ModulePage component={Module} module={ukrlitModule11Yac} />
            }
          />
          <Route
            path="/class/8/ukrlit/yac/modules/module12"
            element={
              <ModulePage component={Module} module={ukrlitModule12Yac} />
            }
          />

          <Route
            path="/class/8/ukrlit/yac/modules/module13"
            element={
              <ModulePage component={Module} module={ukrlitModule13Yac} />
            }
          />
          <Route
            path="/class/8/ukrlit/yac/modules/module14"
            element={
              <ModulePage component={Module} module={ukrlitModule14Yac} />
            }
          />
          <Route
            path="/class/8/ukrlit/yac/modules/module15"
            element={
              <ModulePage component={Module} module={ukrlitModule15Yac} />
            }
          />

<Route
            path="/class/8/ukrlit/yac/modules/module16"
            element={
              <ModulePage component={Module} module={ukrlitModule16Yac} />
            }
          />
                    <Route
            path="/class/8/ukrlit/yac/modules/module17"
            element={
              <ModulePage component={Module} module={ukrlitModule17Yac} />
            }
          />
                    <Route
            path="/class/8/ukrlit/yac/modules/module18"
            element={
              <ModulePage component={Module} module={ukrlitModule18Yac} />
            }
          />
          <Route
            path="/class/8/ukrlit/yac/modules/module19"
            element={
              <ModulePage component={Module} module={ukrlitModule19Yac} />
            }
          />
                    <Route
            path="/class/8/ukrlit/yac/modules/module20"
            element={
              <ModulePage component={Module} module={ukrlitModule20Yac} />
            }
          />
             <Route
            path="/class/8/ukrlit/yac/modules/module21"
            element={
              <ModulePage component={Module} module={ukrlitModule21Yac} />
            }
          />
            <Route
            path="/class/8/ukrlit/yac/modules/module22"
            element={
              <ModulePage component={Module} module={ukrlitModule22Yac} />
            }
          />
           <Route
            path="/class/8/ukrlit/yac/modules/module23"
            element={
              <ModulePage component={Module} module={ukrlitModule23Yac} />
            }
          />
                     <Route
            path="/class/8/ukrlit/yac/modules/module24"
            element={
              <ModulePage component={Module} module={ukrlitModule24Yac} />
            }
          />
                      <Route
            path="/class/8/ukrlit/yac/modules/module25"
            element={
              <ModulePage component={Module} module={ukrlitModule25Yac} />
            }
          />
                      <Route
            path="/class/8/ukrlit/yac/modules/module26"
            element={
              <ModulePage component={Module} module={ukrlitModule26Yac} />
            }
          />
                      <Route
            path="/class/8/ukrlit/yac/modules/module27"
            element={
              <ModulePage component={Module} module={ukrlitModule27Yac} />
            }
          />
                      <Route
            path="/class/8/ukrlit/yac/modules/module28"
            element={
              <ModulePage component={Module} module={ukrlitModule28Yac} />
            }
          />
                       <Route
            path="/class/8/ukrlit/yac/modules/module29"
            element={
              <ModulePage component={Module} module={ukrlitModule29Yac} />
            }
          />
                      <Route
            path="/class/8/ukrlit/yac/modules/module30"
            element={
              <ModulePage component={Module} module={ukrlitModule30Yac} />
            }
          />

{/* ================================ Інтегр 8 клас Яценко ================================== */}

          <Route
            path="/class/8/int/yac/modules/module01"
            element={
              <ModulePage component={Module} module={ukrlitModule1IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module02"
            element={
              <ModulePage component={Module} module={ukrlitModule2IntYac} />
            }
          />

          <Route
            path="/class/8/int/yac/modules/module03"
            element={
              <ModulePage component={Module} module={ukrlitModule3IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module04"
            element={
              <ModulePage component={Module} module={ukrlitModule4IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module05"
            element={
              <ModulePage component={Module} module={ukrlitModule5IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module06"
            element={
              <ModulePage component={Module} module={ukrlitModule6IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module07"
            element={
              <ModulePage component={Module} module={ukrlitModule7IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module08"
            element={
              <ModulePage component={Module} module={ukrlitModule8IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module09"
            element={
              <ModulePage component={Module} module={ukrlitModule9IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module10"
            element={
              <ModulePage component={Module} module={ukrlitModule10IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module11"
            element={
              <ModulePage component={Module} module={ukrlitModule11IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module12"
            element={
              <ModulePage component={Module} module={ukrlitModule12IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module13"
            element={
              <ModulePage component={Module} module={ukrlitModule13IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module14"
            element={
              <ModulePage component={Module} module={ukrlitModule14IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module15"
            element={
              <ModulePage component={Module} module={ukrlitModule15IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module16"
            element={
              <ModulePage component={Module} module={ukrlitModule16IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module17"
            element={
              <ModulePage component={Module} module={ukrlitModule17IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module18"
            element={
              <ModulePage component={Module} module={ukrlitModule18IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module19"
            element={
              <ModulePage component={Module} module={ukrlitModule19IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module20"
            element={
              <ModulePage component={Module} module={ukrlitModule20IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module21"
            element={
              <ModulePage component={Module} module={ukrlitModule21IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module22"
            element={
              <ModulePage component={Module} module={ukrlitModule22IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module23"
            element={
              <ModulePage component={Module} module={ukrlitModule23IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module24"
            element={
              <ModulePage component={Module} module={ukrlitModule24IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module25"
            element={
              <ModulePage component={Module} module={ukrlitModule25IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module26"
            element={
              <ModulePage component={Module} module={ukrlitModule26IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module27"
            element={
              <ModulePage component={Module} module={ukrlitModule27IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module28"
            element={
              <ModulePage component={Module} module={ukrlitModule28IntYac} />
            }
          />
          <Route
            path="/class/8/int/yac/modules/module29"
            element={
              <ModulePage component={Module} module={ukrlitModule29IntYac} />
            }
          />
          <Route
  path="/class/8/int/yac/modules/module30"
  element={<ModulePage component={Module} module={ukrlitModule30IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module31"
  element={<ModulePage component={Module} module={ukrlitModule31IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module32"
  element={<ModulePage component={Module} module={ukrlitModule32IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module33"
  element={<ModulePage component={Module} module={ukrlitModule33IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module34"
  element={<ModulePage component={Module} module={ukrlitModule34IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module35"
  element={<ModulePage component={Module} module={ukrlitModule35IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module36"
  element={<ModulePage component={Module} module={ukrlitModule36IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module37"
  element={<ModulePage component={Module} module={ukrlitModule37IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module38"
  element={<ModulePage component={Module} module={ukrlitModule38IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module39"
  element={<ModulePage component={Module} module={ukrlitModule39IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module40"
  element={<ModulePage component={Module} module={ukrlitModule40IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module41"
  element={<ModulePage component={Module} module={ukrlitModule41IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module42"
  element={<ModulePage component={Module} module={ukrlitModule42IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module43"
  element={<ModulePage component={Module} module={ukrlitModule43IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module44"
  element={<ModulePage component={Module} module={ukrlitModule44IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module45"
  element={<ModulePage component={Module} module={ukrlitModule45IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module46"
  element={<ModulePage component={Module} module={ukrlitModule46IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module47"
  element={<ModulePage component={Module} module={ukrlitModule47IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module48"
  element={<ModulePage component={Module} module={ukrlitModule48IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module49"
  element={<ModulePage component={Module} module={ukrlitModule49IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module50"
  element={<ModulePage component={Module} module={ukrlitModule50IntYac} />}
/>
<Route
  path="/class/8/int/yac/modules/module51"
  element={<ModulePage component={Module} module={ukrlitModule51IntYac} />}
/>


{/* ================================ ЗБД 8 клас Поліщук ================================== */}
          <Route
            path="/class/8/zbd/pol/modules/module01_02"
            element={<ModulePage component={Module} module={zbdModule01_02Pol} />}
          />
          <Route
            path="/class/8/zbd/pol/modules/module03_04"
            element={<ModulePage component={Module} module={zbdModule03_04Pol} />}
          />
                    <Route
            path="/class/8/zbd/pol/modules/module05"
            element={<ModulePage component={Module} module={zbdModule05Pol} />}
          />
          <Route
            path="/class/8/zbd/pol/modules/module06"
            element={<ModulePage component={Module} module={zbdModule06Pol} />}
          />
          <Route
            path="/class/8/zbd/pol/modules/module07_08"
            element={<ModulePage component={Module} module={zbdModule07_08Pol} />}
          />
          <Route
            path="/class/8/zbd/pol/modules/module09"
            element={<ModulePage component={Module} module={zbdModule09Pol} />}
          />
          <Route
            path="/class/8/zbd/pol/modules/module15"
            element={<ModulePage component={Module} module={zbdModule15Pol} />}
          />
          <Route
            path="/class/8/zbd/pol/modules/module17_18"
            element={<ModulePage component={Module} module={zbdModule17_18Pol} />}
          />


{/* ================================ Укрілт 8 клас Голуб ================================== */}
          <Route
            path="/class/8/ukrmova/gol/modules/module01"
            element={<ModulePage component={Module} module={ukrmovaModule1Gol} />}
          />

          <Route
            path="/class/8/ukrmova/gol/modules/module02"
            element={<ModulePage component={Module} module={ukrmovaModule2Gol} />}
          />
          <Route
            path="/class/8/ukrmova/gol/modules/module03"
            element={<ModulePage component={Module} module={ukrmovaModule3Gol} />}
          />
          <Route
            path="/class/8/ukrmova/gol/modules/module04"
            element={<ModulePage component={Module} module={ukrmovaModule4Gol} />}
          />

          <Route
            path="/class/8/ukrmova/gol/modules/module05"
            element={<ModulePage component={Module} module={ukrmovaModule5Gol} />}
          />

          <Route
            path="/class/8/ukrmova/gol/modules/module06"
            element={<ModulePage component={Module} module={ukrmovaModule6Gol} />
            }

          />
          <Route
            path="/class/8/ukrmova/gol/modules/module07"
            element={
              <ModulePage component={Module} module={ukrmovaModule7Gol} />
            }

          />
          <Route
            path="/class/8/ukrmova/gol/modules/module08"
            element={
              <ModulePage component={Module} module={ukrmovaModule8Gol} />
            }
          />
          <Route
            path="/class/8/ukrmova/gol/modules/module09"
            element={
              <ModulePage component={Module} module={ukrmovaModule9Gol} />
            }
          />
          <Route
            path="/class/8/ukrmova/gol/modules/module10"
            element={
              <ModulePage component={Module} module={ukrmovaModule10Gol} />
            }
          />
          <Route
            path="/class/8/ukrmova/gol/modules/module11"
            element={
              <ModulePage component={Module} module={ukrmovaModule11Gol} />
            }
          />
          <Route
            path="/class/8/ukrmova/gol/modules/module12"
            element={
              <ModulePage component={Module} module={ukrmovaModule12Gol} />
            }
          />
          <Route
            path="/class/8/ukrmova/gol/modules/module13"
            element={
              <ModulePage component={Module} module={ukrmovaModule13Gol} />
            }
          />
          <Route
            path="/class/8/ukrmova/gol/modules/module14"
            element={
              <ModulePage component={Module} module={ukrmovaModule14Gol} />
            }
          />
          <Route
            path="/class/8/ukrmova/gol/modules/module15"
            element={
              <ModulePage component={Module} module={ukrmovaModule15Gol} />
            }
          />
          <Route
            path="/class/8/ukrmova/gol/modules/module16"
            element={
              <ModulePage component={Module} module={ukrmovaModule16Gol} />
            }
/>

          <Route
  path="/class/8/ukrmova/gol/modules/module17"
  element={<ModulePage component={Module} module={ukrmovaModule17Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module18"
  element={<ModulePage component={Module} module={ukrmovaModule18Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module19"
  element={
    <ModulePage component={Module} module={ukrmovaModule19Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module20"
  element={
    <ModulePage component={Module} module={ukrmovaModule20Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module21"
  element={
    <ModulePage component={Module} module={ukrmovaModule21Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module22"
  element={
    <ModulePage component={Module} module={ukrmovaModule22Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module23"
  element={
    <ModulePage component={Module} module={ukrmovaModule23Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module24"
  element={
    <ModulePage component={Module} module={ukrmovaModule24Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module25"
  element={
    <ModulePage component={Module} module={ukrmovaModule25Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module26"
  element={
    <ModulePage component={Module} module={ukrmovaModule26Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module27"
  element={
    <ModulePage component={Module} module={ukrmovaModule27Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module28"
  element={
    <ModulePage component={Module} module={ukrmovaModule28Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module29"
  element={
    <ModulePage component={Module} module={ukrmovaModule29Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module30"
  element={
    <ModulePage component={Module} module={ukrmovaModule30Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module31"
  element={
    <ModulePage component={Module} module={ukrmovaModule31Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module32"
  element={
    <ModulePage component={Module} module={ukrmovaModule32Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module33"
  element={
    <ModulePage component={Module} module={ukrmovaModule33Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module34"
  element={
    <ModulePage component={Module} module={ukrmovaModule34Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module35"
  element={
    <ModulePage component={Module} module={ukrmovaModule35Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module36"
  element={
    <ModulePage component={Module} module={ukrmovaModule36Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module37"
  element={
    <ModulePage component={Module} module={ukrmovaModule37Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module38"
  element={
    <ModulePage component={Module} module={ukrmovaModule38Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module39"
  element={
    <ModulePage component={Module} module={ukrmovaModule39Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module40"
  element={
    <ModulePage component={Module} module={ukrmovaModule40Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module41"
  element={
    <ModulePage component={Module} module={ukrmovaModule41Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module42"
  element={
    <ModulePage component={Module} module={ukrmovaModule42Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module43"
  element={
    <ModulePage component={Module} module={ukrmovaModule43Gol} />
  }
/>
<Route
  path="/class/8/ukrmova/gol/modules/module44"
  element={
    <ModulePage component={Module} module={ukrmovaModule44Gol} />
  }
/>


{/* ================================ Читання 3 клас Захарійчук ================================== */}
          <Route
            path="/class/3/chutannya/zah/modules/module01"
            element={<ModulePage component={Module} module={chutannyaModule1Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module02"
            element={<ModulePage component={Module} module={chutannyaModule2Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module03"
            element={<ModulePage component={Module} module={chutannyaModule3Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module04"
            element={<ModulePage component={Module} module={chutannyaModule4Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module05"
            element={<ModulePage component={Module} module={chutannyaModule5Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module06"
            element={<ModulePage component={Module} module={chutannyaModule6Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module07"
            element={<ModulePage component={Module} module={chutannyaModule7Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module08"
            element={<ModulePage component={Module} module={chutannyaModule8Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module09"
            element={<ModulePage component={Module} module={chutannyaModule9Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module10"
            element={<ModulePage component={Module} module={chutannyaModule10Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module11"
            element={<ModulePage component={Module} module={chutannyaModule11Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module12"
            element={<ModulePage component={Module} module={chutannyaModule12Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module13"
            element={<ModulePage component={Module} module={chutannyaModule13Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module14"
            element={<ModulePage component={Module} module={chutannyaModule14Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module15"
            element={<ModulePage component={Module} module={chutannyaModule15Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module16"
            element={<ModulePage component={Module} module={chutannyaModule16Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module17"
            element={<ModulePage component={Module} module={chutannyaModule17Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module18"
            element={<ModulePage component={Module} module={chutannyaModule18Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module19"
            element={<ModulePage component={Module} module={chutannyaModule19Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module20"
            element={<ModulePage component={Module} module={chutannyaModule20Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module21"
            element={<ModulePage component={Module} module={chutannyaModule21Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module22"
            element={<ModulePage component={Module} module={chutannyaModule22Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module23"
            element={<ModulePage component={Module} module={chutannyaModule23Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module24"
            element={<ModulePage component={Module} module={chutannyaModule24Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module25"
            element={<ModulePage component={Module} module={chutannyaModule25Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module26"
            element={<ModulePage component={Module} module={chutannyaModule26Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module27"
            element={<ModulePage component={Module} module={chutannyaModule27Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module28"
            element={<ModulePage component={Module} module={chutannyaModule28Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module29"
            element={<ModulePage component={Module} module={chutannyaModule29Zah} />}
          />
          <Route
            path="/class/3/chutannya/zah/modules/module30"
            element={<ModulePage component={Module} module={chutannyaModule30Zah} />}
          />


          <Route
            path="/class/1/math/prosh/modules/module01"
            element={<ModulePage component={Module} module={mathModule01Prosh} />}
          />
          
          <Route
            path="/class/1/math/prosh/modules/module02"
            element={<ModulePage component={Module} module={mathModule02Prosh} />}
          />
          
          <Route
            path="/class/1/math/prosh/modules/module03"
            element={<ModulePage component={Module} module={mathModule03Prosh} />}
          />
          
          <Route
            path="/class/1/math/prosh/modules/module04"
            element={<ModulePage component={Module} module={mathModule04Prosh} />}
          />

          <Route
            path="/class/1/math/prosh/modules/module05"
            element={<ModulePage component={Module} module={mathModule05Prosh} />}
          />

<Route
            path="/class/1/math/prosh/modules/module06"
            element={<ModulePage component={Module} module={mathModule06Prosh} />}
          />

<Route
            path="/class/1/math/prosh/modules/module07"
            element={<ModulePage component={Module} module={mathModule07Prosh} />}
          />

<Route
            path="/class/1/math/prosh/modules/module08"
            element={<ModulePage component={Module} module={mathModule08Prosh} />}
          />

<Route
            path="/class/1/math/prosh/modules/module09"
            element={<ModulePage component={Module} module={mathModule09Prosh} />}
          />

<Route
            path="/class/1/math/prosh/modules/module10"
            element={<ModulePage component={Module} module={mathModule10Prosh} />}
          />

<Route
            path="/class/1/math/prosh/modules/module11"
            element={<ModulePage component={Module} module={mathModule11Prosh} />}
          />

<Route
            path="/class/1/math/prosh/modules/module12"
            element={<ModulePage component={Module} module={mathModule12Prosh} />}
          />

<Route
            path="/class/1/math/prosh/modules/module13"
            element={<ModulePage component={Module} module={mathModule13Prosh} />}
          />

<Route
            path="/class/1/math/prosh/modules/module14"
            element={<ModulePage component={Module} module={mathModule14Prosh} />}
          />

<Route
            path="/class/1/math/prosh/modules/module15"
            element={<ModulePage component={Module} module={mathModule15Prosh} />}
          />

<Route
            path="/class/1/math/prosh/modules/module16"
            element={<ModulePage component={Module} module={mathModule16Prosh} />}
          />




          <Route
            path="/theory/module1"
            element={
              <TheoryPage
                content={
                  <img style={{ width: "70%" }} src={img1_1} alt="img1_1" />
                }
              />}
          />

          <Route
            path="/theory/module2"
            element={
              <TheoryPage
                content={
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/B9tXngTv8ds?si=KMO7CB-Ey1fXi33r`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                }
              />
            }
          />

          <Route path="/class/:id" element={<ClassPage />} />

          <Route path="/class/:id/:subject" element={<SubjectPage />} />

          <Route
            path="/class/:id/:subject/:author/modules"
            element={<AuthorPage />}
          />

          <Route path="/teacher" element={<TeacherPage />} />

          <Route path="/teacher/class/1b" element={<TeacherClassPage />} />

          <Route
            path="/class/1/math/prosh/modules/task01"
            element={
              <ModulePage
                component={MathModulePage}
                path="/math/1/index.html"
              />
            }
          />

          <Route
            path="/class/1/math/prosh/modules/task02"
            element={
              <ModulePage
                component={MathModulePage}
                path="/math/2/index.html"
              />
            }
          />

          <Route
            path="/class/1/math/prosh/modules/task03"
            element={
              <ModulePage
                component={MathModulePage}
                path="/math/3/index.html"
              />
            }
          />

          <Route
            path="/class/1/math/prosh/modules/task04"
            element={
              <ModulePage
                component={MathModulePage}
                path="/math/4/index.html"
              />
            }
          />

          <Route
            path="/class/1/math/prosh/modules/task05"
            element={
              <ModulePage
                component={MathModulePage}
                path="/math/5/index.html"
              />
            }
          />

          <Route
            path="/class/2/ukrmova/zah/modules/module01"
            element={
              <ModulePage
                component={MathModulePage}
                path="/ukrmova/1/module1.html"
              />
            }
          />

          <Route
            path="/class/3/math/prosh/modules/module01"
            element={
              <ModulePage
                component={MathModulePage}
                path="/math/class/3/module01/index.html"
              />
            } />

<Route
            path="/class/3/math/prosh/modules/module02"
            element={
              <ModulePage
                component={MathModulePage}
                path="/math/class/3/module02/task_02_1.html"
              />
            } />

        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
