import {text_mod_3, } from "../text/Text.js";
export const module03 = {
  title:
    "Розділ 1.3. Теми 19-25. Склад чисел. Додавання чисел. ",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_3, },
    ]
  };