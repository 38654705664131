export const module09 = {
  title: "НЕОЗНАЧЕНО-ОСОБОВІ РЕЧЕННЯ. Мандрувати – корисно й захопливо!",
  questions: [
    {
      id: 1,
      question: "Яке речення є неозначено-особовим?",
      options: [
        { id: "А", text: "Ми подорожували Європою минулого літа." },
        { id: "Б", text: "У готелі нам запропонували комфортний номер." },
        { id: "В", text: "Туристи відвідали знамениту пам'ятку." },
        { id: "Г", text: "Подорож була захоплюючою та пізнавальною." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 2,
      question: "Оберіть правильне неозначено-особове речення:",
      options: [
        { id: "А", text: "В аеропорту перевіряють паспорти." },
        { id: "Б", text: "Ми перевіряємо паспорти в аеропорту." },
        { id: "В", text: "Паспорти перевірені в аеропорту." },
        { id: "Г", text: "Паспорти потрібно перевірити в аеропорту." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 3,
      question: "У якому реченні дієслово вжито в неозначено-особовій формі?",
      options: [
        { id: "А", text: "Екскурсовод розповідає про історію міста." },
        { id: "Б", text: "Туристи фотографують визначні місця." },
        { id: "В", text: "У музеї представляють нову виставку." },
        { id: "Г", text: "Ми насолоджуємося прекрасними краєвидами." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "Яке речення НЕ є неозначено-особовим?",
      options: [
        { id: "А", text: "На вокзалі оголосили про прибуття потяга." },
        { id: "Б", text: "У ресторані подають традиційні страви." },
        { id: "В", text: "Нам порадили відвідати цей чудовий парк." },
        { id: "Г", text: "Туристи захоплено розглядають архітектуру міста." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 5,
      question: 'Виберіть правильне продовження неозначено-особового речення: "У цьому готелі..."',
      options: [
        { id: "А", text: "...туристи відпочивають комфортно." },
        { id: "Б", text: "...пропонують різноманітні екскурсії." },
        { id: "В", text: "...ми забронювали номер на тиждень." },
        { id: "Г", text: "...є басейн та спортзал." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "Яке речення можна перетворити на неозначено-особове?",
      options: [
        { id: "А", text: "Мандрівники насолоджуються прекрасним краєвидом." },
        { id: "Б", text: "Подорож була дуже цікавою та пізнавальною." },
        { id: "В", text: "Гід розповідає про історію старовинного замку." },
        { id: "Г", text: "Це місто славиться своїми архітектурними пам'ятками." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "У якому реченні є помилка у вживанні неозначено-особової форми?",
      options: [
        { id: "А", text: "На екскурсії розповідають про місцеві традиції." },
        { id: "Б", text: "У цьому ресторані готують смачні страви." },
        { id: "В", text: "Тут пропонується широкий вибір сувенірів." },
        { id: "Г", text: "На пляжі відпочивають багато туристів." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "Оберіть правильний варіант неозначено-особового речення:",
      options: [
        { id: "А", text: "У цьому місті будують новий музей." },
        { id: "Б", text: "Новий музей будується в цьому місті." },
        { id: "В", text: "Ми будуємо новий музей у цьому місті." },
        { id: "Г", text: "Будівництво нового музею в цьому місті." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
  ],
};
