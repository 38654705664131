import {text_mod_26, } from "../text/Text.js";
export const module26 = {
  title:
    "Тема 26. Лексичне значення слова​​",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_26, },
    ]
  }
