export const module82 = {
  title: "§ 82. Розділові знаки в простому ускладненому реченні",
  questions: [
    {
      id: 1,
      question: "1. Відокремлену прикладку вжито в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Трапилася мені голуба куниця красна дівиця." },
        { id: "Б", text: "Великих вершин досягають на жаль не тільки орли." },
        { id: "В", text: "Море натомлене штормом здається безсилим." },
        { id: "Г", text: "Над горами стояла ніч світла чиста прозора." }
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Вставне слово вжито в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "На жаль сподіватися не треба." },
        { id: "Б", text: "На диво чекали новорічної ночі." },
        { id: "В", text: "На жаль дощі не припиняються." },
        { id: "Г", text: "На щастя сподівається кожний." }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Двокрапку треба поставити перед однорідними членами, а після них — тире в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Луки і ліси під пензлем осені барвисто немов шедеври пейзажиста горять на виставці краси." },
        { id: "Б", text: "Усяке птаство як-от деркачів перепілок куликів курочок можна було викосити косою в траві." },
        { id: "В", text: "І це повітря і покручені лози і зів’яла трава усе це мимохіть нагадувало їй щасливі хвилини життя." },
        { id: "Г", text: "Річки трава дерева птахи люди усе навколо сповнене весняної пружної нестримної сили." }
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Прочитайте речення. <br/> <h4><b><i>Під сосною (1) на теплому твердому піску (2) змішаному з падаличною глицею (3) лежить Тимоха (4) смирний, неговіркий хлопець.</b></i></h4><br/>Тире треба поставити замість цифри",
      options: [
        { id: "А", text: "1" },
        { id: "Б", text: "2" },
        { id: "В", text: "3" },
        { id: "Г", text: "4" }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Прочитайте речення. </br> <h4><b><i> Іржання те розбилося зараз же на десять відгомонів (1) і покотилося по ярузі (2) і пішло бродить і гомоніти понад болотечком так (3) наче не одна конячка заіржала, а (4) принаймні (5) цілий табун.</b></i></h4></br> Коми треба поставити замість усіх цифр, ОКРІМ",
      options: [
        { id: "А", text: "1, 2" },
        { id: "Б", text: "2, 3" },
        { id: "В", text: "3, 4" },
        { id: "Г", text: "4, 5" }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Доберіть умову вживання коми до кожного прикладу речення.",
      dropdownQuestionParts: [
        "<i><b>Умова вживання коми</b></i> <br> 1) кома при вставній конструкції ",
        { id: "dropdown1", text: " - " },
        "<br> 2) кома при однорідних членах ",
        { id: "dropdown2", text: " - " },
        "<br> 3) кома при уточнювальному члені ",
        { id: "dropdown3", text: " - " },
        "<br> 4) кома при відокремленій обставині ",
        { id: "dropdown4", text: " - " },
      ],
      options: [
        { id: "А", text: "Приморозок, тихо скрадаючись, ранить пізній цвіт." },
        { id: "Б", text: "Звичайна, на перший погляд, українська хата." },
        { id: "В", text: "Божий світ, оповитий красою, задрімав у легенькому сні." },
        { id: "Г", text: "Наука в ліс не веде, а з лісу виводить." },
        { id: "Д", text: "Три доби він, Богдан, майже не склеплював повік." },
      ],
      correctAnswers: {
        dropdown1: "Б",
        dropdown2: "Г",
        dropdown3: "Д",
        dropdown4: "А",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 7,
      question:
        '7. Розподіліть прості ускладнені речення за особливостями: <i><b>речення, у яких треба вживати тире; речення, у яких не треба вживати тире.</b></i>',
      isGame: true,
      initialWords: [
        { id: 1, text: "Кость не побіг – помчав до рідної садиби.", type: "leftBucket" },
        { id: 2, text: "Швидко надходив зимовий вечір – морозний, зоряний.", type: "leftBucket" },
        { id: 3, text: "Брязкіт зброї тупіт копит войовничі крики – усе це глушило будь-які інші звуки.", type: "leftBucket" },
        { id: 4, text: "Десь там, на іскристій рівнині, не спить у заметах село.", type: "rightBucket" },
        { id: 5, text: "Із-за городів викотився місяць, ніби величезний жовтогарячий гарбуз.", type: "rightBucket" },
        { id: 6, text: "Ти, юносте, пробач за безтурботній сміх, проміняний дорослою на смуток.", type: "rightBucket" },
      ],
      leftBucketTitle: "Речення, у яких треба вживати тире",
      rightBucketTitle: "Речення, у яких не треба вживати тире",
    },
    {
      id: 8,
      question:
        '8. Розподіліть прості ускладнені речення за особливостями: <i><b>речення, у яких треба вживати тире; речення, у яких не треба вживати тире.</b></i>',
      isGame: true,
      initialWords: [
        { id: 1, text: "У всіх було єдине бажання – найшвидше повернутися додому.", type: "leftBucket" },
        { id: 2, text: "У серці назавжди лишу бездонну синь – Чорне море.", type: "leftBucket" },
        { id: 3, text: "Душа народу не сліпа – стоока.", type: "leftBucket" },
        { id: 4, text: "Окрім Миколи та Юрка, отаман запросив ще й Грицька Баламбуру.", type: "rightBucket" },
        { id: 5, text: "Найбільші шкідники вишень, як відомо, шпаки й горобці.", type: "rightBucket" },
        { id: 6, text: "Чорна карета, запряжена четвіркою коней, похитувалася горбистою дорогою повз мальовниче село.", type: "rightBucket" },
      ],
      leftBucketTitle: "Речення, у яких треба вживати тире",
      rightBucketTitle: "Речення, у яких не треба вживати тире",
    },
  ],
};
