import { text_mod_33_1, text_mod_33_2 } from "../text/Text.js";

export const module33 = {
  title: "Тема 33. Доба Козацького бароко в світовій літературі. Райнер Марія Рільке «Пісня про Правду»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_33_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_33_2,
    },
    {
      id: 3,
      question: "“Пісня про Правду” входить до збірки",
      options: [
        { id: "А", text: "“Подорожник”" },
        { id: "Б", text: "“Жертви ларам”" },
        { id: "В", text: "“Казки про любого Бога”" },
        { id: "Г", text: "“Святвечір”" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "Жанр “Пісні про Правду” Р. М. Рільке –",
      options: [
        { id: "А", text: "легенда" },
        { id: "Б", text: "оповідання-притча" },
        { id: "В", text: "пісня" },
        { id: "Г", text: "повчання" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "“Дивовижними особами” Рільке у «Пісні про Правду» називає",
      options: [
        { id: "А", text: "кобзарів" },
        { id: "Б", text: "іконописців" },
        { id: "В", text: "козаків" },
        { id: "Г", text: "літописців" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 6,
      question: "В уривку “...єдина зупинка, єдиний надійний дороговказ: жодне житло без них не стоїть” мова йде про",
      options: [
        { id: "А", text: "криниці" },
        { id: "Б", text: "перелази" },
        { id: "В", text: "ікони" },
        { id: "Г", text: "традиційні дерева" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "Поява сліпого кобзаря Остапа у хаті Петра Якимовича – це",
      options: [
        { id: "А", text: "зав'язка" },
        { id: "Б", text: "розвиток дії" },
        { id: "В", text: "кульмінація" },
        { id: "Г", text: "розв'язка" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "Головна проблема, розкрита Рільке у «Пісні про Правду»",
      options: [
        { id: "А", text: "віри в Бога" },
        { id: "Б", text: "батьків і дітей" },
        { id: "В", text: "пошуку власного “Я”" },
        { id: "Г", text: "мистецтва і Бога" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
  ],
};
