export const module40 = {
  title: "Визначення понять у процесі суперечки",
  questions: [
    {
      id: 1,
      question: "Яка з наведених характеристик є умовою суперечки?",
      options: [
        { id: "A", text: "Усі учасники повинні погоджуватися" },
        { id: "B", text: "Учасники мають різні думки" },
        { id: "C", text: "Учасники не можуть висловлювати свої думки" },
        { id: "D", text: "Суперечка завжди закінчується конфліктом" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з наведених фраз є тезою в суперечці?",
      options: [
        { id: "A", text: "Успіх - це щастя" },
        { id: "B", text: "Я не згоден з тобою" },
        { id: "C", text: "Гроші - це важливо" },
        { id: "D", text: "Успіх вимірюється матеріальними статками" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з наведених ситуацій є прикладом суперечки?",
      options: [
        { id: "A", text: "Обговорення погоди" },
        { id: "B", text: "Дискусія про успіх" },
        { id: "C", text: "Розмова про улюблені фільми" },
        { id: "D", text: "Вибір місця для відпочинку" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка з наведених фраз є аргументом у суперечці?",
      options: [
        { id: "A", text: "Я не згоден" },
        { id: "B", text: "Успіх - це щастя" },
        { id: "C", text: "Гроші не є єдиним показником успіху" },
        { id: "D", text: "Я думаю, що ти помиляєшся" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених фраз є запитанням для уточнення?",
      options: [
        { id: "A", text: "Чому ти так вважаєш?" },
        { id: "B", text: "Я не згоден з тобою" },
        { id: "C", text: "Успіх - це важливо" },
        { id: "D", text: "Я не розумію твоєї думки" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка з наведених характеристик є важливою для гармонійного спілкування під час суперечки?",
      options: [
        { id: "A", text: "Вміння слухати" },
        { id: "B", text: "Вміння критикувати" },
        { id: "C", text: "Вміння заперечувати" },
        { id: "D", text: "Вміння мовчати" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
  ],
};
