import { text_mod_51_1, text_mod_51_2 } from "../text/Text.js";

export const module51 = {
  title: "Тема 51. Павло Грабовський «Я не співець чудовної природи…»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_51_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_51_2,
    },
    {
      id: 3,
      question: "Більшість українських поетів-реалістів були",
      options: [
        { id: "А", text: "кирило-мефодіївцями" },
        { id: "Б", text: "декабристами" },
        { id: "В", text: "революціонерами" },
        { id: "Г", text: "перекладачами" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "Українські поети-реалісти розглядали художнє слово як",
      options: [
        { id: "А", text: "зброю" },
        { id: "Б", text: "символ" },
        { id: "В", text: "візуальний образ" },
        { id: "Г", text: "емоційний заклик" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 5,
      question: "Павло Грабовський був також відомим",
      options: [
        { id: "А", text: "композитором" },
        { id: "Б", text: "художником" },
        { id: "В", text: "перекладачем" },
        { id: "Г", text: "скульптором" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "Тема поезії “Я не співець чудовної природи…” –",
      options: [
        { id: "А", text: "захоплення красою природи" },
        { id: "Б", text: "роль слова і митця в суспільстві" },
        { id: "В", text: "переживання за долю рідного народу" },
        { id: "Г", text: "оспівування краси й сили рідного слова" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "У рядках “Серед ясних, золо́чених просторів / Я бачу люд без житнього шматка…/ Блакить… пташки… з-під соловйових хорів, / Мов ніж, вража скрізь стогін мужика” НЕ використано",
      options: [
        { id: "А", text: "метафору" },
        { id: "Б", text: "епітети" },
        { id: "В", text: "порівняння" },
        { id: "Г", text: "літоту" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 8,
      question: "“Я не співець чудовної природи” належить до лірики",
      options: [
        { id: "А", text: "пейзажної" },
        { id: "Б", text: "громадянської" },
        { id: "В", text: "інтимної" },
        { id: "Г", text: "філософської" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
