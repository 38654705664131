export const module18 = {
  title: "Знаки довкола нас як засіб інформування",
  questions: [
    {
      id: 1,
      question: "Яка з наведених функцій знаків є основною для передачі інформації про правила дорожнього руху?",
      options: [
        { id: "A", text: "Культурологічна" },
        { id: "B", text: "Інформаційна" },
        { id: "C", text: "Комунікативна" },
        { id: "D", text: "Символічна" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Який з наведених прикладів є знаком-символом в Україні?",
      options: [
        { id: "A", text: "Дорожній знак" },
        { id: "B", text: "Червона калина" },
        { id: "C", text: "Стрілка годинника" },
        { id: "D", text: "Жест вітання" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка інформація є постійним складником маркування товару?",
      options: [
        { id: "A", text: "Номер партії" },
        { id: "B", text: "Дата виготовлення" },
        { id: "C", text: "Товарний знак" },
        { id: "D", text: "Країна-пакувальник" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка з наведених функцій знаків зберігає культурне надбання народу?",
      options: [
        { id: "A", text: "Інформаційна" },
        { id: "B", text: "Комунікативна" },
        { id: "C", text: "Культурологічна" },
        { id: "D", text: "Символічна" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених ознак є прикладом знака?",
      options: [
        { id: "A", text: "Знак, що вказує на обмеження швидкості" },
        { id: "B", text: "Слово 'доброго ранку'" },
        { id: "C", text: "Малюнок квітки" },
        { id: "D", text: "Символ серця" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка з наведених функцій знаків є важливою для міжособистісного спілкування?",
      options: [
        { id: "A", text: "Інформаційна" },
        { id: "B", text: "Комунікативна" },
        { id: "C", text: "Культурологічна" },
        { id: "D", text: "Символічна" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};