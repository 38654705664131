import {text_mod_10, } from "../text/Text.js";
export const module10 = {
  title:
    "Тема 10. Зимові свята",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_10, },
    ]
  }
