import { text_mod_29_1, text_mod_29_2, text_mod_29_3 } from "../text/Text.js";

export const module29 = {
  title: "Григорій Квітка-Основ’яненко «Сватання на Гончарівці»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_29_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_29_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_29_3,
    },
    {
      id: 4,
      question: "П'єса «Сватання на Гончарівці» за жанром",
      options: [
        { id: "A", text: "романтична комедія" },
        { id: "B", text: "соціально-побутова комедія з елементами водевілю" },
        { id: "C", text: "водевіль" },
        { id: "D", text: "пригодницька комедія" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "У п'єсі не порушено проблему",
      options: [
        { id: "A", text: "кохання та сімейного щастя" },
        { id: "B", text: "батьків і дітей" },
        { id: "C", text: "гармонії людини й природи" },
        { id: "D", text: "соціальної нерівності" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 6,
      question: "Мати Уляни проти її шлюбу з Олексієм, бо",
      options: [
        { id: "A", text: "хлопець їй не подобається." },
        { id: "B", text: "юнак має йти до війська." },
        { id: "C", text: "їхня родина багата, а хлопець з бідної сім'ї." },
        { id: "D", text: "парубок є кріпаком." },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 7,
      question: "Мати зауважує Уляні: «Як-таки се можна, щоб тобі з волі та у неволю…» Мова йде про",
      options: [
        { id: "A", text: "одруження зі Стецьком" },
        { id: "B", text: "одруження з Олексієм" },
        { id: "C", text: "влаштування на роботу до панів" },
        { id: "D", text: "переїзд у село" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 8,
      question: "Усі наведені характеристики стосуються образу Уляни, ОКРІМ",
      options: [
        { id: "A", text: "гарна й працьовита, вільнолюбна;" },
        { id: "B", text: "здатна на самопожертву заради щастя з коханим;" },
        { id: "C", text: "забобонна й сварлива" },
        { id: "D", text: "є уособленням кращих моральних рис української дівчини." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 9,
      question: "Персонажі п'єси спілкуються діалектом",
      options: [
        { id: "A", text: "слобожанським" },
        { id: "B", text: "волинським" },
        { id: "C", text: "степовим" },
        { id: "D", text: "гуцульським" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
  ],
};
