import {text_mod_8, } from "../text/Text.js";
export const module08 = {
  title:
    "Тема 8. Про одне й те саме в різний спосіб",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_8, },
    ]
  }
