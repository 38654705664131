export const module34 = {
  title: "Чат",
  questions: [
    {
      id: 1,
      question: "Яка з наведених характеристик найкраще описує аватар у віртуальному спілкуванні?",
      options: [
        { id: "A", text: "Це ім'я користувача в чаті." },
        { id: "B", text: "Це графічне зображення, що представляє користувача в онлайн-середовищі." },
        { id: "C", text: "Це програма для відеозв'язку." },
        { id: "D", text: "Це тип повідомлення в чаті." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з переваг спілкування в чаті є найбільш значущою?",
      options: [
        { id: "A", text: "Можливість використовувати лише текст." },
        { id: "B", text: "Анонімність і швидкість обміну інформацією." },
        { id: "C", text: "Відсутність можливості використовувати смайлики." },
        { id: "D", text: "Обмежений доступ до інформації." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Які фактори впливають на вибір ніка у друзів?",
      options: [
        { id: "A", text: "Довжина ніка." },
        { id: "B", text: "Відповідність особистості та інтересів користувача." },
        { id: "C", text: "Випадковість." },
        { id: "D", text: "Наявність цифр у ніку." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 4,
      question: "В якій ситуації доцільно використовувати смайлики в чатах?",
      options: [
        { id: "A", text: "Коли ви хочете висловити серйозні думки." },
        { id: "B", text: "Коли ви спілкуєтеся з незнайомими людьми." },
        { id: "C", text: "Коли ви хочете передати емоції або підкреслити настрій." },
        { id: "D", text: "Коли ви не знаєте, що написати." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "Що з наведеного не можна писати в чатах?",
      options: [
        { id: "A", text: "Особисті думки." },
        { id: "B", text: "Конфіденційну інформацію." },
        { id: "C", text: "Смайлики." },
        { id: "D", text: "Питання про домашнє завдання." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "Які з наведених правил використання емодзі є правильними?",
      options: [
        { id: "A", text: "Використовувати емодзі в кожному реченні." },
        { id: "B", text: "Використовувати 1-2 смайлики поряд." },
        { id: "C", text: "Заміщати слова емодзі." },
        { id: "D", text: "Використовувати незрозумілі емодзі." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
