import { text_mod_17_1, text_mod_17_2, text_mod_17_3 } from "../text/Text.js";

export const module17 = {
  title: "Ашик Омер",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_17_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_17_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_17_3,
    },
    {
      id: 4,
      question: "За часів ханської держави розвивалася література",
      options: [
        { id: "A", text: "народна" },
        { id: "B", text: "палацова" },
        { id: "C", text: "ханська" },
        { id: "D", text: "ашикська" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Найпоширенішою ашикська поезія є у",
      options: [
        { id: "A", text: "кримських татар" },
        { id: "B", text: "кримчаків" },
        { id: "C", text: "караїмів" },
        { id: "D", text: "усіх тюркомовних народів" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 6,
      question: "Ашиків називають побратимами українських кобзарів, бо",
      options: [
        { id: "A", text: "вони використовували однакові музичні інструменти." },
        { id: "B", text: "вони є носіями ідей високої духовності та національної самосвідомості." },
        { id: "C", text: "будова творів, які вони виконували, дуже схожа." },
        { id: "D", text: "мелодика думи та кошми схожа." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 7,
      question: "Який музичний інструмент принесла у сні Ашику Омеру діва?",
      options: [
        { id: "A", text: "саз" },
        { id: "B", text: "зурну" },
        { id: "C", text: "кавал" },
        { id: "D", text: "деф" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 8,
      question: "Останні роки життя Ашик Омер присвятив",
      options: [
        { id: "A", text: "виготовленню музичних інструментів" },
        { id: "B", text: "спорудженню мечетей" },
        { id: "C", text: "написанню поем" },
        { id: "D", text: "запису фольклору" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 9,
      question: "У рядках “Як риби, ми забилися під камінь / В надії, що врятують море й гори…” Ашик Омер",
      options: [
        { id: "A", text: "передав трагедію рідного народу" },
        { id: "B", text: "описав красу природи рідного краю" },
        { id: "C", text: "висловив бажання бачити Крим вільним" },
        { id: "D", text: "використав фольклорні мотиви" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
  ],
};
