export const module36 = {
  title: "Вторинні тексти. Стислий переказ. Способи скорочення тексту",
  questions: [
    {
      id: 1,
      question: "Які тексти називаються первинними?",
      options: [
        { id: "A", text: "Тексти, що містять нову інформацію" },
        { id: "B", text: "Тексти, що є переказами" },
        { id: "C", text: "Тексти, що є лише короткими замітками" },
        { id: "D", text: "Тексти, що містять лише факти" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 2,
      question: "Чому переказ називають вторинним текстом?",
      options: [
        { id: "A", text: "Тому що він містить лише факти" },
        { id: "B", text: "Тому що він є переробленим варіантом первинного тексту" },
        { id: "C", text: "Тому що він не має сенсу" },
        { id: "D", text: "Тому що він є коротшим за первинний текст" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка основна мета стислого переказу?",
      options: [
        { id: "A", text: "Включити всі деталі" },
        { id: "B", text: "Передати основний зміст тексту, вилучивши несуттєве" },
        { id: "C", text: "Зробити текст більш складним" },
        { id: "D", text: "Додати нові ідеї" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 4,
      question: "Який із наведених варіантів є прикладом скорочення тексту?",
      options: [
        { id: "A", text: "Залишити всі деталі" },
        { id: "B", text: "Сформулювати основну думку" },
        { id: "C", text: "Написати новий текст" },
        { id: "D", text: "Додати більше інформації" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
