import { text_mod_34_1, text_mod_34_2 } from "../text/Text.js";

export const module34 = {
  title: "Тема 34. Кримські татари у світовій літературі. Переказ «Фонтан сліз у Бахчисарайському палаці»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_34_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_34_2,
    },
    {
      id: 3,
      question: "Яке з тверджень НЕ відповідає дійсності?",
      options: [
        { id: "А", text: "Киримли – це історична самоназва кримських татар." },
        { id: "Б", text: "Киримли – це корінний народ нашої держави." },
        { id: "В", text: "Більшість кримських татар за віросповіданням християни." },
        { id: "Г", text: "Киримли є нащадком багатьох етносів, що в різний час проживали в Криму." },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "Крим-Гірей, як вважали в народі, мав замість серця",
      options: [
        { id: "А", text: "камінь" },
        { id: "Б", text: "клубок шерсті" },
        { id: "В", text: "уламок заліза" },
        { id: "Г", text: "уламок скла" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "Крим-Гірей НЕ порівнює красу Діляре із",
      options: [
        { id: "А", text: "джерелом" },
        { id: "Б", text: "сонцем" },
        { id: "В", text: "ланню" },
        { id: "Г", text: "голубкою" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 6,
      question: "У центрі «Фонтану сліз» Омер вирізьбив",
      options: [
        { id: "А", text: "троянду" },
        { id: "Б", text: "голубку" },
        { id: "В", text: "сльозу" },
        { id: "Г", text: "око" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "Скульптор Омер вирізьбив слимака на «Фонтані сліз», щоб",
      options: [
        { id: "А", text: "передати сумнів, який гризе душу хана." },
        { id: "Б", text: "якнайкраще оздобити свій витвір." },
        { id: "В", text: "проявити свій авторський стиль." },
        { id: "Г", text: "не порушувати мистецькі традиції." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 8,
      question: "За задумом майстра Омера, фонтан мав розповісти про",
      options: [
        { id: "А", text: "сльози нерозділеного кохання" },
        { id: "Б", text: "сльози від утрати коханої" },
        { id: "В", text: "пекучі чоловічі сльози" },
        { id: "Г", text: "сльози невільниці Деляре" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
  ],
};
