export const module47 = {
  title: "§ 47. Безособові речення",
  questions: [
    {
      id: 1,
      question: "1. Односкладним є речення",
      options: [
        { id: "А", text: "У степу зелене царство трав." },
        { id: "Б", text: "Не так сталося, як гадалося." },
        { id: "В", text: "Голубий вечір сідав на плечі." },
        { id: "Г", text: "Вода була навдивовижу тепла." }
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Безособовим є речення",
      options: [
        { id: "А", text: "Немає диму без вогню." },
        { id: "Б", text: "Тягне цуценя себе за хвіст." },
        { id: "В", text: "Івасеві снилося дитинство." },
        { id: "Г", text: "Зацокоти мені, коню-птах." }
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Безособовими є всі речення, ОКРІМ",
      options: [
        { id: "А", text: "Треба було шукати сховок на день." },
        { id: "Б", text: "Вже зібрано врожай у саду і в полі." },
        { id: "В", text: "Небо кропило нас святою водою." },
        { id: "Г", text: "Козацькому роду нема переводу." }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Безособовим є речення",
      options: [
        { id: "А", text: "Червону руту не шукай вечорами." },
        { id: "Б", text: "На палубі вже не метушилися." },
        { id: "В", text: "Тиховода Оріль, верболозів розмай." },
        { id: "Г", text: "Навколо було мирно й буденно." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Безособовими є всі речення, ОКРІМ",
      options: [
        { id: "А", text: "Було затишно, пахло грибами, прілим листям і глицею." },
        { id: "Б", text: "Хлопцеві так хочеться втнути щось героїчне, лицарське." },
        { id: "В", text: "Місцями довелося гатити гаті й будувати міст через Сулу." },
        { id: "Г", text: "Щодня себе перемагаю, від суєти застерігаю." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Доберіть до кожного типу односкладного речення приклад.",
      dropdownQuestionParts: [
        "<i> <b>Тип односкладного речення</b> </br> 1)   ",
        { id: "dropdown1", text: "означено-особове " },
        "<br /> <i> 2) ",
        { id: "dropdown2", text: "неозначено-особове " },
        "<i><br />3) ",
        { id: "dropdown3", text: "узагальнено-особове " },
        "<i><br />4) ",
        { id: "dropdown4", text: "безособове" },
        "<i> <br/></i>",
      ],
      options: [ 
        { id: "А", text: "Пахло миром, вечоровим спокоєм, затишком." },
        { id: "Б", text: "Однією рукою і невеличкого вузла не зав’яжеш." },
        { id: "В", text: "Язик до Києва доведе, а в Києві заблудить." },
        { id: "Г", text: "Ношу дрова до куреня, розводжу вогонь, чищу картоплю." },
        { id: "Д", text: "У народних піснях козаків часто порівнювали з орлами." },
      ],
      correctAnswers: {
        dropdown1: "Г",
        dropdown2: "Д",
        dropdown3: "Б",
        dropdown4: "А",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 7,
      question:
        '7. Розподіліть односкладні речення за типами: <i><b>односкладні безособові речення; інші типи односкладних речень.</i></b> </br>Перетягни номер речення у відповідний кошик).',
      isGame: true,
      initialWords: [
        { id: 1, text: "Із чорної кішки білої не зробиш.", type: "rightBucket" },
        { id: 2, text: "Надворі швидко темніло. ", type: "leftBucket" },
        { id: 3, text: "Мені хотілося розмовляти з природою образною мовою, сповненою таємничої краси.  ", type: "leftBucket" },
        { id: 4, text: "Журний шепіт верби, полиневі вітри.  ", type: "rightBucket" },
        { id: 5, text: "Якийсь час роздивлялися водну гладінь могутньої ріки. ", type: "rightBucket" },
    
      ],
      leftBucketTitle: "Односкладні безособові речення",
      rightBucketTitle: "Інші типи односкладних речень",
    },

    {
      id: 8,
      question:
      '8. Розподіліть односкладні речення за типами: <i><b>односкладні безособові речення; інші типи односкладних речень.</i></b> </br>Перетягни номер речення у відповідний кошик).',
      isGame: true,
      initialWords: [
        { id: 1, text: "У дружній родині і в холод те́пло. ", type: "rightBucket" },
        { id: 2, text: "У лісовика грибів випрошую. ", type: "leftBucket" },
        { id: 3, text: "Для духу вольного нема межі́, ні краю. ", type: "rightBucket" },
        { id: 4, text: "Виховуючи дитину, виховуємо себе. ", type: "leftBucket" },
        { id: 5, text: "Брехливу собаку далеко чути. ", type: "rightBucket" },
        { id: 6, text: "Справжні книжки перечитують. ", type: "leftBucket" },
        { id: 7, text: "Почало розвиднюватися. ", type: "rightBucket" },
      ],
      leftBucketTitle: "Односкладні безособові речення",
      rightBucketTitle: "Інші типи односкладних речень",
    },
    // Додати нові питання сюди
  ],
};
