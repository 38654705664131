export const module24 = {
  title: "Особливості медійної інформації. Актуальність інформації",
  questions: [
    {
      id: 1,
      question: "Які з наведених джерел вважаються надійними?",
      options: [
        { id: "A", text: "Анонімні блоги" },
        { id: "B", text: "Офіційні новинні агенції" },
        { id: "C", text: "Соціальні мережі" },
        { id: "D", text: "Чутки" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з наведених характеристик є особливою для медійної інформації?",
      options: [
        { id: "A", text: "Повільність" },
        { id: "B", text: "Неправдивість" },
        { id: "C", text: "Швидкість поширення" },
        { id: "D", text: "Складність" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з наведених ситуацій є прикладом актуальної інформації?",
      options: [
        { id: "A", text: "Інформація про минулі події" },
        { id: "B", text: "Новини про сучасні події" },
        { id: "C", text: "Інформація про стародавні цивілізації" },
        { id: "D", text: "Літературні твори" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка з наведених характеристик є важливою для актуальності інформації?",
      options: [
        { id: "A", text: "Достовірність" },
        { id: "B", text: "Своєчасність" },
        { id: "C", text: "Секретність" },
        { id: "D", text: "Суб'єктивність" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених функцій медійної інформації є найважливішою?",
      options: [
        { id: "A", text: "Формування громадської думки" },
        { id: "B", text: "Розвага" },
        { id: "C", text: "Освіта" },
        { id: "D", text: "Лише інформування" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка з наведених характеристик медійної інформації може бути її недоліком?",
      options: [
        { id: "A", text: "Швидкість" },
        { id: "B", text: "Неправдивість" },
        { id: "C", text: "Доступність" },
        { id: "D", text: "Різноманітність" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
  ],
};
