export const module08 = {
  title: "Односкладні речення з головним членом присудком. Означено-особове речення. Добрі й погані звички",
  questions: [
    {
      id: 1,
      question: "Яке з наведених речень є означено-особовим?",
      options: [
        { id: "А", text: "Завжди мию руки перед їжею." },
        { id: "Б", text: "Дотримуйтесь правил гігієни." },
        { id: "В", text: "Здоровий спосіб життя - запорука успіху." },
        { id: "Г", text: "Чистити зуби двічі на день корисно." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 2,
      question: "У якому реченні головний член виражений дієсловом у формі 1-ої особи множини?",
      options: [
        { id: "А", text: "Щодня займаюсь спортом." },
        { id: "Б", text: "Вживаємо більше овочів та фруктів." },
        { id: "В", text: "Не палять і ведуть здоровий спосіб життя." },
        { id: "Г", text: "Прокидайся рано, щоб встигнути на пробіжку." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 3,
      question: "Визначте означено-особове речення:",
      options: [
        { id: "А", text: "Не можна зловживати солодощами." },
        { id: "Б", text: "Регулярно відвідуй стоматолога." },
        { id: "В", text: "Дотримуюсь режиму дня." },
        { id: "Г", text: "Корисно спати не менше 8 годин." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "У якому реченні присудок виражений дієсловом у формі наказового способу?",
      options: [
        { id: "А", text: "Щодня займаюсь фізичними вправами." },
        { id: "Б", text: "Обмежте споживання шкідливої їжі." },
        { id: "В", text: "Намагаємось їсти менше солодкого." },
        { id: "Г", text: "Читаю перед сном для кращого засинання." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "Яке речення НЕ є означено-особовим?",
      options: [
        { id: "А", text: "Не забувай про правильну поставу." },
        { id: "Б", text: "Вживаємо достатньо води щодня." },
        { id: "В", text: "Здоровий сон - запорука гарного самопочуття." },
        { id: "Г", text: "Регулярно провітрюю кімнату." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "У якому реченні присудок виражений дієсловом у формі майбутнього часу?",
      options: [
        { id: "А", text: "Завтра встану рано і зроблю зарядку." },
        { id: "Б", text: "Намагаюсь менше користуватися гаджетами перед сном." },
        { id: "В", text: "Щодня гуляємо на свіжому повітрі." },
        { id: "Г", text: "Не забувайте про користь фізичної активності." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 7,
      question: "Визначте означено-особове речення з головним членом у формі 2-ої особи однини:",
      options: [
        { id: "А", text: "Дотримуйся правил дорожнього руху." },
        { id: "Б", text: "Намагаємось менше нервувати." },
        { id: "В", text: "Щодня роблю ранкову гімнастику." },
        { id: "Г", text: "Не зловживайте фаст-фудом." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 8,
      question: "Яке з наведених речень є означено-особовим з присудком у теперішньому часі?",
      options: [
        { id: "А", text: "Завжди мий фрукти перед вживанням." },
        { id: "Б", text: "Намагаюсь обмежити час перед екраном." },
        { id: "В", text: "Здорове харчування - основа гарного самопочуття." },
        { id: "Г", text: "Не забудьте про ранкову пробіжку." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
