import { text_mod_6_1, text_mod_6_2 } from "../text/Text.js";

export const module06 = {
  title: "Тема 6. Культура й віра прадавніх українців",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_6_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_6_2,
    },
    {
      id: 3,
      question: "1. Перші письмові згадки про слов’ян датують",
      options: [
        { id: "А", text: "ІV ст. до н. е." },
        { id: "Б", text: "VІ ст. до н. е." },
        { id: "В", text: "VІ ст. н. е." },
        { id: "Г", text: "VІ ст. н. е." },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "2. Наші пращури задовольняли усі духовні запити, давали пояснення до будь-яких дій у",
      options: [
        { id: "А", text: "піснях" },
        { id: "Б", text: "міфах" },
        { id: "В", text: "казках" },
        { id: "Г", text: "легендах" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "3. НЕ є ознакою міфічного мислення",
      options: [
        { id: "А", text: "символізм" },
        { id: "Б", text: "протиставлення суперечностей" },
        { id: "В", text: "магічність" },
        { id: "Г", text: "синкретизм" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "4. Центральний образ праукраїнської міфології",
      options: [
        { id: "А", text: "хліб і сіль" },
        { id: "Б", text: "танець та пісня" },
        { id: "В", text: "вірне кохання" },
        { id: "Г", text: "Дерево життя" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 7,
      question: "5. Форма кола у танці символізує",
      options: [
        { id: "А", text: "зв’язок між різними світами, єднання, одруження" },
        { id: "Б", text: "сонце, родючість, добробут, долю, упорядкованість" },
        { id: "В", text: "перехід із фізичного виміру в метафізичний, потойбічний" },
        { id: "Г", text: "модель Усесвіту" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "6. Вічність, єдність життя у всіх його вимірах і формах на рушнику передає",
      options: [
        { id: "А", text: "Дерево життя" },
        { id: "Б", text: "безкінечник" },
        { id: "В", text: "сонце" },
        { id: "Г", text: "сам рушник" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
