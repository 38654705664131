import { text_mod_44_1, text_mod_44_2 } from "../text/Text.js";

export const module44 = {
  title: "Тема 44. Джордж Ноел Гордон Байрон «Мазепа»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_44_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_44_2,
    },
    {
      id: 3,
      question: "Усі твердження про поему “Мазепа” є правильними, ОКРІМ:",
      options: [
        { id: "А", text: "Поему створено на основі наукової роботи Вольтера “Історія Карла ХІІ”" },
        { id: "Б", text: "Дж. Байрон переповідає у творі історичні події." },
        { id: "В", text: "Тарас Шевченко високо оцінив поему." },
        { id: "Г", text: "У творі є автобіографічні мотиви." },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "За жанром твір Дж. Байрона “Мазепа” –",
      options: [
        { id: "А", text: "роман у віршах" },
        { id: "Б", text: "історична поема" },
        { id: "В", text: "романтична ліро-епічна поема" },
        { id: "Г", text: "пригодницька поема" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "Усі характеристики Мазепи є рисами “байронічного героя”, ОКРІМ:",
      options: [
        { id: "А", text: "типовий герой в типових обставинах" },
        { id: "Б", text: "титанічна особистість" },
        { id: "В", text: "дух бунтаря" },
        { id: "Г", text: "романтична душа" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 6,
      question: "Внутрішній світ головного героя поеми «Мазепа» автор розкриває за допомогою",
      options: [
        { id: "А", text: "авторської характеристики" },
        { id: "Б", text: "ліричних відступів" },
        { id: "В", text: "монологу-сповіді" },
        { id: "Г", text: "висловлювань інших персонажів" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "Якого образу-символу НЕМАЄ в поемі «Мазепа»?",
      options: [
        { id: "А", text: "долі" },
        { id: "Б", text: "човна" },
        { id: "В", text: "дикого коня" },
        { id: "Г", text: "сонця" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "Мазепіана – це",
      options: [
        { id: "А", text: "стиль управління в державі" },
        { id: "Б", text: "історичні документи доби гетьманства Івана Мазепи" },
        { id: "В", text: "мистецькі твори про Мазепу" },
        { id: "Г", text: "вислови відомих людей про Мазепу" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
  ],
};
