const authorsData = {
  1: {
    math: ["Прошкуратова"],
  },
  8: {
    ukrmova: ["Авраменко", "Голуб"],
    ukrlit: ["Авраменко", "Яценко"],
    int: ["Яценко"],
    zbd: ["Поліщук"],
  },
  2: {
    ukrmova: ["Захарійчук"],
  },
  3: {
    math: ["Прошкуратова"],
    chutannya: ["Захарійчук"],
  },
};

export default authorsData;
