export const module23 = {
  title: "Тема 23. Вільям Шекспір. Сонет 130. Трагедія «Ромео і Джульєтта»",
  text_mods: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      content: "Цей модуль присвячений аналізу творчості Вільяма Шекспіра, зокрема його Сонету 130 і трагедії «Ромео і Джульєтта». Ви дізнаєтеся про особливості шекспірівського сонета, головні теми та мотиви, а також аналіз образів та ключових сцен п’єси."
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      content: "Ознайомтеся з уривками екранізацій «Ромео і Джульєтти» та перегляньте інтерпретації Сонета 130 сучасними акторами. Зверніть увагу на те, як режисери і актори передають емоції героїв."
    }
  ],
  questions: [
    {
      id: 3,
      question: "1. Яке ставлення автора до коханої розкривається в останніх двох рядках сонета 130?",
      options: [
        { id: "А", text: "розчарування її недосконалостями" },
        { id: "Б", text: "погляд як на звичайну, непримітну жінку" },
        { id: "В", text: "поцінування її справжньої краси, незважаючи на недоліки" },
        { id: "Г", text: "порівняння її з іншими жінками, яких вважає кращими" },
      ],
      correctAnswer: "В",
      subtitle: "Тестові питання",
      isTest: true,
    },
    {
      id: 4,
      question: "2. Яку роль відіграє брат Лоренцо в розвитку подій п'єси «Ромео і Джульєтта»?",
      options: [
        { id: "А", text: "антагоніст, який перешкоджає коханню Ромео та Джульєтти" },
        { id: "Б", text: "намагається припинити ворожнечу між Монтеккі й Капулетті через шлюб закоханих" },
        { id: "В", text: "байдужий до долі молодих людей" },
        { id: "Г", text: "підбурює Ромео та Джульєтту проти їхніх батьків" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "3. Чому Джульєтта погоджується випити зілля, яке імітує смерть?",
      options: [
        { id: "А", text: "для уникнення шлюбу з Парісом" },
        { id: "Б", text: "щоб помститися батькам" },
        { id: "В", text: "щоб перевірити кохання Ромео" },
        { id: "Г", text: "для втечі з Верони" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 6,
      question: "4. Яке значення має сцена розмови закоханих на балконі для розвитку сюжету?",
      options: [
        { id: "А", text: "показує легковажність почуттів героїв" },
        { id: "Б", text: "розкриває справжню сутність їхнього кохання" },
        { id: "В", text: "є елементом розвитку дії" },
        { id: "Г", text: "показує нерішучість Ромео і Джульєтти" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "5. Яку роль відіграє випадковість у розвитку трагічних подій?",
      options: [
        { id: "А", text: "не має ніякого значення" },
        { id: "Б", text: "є ключовим фактором, який призводить до трагедії" },
        { id: "В", text: "прискорює неминучий фінал" },
        { id: "Г", text: "показує безглуздя людських вчинків" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "6. Яка ідея п’єси В. Шекспіра «Ромео і Джульєтта»?",
      options: [
        { id: "А", text: "Справжнє кохання завжди перемагає" },
        { id: "Б", text: "Ворожнеча між людьми призводить до страждань невинних" },
        { id: "В", text: "Молоді люди не повинні приймати поспішних рішень" },
        { id: "Г", text: "Долю неможливо змінити, все визначено наперед" },
      ],
      correctAnswer: "Б",
      isTest: true,
    }
  ],
};
