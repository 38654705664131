import { text_mod_29_1, text_mod_29_2 } from "../text/Text.js";

export const module29 = {
  title: "Тема 29. Трейсі Шевальє «Дівчина з перловою сережкою»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_29_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_29_2,
    },
    {
      id: 3,
      question: "1. За яких обставин Ґріт познайомилася з родиною Вермеєрів?",
      options: [
        { id: "А", text: "Вони прийшли у дім її батьків, щоб найняти дівчину на службу" },
        { id: "Б", text: "Вона побачила їх на ринковій площі" },
        { id: "В", text: "Вони зустрілися в церкві" },
        { id: "Г", text: "Вона прийшла на співбесіду до їхнього дому" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 4,
      question: "2. Чому Ґріт погодилася працювати служницею у Вермеєрів?",
      options: [
        { id: "А", text: "Їй подобалося мистецтво" },
        { id: "Б", text: "Вона хотіла вийти заміж за художника" },
        { id: "В", text: "Її сім'я потребувала грошей після того, як батько осліп" },
        { id: "Г", text: "Вона хотіла втекти з дому" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "3. Що здивувало Вермеєра, коли він вперше побачив як Ґріт нарізає овочі?",
      options: [
        { id: "А", text: "її акуратність" },
        { id: "Б", text: "спосіб розташування овочів за кольором" },
        { id: "В", text: "швидкість її роботи" },
        { id: "Г", text: "використання незвичайного ножа" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "4. Як Вермеєр вирішив зобразити Ґріт на картині?",
      options: [
        { id: "А", text: "заможною дамою в дорогому одязі" },
        { id: "Б", text: "служницею зі шваброю" },
        { id: "В", text: "просто як вона є, без перевдягання" },
        { id: "Г", text: "як музикантку з інструментом" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "5. Що Ґріт відчувала, коли Вермеєр малював її портрет?",
      options: [
        { id: "А", text: "страх і тривогу" },
        { id: "Б", text: "радість і захоплення" },
        { id: "В", text: "спокій і розслабленість, ніби її там не було" },
        { id: "Г", text: "гнів і обурення" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "6. Чому Ґріт не подивилася на завершену картину?",
      options: [
        { id: "А", text: "Їй було заборонено це робити" },
        { id: "Б", text: "Вона боялася побачити себе" },
        { id: "В", text: "Картина вже була продана" },
        { id: "Г", text: "Вона вирішила не дивитися і пізніше про це шкодувала" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
  ],
};
