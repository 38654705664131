import {text_mod_6, } from "../text/Text.js";
export const module06 = {
  title:
    "Розділ 1.6. Теми 37-47. Додавання і віднімання в межах 10. ",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_6, },
    ]
  };