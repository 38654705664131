import { text_mod_48_1, text_mod_48_2 } from "../text/Text.js";

export const module48 = {
  title: "Тема 48. Тарас Шевченко «Причинна»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_48_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_48_2,
    },
    {
      id: 3,
      question: "Жанр балади походить від",
      options: [
        { id: "А", text: "українських народних пісень" },
        { id: "Б", text: "середньовічних історичних пісень" },
        { id: "В", text: "західноєвропейських танцювально-хорових пісень" },
        { id: "Г", text: "соціально-побутових пісень" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "Підготувати першу збірку “Кобзар” до друку Т. Шевченку допоміг",
      options: [
        { id: "А", text: "поміщик Енгельґард" },
        { id: "Б", text: "Євген Гребінка" },
        { id: "В", text: "Карл Брюллов" },
        { id: "Г", text: "Микола Костомаров" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "Звуковий образ буряної ночі на Дніпрі у баладі “Причинна” Т. Шевченко передає за допомогою",
      options: [
        { id: "А", text: "метафори та епітетів" },
        { id: "Б", text: "епітетів та уособлення" },
        { id: "В", text: "алітерацій та асонансів" },
        { id: "Г", text: "порівняння" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "Ознаками романтизму в баладі є всі названі, ОКРІМ",
      options: [
        { id: "А", text: "Мотиви розлуки, нестерпної туги за коханою людиною." },
        { id: "Б", text: "Фольклорні образи русалок." },
        { id: "В", text: "Існування “двох світів”: світу ідеалу, мрії та світу дійсності." },
        { id: "Г", text: "Показ впливу соціально-побутових умов на формування характеру людини." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 7,
      question: "Рядки “Не русалонька блукає: /То дівчина ходить, / Й сама не зна (бо причинна), /Що такеє робить” у композиції балади є",
      options: [
        { id: "А", text: "епілогом" },
        { id: "Б", text: "зав'язкою" },
        { id: "В", text: "розв'язкою" },
        { id: "Г", text: "кульмінацією" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "Яку тварину з народних вірувань НЕ згадано в баладі?",
      options: [
        { id: "А", text: "кінь" },
        { id: "Б", text: "кішка" },
        { id: "В", text: "півень" },
        { id: "Г", text: "сич" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
