import { Component } from "react";
import Header from "../header_h/Header";

import Footer from "../footer/Footer";

import ModuleContainer from "../module-container/ModuleContainer";
import BreadcrumbsModulePage from "../breadcrumbs/BreadcrumbsModulePage";

class ModulePage extends Component {
  render() {
    const { userName, module, path } = this.props;

    const { component: Component } = this.props;
    return (
      <>
        <Header userName={userName} />
        <BreadcrumbsModulePage />
        <ModuleContainer component={Component} module={module} path={path} />
        <Footer />
      </>
    );
  }
}

export default ModulePage;
