export const module29 = {
  title: "Роздум над проблемою",
  questions: [
    {
      id: 1,
      question: "Яка з наведених характеристик найкраще описує роздум?",
      options: [
        { id: "A", text: "Внутрішній діалог людини з іншими." },
        { id: "B", text: "Внутрішній діалог із собою." },
        { id: "C", text: "Діалог між людьми." },
        { id: "D", text: "Опис подій у часі." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з наведених тем не є типовою для роздумів?",
      options: [
        { id: "A", text: "Сенс життя." },
        { id: "B", text: "Можливості." },
        { id: "C", text: "Прогноз погоди." },
        { id: "D", text: "Вчинки." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з наведених форм роздумів є прикладом спростування?",
      options: [
        { id: "A", text: "Роздум-твердження." },
        { id: "B", text: "Роздум-спостереження." },
        { id: "C", text: "Роздум-спростування." },
        { id: "D", text: "Роздум з прямими доказами." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка особливість тексту-роздуму відрізняє його від інших текстів?",
      options: [
        { id: "A", text: "Використання часових відношень." },
        { id: "B", text: "Використання просторових відношень." },
        { id: "C", text: "Перебування речень у відношеннях причини й наслідку." },
        { id: "D", text: "Використання художніх образів." },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "У яких стилях зазвичай використовують стислий роздум?",
      options: [
        { id: "A", text: "Науковому та діловому." },
        { id: "B", text: "Художньому та розмовно-побутовому." },
        { id: "C", text: "Офіційному та діловому." },
        { id: "D", text: "Науковому та художньому." },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка з наведених форм роздумів включає обґрунтування думки?",
      options: [
        { id: "A", text: "Роздум-спостереження." },
        { id: "B", text: "Роздум з доказами від протилежного." },
        { id: "C", text: "Роздум дискусійного характеру." },
        { id: "D", text: "Роздум-твердження." },
      ],
      correctAnswer: "D",
      isTest: true,
    },
  ],
};
