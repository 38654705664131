import {text_mod_14, } from "../text/Text.js";
export const module14 = {
  title:
    "Розділ 3.4. Теми 114-123. Рік. Місяць. Тиждень. Доба. Календар.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_14, },
    ]
  };




