import { text_mod_43_1, text_mod_43_2 } from "../text/Text.js";

export const module43 = {
  title: "Тема 43. Джонатан Свіфт «Мандри Гуллівера»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_43_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_43_2,
    },
    {
      id: 3,
      question: "За фахом Лемюель Гуллівер був",
      options: [
        { id: "А", text: "письменником" },
        { id: "Б", text: "лікарем" },
        { id: "В", text: "священником" },
        { id: "Г", text: "географом" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "Якою метою Гуллівер подорожує світом?",
      options: [
        { id: "А", text: "у пошуках теплих країв" },
        { id: "Б", text: "розшукує втраченого друга" },
        { id: "В", text: "у пошуках ідеального суспільства" },
        { id: "Г", text: "для проведення географічних досліджень" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "У романі «Мандри Гуллівера» автор НЕ висміює",
      options: [
        { id: "А", text: "політичні інтриги" },
        { id: "Б", text: "наукове невігластво" },
        { id: "В", text: "релігійну роз’єднаність" },
        { id: "Г", text: "родинні конфлікти" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "Втіленням ідеалів Гуллівера є країна",
      options: [
        { id: "А", text: "ліліпутів" },
        { id: "Б", text: "велетнів" },
        { id: "В", text: "розумних коней" },
        { id: "Г", text: "учених" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "Боротьба між партіями високих і низьких каблуків у романі «Мандри Гуллівера» - це пародія на",
      options: [
        { id: "А", text: "суспільний устрій Англії" },
        { id: "Б", text: "політичну систему" },
        { id: "В", text: "релігійні війни" },
        { id: "Г", text: "міжусобні чвари" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "Як пов’язані між собою «Пригоди Робінзона Крузо» Даніеля Дефо та «Мандри Гуллівера» Джонатана Свіфта?",
      options: [
        { id: "А", text: "роман Свіфта є пародією на «Пригоди Робінзона Крузо»" },
        { id: "Б", text: "роман Свіфта є продовженням «Пригод Робінзона Крузо»" },
        { id: "В", text: "ці твори однакові за жанром" },
        { id: "Г", text: "ці твори написані одним і тим же письменником під різними псевдонімами" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
  ],
};
