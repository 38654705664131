import {text_mod_7, } from "../text/Text.js";
export const module07 = {
  title:
    "Розділ 2.1. Теми 48-57. Задачі. ",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_7, },
    ]
  };