export const module35 = {
  title: "Складники друкованого чи цифрового тексту",
  questions: [
    {
      id: 1,
      question: "Які з наведених складників є обов'язковими для цифрового тексту?",
      options: [
        { id: "A", text: "Заголовки" },
        { id: "B", text: "Нумерація" },
        { id: "C", text: "Гіперпокликання" },
        { id: "D", text: "Всі вищезазначені" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка роль гіперпокликань у цифровому тексті?",
      options: [
        { id: "A", text: "Вони прикрашають текст" },
        { id: "B", text: "Вони допомагають переходити до інших частин тексту або ресурсів" },
        { id: "C", text: "Вони є частиною нумерації" },
        { id: "D", text: "Вони не мають жодної ролі" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 3,
      question: "Що таке рубрикація в тексті?",
      options: [
        { id: "A", text: "Визначення терміна" },
        { id: "B", text: "Поділ тексту на логічні частини для кращого сприйняття" },
        { id: "C", text: "Використання кольору для виділення тексту" },
        { id: "D", text: "Нумерація фрагментів тексту" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 4,
      question: "Чому важливо використовувати заголовки в тексті?",
      options: [
        { id: "A", text: "Вони допомагають привернути увагу" },
        { id: "B", text: "Вони роблять текст більш естетичним" },
        { id: "C", text: "Вони структурують інформацію та полегшують її сприйняття" },
        { id: "D", text: "Вони не мають значення" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
  ],
};
