export const module12 = {
  title: "ПОВНІ Й НЕПОВНІ РЕЧЕННЯ. ТИРЕ В НЕПОВНИХ РЕЧЕННЯХ. Вибір професії – це важливо",
  questions: [
    {
      id: 1,
      question: "У якому неповному реченні потрібно поставити тире?",
      options: [
        { id: "А", text: "Лікарі рятують життя, а вчителі душі." },
        { id: "Б", text: "Програмісти створюють майбутнє." },
        { id: "В", text: "Архітектори проектують будівлі." },
        { id: "Г", text: "Журналісти пишуть статті." },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 2,
      question: "Яке з наведених речень є неповним?",
      options: [
        { id: "А", text: "Вибір професії — це відповідальний крок." },
        { id: "Б", text: "Кожен повинен обрати свій шлях у житті." },
        { id: "В", text: "Одні мріють стати акторами, інші — науковцями." },
        { id: "Г", text: "Професія має відповідати здібностям людини." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 3,
      question: "У якому реченні неправильно поставлено тире?",
      options: [
        { id: "А", text: "Хтось обирає професію за покликанням, а хтось — за порадою батьків." },
        { id: "Б", text: "Юристи захищають права, а лікарі — здоров'я." },
        { id: "В", text: "Вчителі навчають дітей, а батьки — виховують." },
        { id: "Г", text: "Інженери проектують машини, а механіки — ремонтують." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "Яке речення є повним?",
      options: [
        { id: "А", text: "Мій брат — програміст." },
        { id: "Б", text: "Сестра мріє стати дизайнером, а я — архітектором." },
        { id: "В", text: "Найважливіше у виборі професії — покликання." },
        { id: "Г", text: "Кожна професія важлива для суспільства." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 5,
      question: "У якому реченні тире поставлено правильно?",
      options: [
        { id: "А", text: "Одні обирають роботу в офісі — інші на свіжому повітрі." },
        { id: "Б", text: "Психологи допомагають людям, а соціальні працівники — суспільству." },
        { id: "В", text: "Вибір професії залежить від — багатьох факторів." },
        { id: "Г", text: "Професія повинна — приносити задоволення." },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "Яке з наведених речень є неповним?",
      options: [
        { id: "А", text: "Професія має відповідати інтересам людини." },
        { id: "Б", text: "Важливо обрати справу до душі." },
        { id: "В", text: "Хороший спеціаліст завжди знайде роботу." },
        { id: "Г", text: "Одні люблять працювати з технікою, інші — з людьми." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
  ],
};
