export const module59_60 = {
  title: "§ 59–60. Узагальнювальні слова в реченнях з однорідними членами",
  questions:
    [
      {
        id: 1,
        question: "1. Двокрапку при однорідних членах треба поставити в реченні (розділові знаки пропущено)",
        options: [
            { id: "А", text: "Жартівлива рука бризнула квітами королевим цвітом крученими паничами чорнобривцями." },
            { id: "Б", text: "Із різьбленого креденса Іванка почала виймати купи газет журналів листівок карток." },
            { id: "В", text: "Під тихий крок зорі під води призаснулі на цей пісок і ми вкладем свій крок." },
            { id: "Г", text: "На високому злегка похилому назад лобі Гурка залягла глибока зморшка." }
        ],
        correctAnswer: "А",
        isTest: true,
    },
    {
        id: 2,
        question: "2. Тире при однорідних членах треба поставити в реченні (розділові знаки пропущено)",
        options: [
            { id: "А", text: "На луках паслися густі череди худоби коні бики вівці дикі тури корови." },
            { id: "Б", text: "Жовта імла поглинула всіх чорних шпаків і вівсянок водяних курочок і ластівок." },
            { id: "В", text: "У темних лісах водилися не лише ведмеді а й інша живина вовки лисиці о́лені." },
            { id: "Г", text: "У річці й латаття і жабуриння і ряска ну геть-чисто все що треба для пейзажу." }
        ],
        correctAnswer: "Г",
        isTest: true,
    },
    {
        id: 3,
        question: "3. Перед однорідними членами треба поставити двокрапку, а після них тире в реченні (розділові знаки пропущено)",
        options: [
            { id: "А", text: "Книжками, старими меблями, моїм милом – таким запахом війнуло від помешкання." },
            { id: "Б", text: "На пагорбі росла велика, покручена плином часу та вітрюганами стара верба." },
            { id: "В", text: "Усе: і житній хліб, і сало, і цибуля, і квашенина капуста – смакувало хлопцеві." },
            { id: "Г", text: "Платонида в печі готувала лакоми́ну: спаржу, боби, баклажани, томатову затірку." }
        ],
        correctAnswer: "В",
        isTest: true,
    },
    {
        id: 4,
        question: "4. Редагування потребує речення",
        options: [
            { id: "А", text: "З-поміж прикрас надаю перевагу ланцюжкам і сережкам." },
            { id: "Б", text: "У лісі назбирали різних грибів, зокрема підберезників." },
            { id: "В", text: "Батько й донька швидко дійшла до Хрещатика." },
            { id: "Г", text: "У Тернополі на форум зібралися лікарі та педіатри." }
        ],
        correctAnswer: "Г",
        isTest: true,
    },
    {
        id: 5,
        question: "5. Редагування потребують усі речення, ОКРІМ",
        options: [
            { id: "А", text: "У саду достигли різні ягоди й малина." },
            { id: "Б", text: "Друзі замовили какао, круасани з вишнею та кремом." },
            { id: "В", text: "Зоряна й Андрій пішов на гурток робототехніки." },
            { id: "Г", text: "Ми любимо й захоплюємося українською поезією." }
        ],
        correctAnswer: "Б",
        isTest: true,
    },
      
    {
      id: 6,
      question: "6. Доберіть до кожної схеми приклад речення (розділові знаки пропущено).",
      dropdownQuestionParts: [
        "<i> <b>Схема речення</b> </br> 1) УС: О, О, О ",
        { id: "dropdown1", text: " - " },
        "<br /> <i> 2) УС, … : О, О, О ",
        { id: "dropdown2", text: " - " },
        "<i><br />3) О, О, О — УС ",
        { id: "dropdown3", text: " - " },
        "<i><br />4) УС: О, О, О — ... ",
        { id: "dropdown4", text: " - " },
        "<i> <br/></i>",
      ],
      options: [ 
        { id: "А", text: "І берег і кручі і лози переповнилися трепетом." },
        { id: "Б", text: "Уся округа берег кручі лози переповнилася трепетом." },
        { id: "В", text: "Уся округа як-от берег кручі лози переповнилася трепетом." },
        { id: "Г", text: "Берег кручі лози уся округа переповнилася трепетом." },
        { id: "Д", text: "Уся округа переповнилася трепетом берег кручі лози." },
      ],
      correctAnswers: {
        dropdown1: "Д",
        dropdown2: "В",
        dropdown3: "Г",
        dropdown4: "Б",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    
    {
      id: 7,
      question:
        '7. Розподіліть речення за особливостями: <i><b>з узагальнювальними словами в реченнях з однорідними членами; без узагальнювальних слів у реченнях з однорідними членами.</b></i>',
      isGame: true,
      initialWords: [
          { id: 1, text: "Пам’ять сила мудрість роду вічні обереги.", type: "rightBucket" },
          { id: 2, text: "Оператор художник директор фільму усі вже на місці.", type: "leftBucket" },
          { id: 3, text: "Усі лісові та степові мешканці утомившись від зимового холоду й голоду чекали весни.", type: "rightBucket" },
          { id: 4, text: "Старий відчув насолоду від смаку хрусткого тільки-но спеченого хліба й усміхнувся.", type: "rightBucket" },
          { id: 5, text: "На постатях усе і кожухи і шапки і навіть обличчя запорошене снігом що годі було впізнати.", type: "leftBucket" },
          { id: 6, text: "Я знала кожну деталь годинника емалевий циферблат тонкі стрілки опуклість скла.", type: "leftBucket" },
      ],
      leftBucketTitle: "З узагальнювальними словами в реченнях з однорідними членами",
      rightBucketTitle: "Без узагальнювальних слів у реченнях з однорідними членами",
    },
    {
      id: 8,
      question:
        '8. Розподіліть речення за особливостями: <i><b>з узагальнювальними словами в реченнях з однорідними членами; без узагальнювальних слів у реченнях з однорідними членами.</b></i>',
      isGame: true,
      initialWords: [
          { id: 1, text: "З усіх усюд з фортеці з дзвіниці з монастиря линули тривожні звуки сполоху.", type: "leftBucket" },
          { id: 2, text: "І вересом і жоржинами і хризантемами такими квітами милувалися перехожі.", type: "leftBucket" },
          { id: 3, text: "Осінній смерк і хрест й зів’лі квіти ось відповідь на всі твої питання.", type: "rightBucket" },
          { id: 4, text: "Перші приморозки вдарили хоча й веселі але не такі вже й ласкаві.", type: "rightBucket" },
          { id: 5, text: "Я показала Іванці кошик із гостинцями з малиновим соком грушками лікувальним медом.", type: "leftBucket" },
          { id: 6, text: "Арсен знявши рукавицю теплою долонею зігнав із брів і вій шорсткий намерзлий сніг.", type: "rightBucket" },
      ],
      leftBucketTitle: "З узагальнювальними словами в реченнях з однорідними членами",
      rightBucketTitle: "Без узагальнювальних слів у реченнях з однорідними членами",
    },
  
    
    // / Додати нові питання сюди
  ],
};
