import { text_mod_37_1, text_mod_37_2 } from "../text/Text.js";

export const module37 = {
  title: "Тема 37. Класицизм як художній напрям",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_37_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_37_2,
    },
    {
      id: 3,
      question: "Класицизм виник у",
      options: [
        { id: "А", text: "Німеччині" },
        { id: "Б", text: "Франції" },
        { id: "В", text: "Італії" },
        { id: "Г", text: "Греції" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "Для класицизму є характерними всі ознаки, ОКРІМ:",
      options: [
        { id: "А", text: "орієнтація на високохудожні зразки античної літератури" },
        { id: "Б", text: "чіткий поділ персонажів на позитивних і негативних" },
        { id: "В", text: "аристократизм" },
        { id: "Г", text: "зображення містичних явищ" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 5,
      question: "Оди, трагедії, героїчні поеми – це твори",
      options: [
        { id: "А", text: "“високого” жанру" },
        { id: "Б", text: "середнього жанру" },
        { id: "В", text: "низького жанру" },
        { id: "Г", text: "заборонені в класицизмі жанри" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 6,
      question: "“Стиль Людовика XIV” – це",
      options: [
        { id: "А", text: "напрям у моді" },
        { id: "Б", text: "назва класицизму ХV ст." },
        { id: "В", text: "напрям в архітектурі" },
        { id: "Г", text: "назва картини" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "Пам'яткою класицизму в архітектурі є",
      options: [
        { id: "А", text: "Паризька школа витончених мистецтв" },
        { id: "Б", text: "Ейфелева вежа" },
        { id: "В", text: "Версальський палац" },
        { id: "Г", text: "Бранденбурзькі ворота" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "Вершиною музичного класицизму є твори",
      options: [
        { id: "А", text: "Шопена й Моцарта" },
        { id: "Б", text: "Моцарта й Бетховена" },
        { id: "В", text: "Баха й Моцарта" },
        { id: "Г", text: "Шопена й Баха" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
