export const module69 = {
  title: "§ 69. Відокремлені члени речення",
  questions: [
    {
      id: 1,
      question: "1. Відокремленим членом ускладнено речення",
      options: [
        { id: "А", text: "При дорозі дрібною курявою вкрите лопушиння, а також листя фруктових дерев." },
        { id: "Б", text: "Ось, схоже, мале ведмежатко побачило щось цікаве на цій кривобокій сосні." },
        { id: "В", text: "Помешкання було обставлене меблями – масивними, порядними, з теплої сосни." },
        { id: "Г", text: "Златко, я зроблю все, щоб ти була щасливою, щоб моя земля стала для тебе рідною." }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Відокремленим членом ускладнено речення",
      options: [
        { id: "А", text: "Ніхто, крім нас, не захистить рідну землю." },
        { id: "Б", text: "Вітаю тебе, старенька вербо, давно не бачилися." },
        { id: "В", text: "На столі було закопчене м’ясо, сушена риба й сухарі." },
        { id: "Г", text: "Тепер кавунами ласували всі: козаки, коні, щасливі білочки." }
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Відокремленими членами ускладнені всі речення, ОКРІМ",
      options: [
        { id: "А", text: "Підвівши голову до неба, сильним голосом кобзар заспівав." },
        { id: "Б", text: "Ми з другом витягли з-під припічка макітерку, накриту рушником." },
        { id: "В", text: "Раптом ми зрозуміли, що в кімнаті, крім нас із Максом, нікого не було." },
        { id: "Г", text: "Лелека поволі, але впевненою ходою йде по вуличці." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Уточнювальною обставиною ускладнено речення",
      options: [
        { id: "А", text: "Екіпаж мчав лісовою дорогою, як ураган." },
        { id: "Б", text: "За кілька місяців, у червні, відкриють пляжний сезон." },
        { id: "В", text: "Микола й Сашко, посміхаючись, переглядалися." },
        { id: "Г", text: "Роман, затамувавши подих, слухав розповіді козаків." }
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Пунктуаційну помилку допущено в реченні",
      options: [
        { id: "А", text: "Привітавшись, уходники зайшли на подвір’я." },
        { id: "Б", text: "Іванка, здивована й радісна, ласкаво мене обняла." },
        { id: "В", text: "Ніяким грибом я не гордував, крім блідої поганки." },
        { id: "Г", text: "Щасливий він дивився вслід чарівній панні." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Доберіть до кожного відокремленого члена речення приклад.",
      dropdownQuestionParts: [
        "<i><b>Відокремлений член речення</b></i> <br> 1) обставина ",
        { id: "dropdown1", text: " - " },
        "<br> 2) додаток ",
        { id: "dropdown2", text: " - " },
        "<br> 3) означення ",
        { id: "dropdown3", text: " - " },
        "<br> 4) прикладка ",
        { id: "dropdown4", text: " - " },
      ],
      options: [
        { id: "А", text: "Пам’ять, сила, мудрість роду — вічні обереги." },
        { id: "Б", text: "Аделя – осяйна, привітна – кинулася вітати гостей." },
        { id: "В", text: "Придивившись до нас, дідусь широко усміхнувся." },
        { id: "Г", text: "Крім Миколи і Сашка, із лабораторії вийшли всі учні." },
        { id: "Д", text: "Замкові стіни були складені з городень – міцних колод." },
      ],
      correctAnswers: {
        dropdown1: "В",
        dropdown2: "Г",
        dropdown3: "Б",
        dropdown4: "Д",
      },
      showAnswerOptions: true,
      dropdownAmount: 4,
      isTest: false,
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за особливостями: <i><b>речення, ускладнені відокремленими членами; речення, не ускладнені відокремленими членами.</b></i>',
      isGame: true,
      initialWords: [
        { id: 1, text: "Цвіте малина, смородина, тютюн, квасоля.", type: "rightBucket" },
        { id: 2, text: "Доступу до капсули немає ні в кого, крім командування.", type: "leftBucket" },
        { id: 3, text: "Ставна та гнучка, вона бігла надзвичайно швидко.", type: "leftBucket" },
        { id: 4, text: "Поставала нова й нова лісова ярина: березові брості, молода папороть, молочай, щавель.", type: "rightBucket" },
        { id: 5, text: "Горлице моя, горличко, як гарно в наших горах!", type: "rightBucket" },
        { id: 6, text: "Хлопці подалися додому – задоволені, повеселілі.", type: "leftBucket" },
      ],
      leftBucketTitle: "Речення, ускладнені відокремленими членами",
      rightBucketTitle: "Речення, не ускладнені відокремленими членами",
    },
    {
      id: 8,
      question:
        '8. Розподіліть речення за особливостями: <i><b>речення, ускладнені відокремленими членами; речення, не ускладнені відокремленими членами.</b></i>',
      isGame: true,
      initialWords: [
        { id: 1, text: "У степу, і в небі, і на буграх стало враз темніше.", type: "rightBucket" },
        { id: 2, text: "У коси я увіткнула гребінець зі скла – цикаду на гілці з вишневим цвітом.", type: "leftBucket" },
        { id: 3, text: "Справді, на поверхні води плавала соснова шишка.", type: "rightBucket" },
        { id: 4, text: "Закусивши губу, юнак занурився у власні думки.", type: "leftBucket" },
        { id: 5, text: "Попрощавшись, я вийшов надвір.", type: "leftBucket" },
        { id: 6, text: "О краю мій, моїх ясних привітів не діставав від мене жодний ворог.", type: "rightBucket" },
      ],
      leftBucketTitle: "Речення, ускладнені відокремленими членами",
      rightBucketTitle: "Речення, не ускладнені відокремленими членами",
    },
  ],
};
