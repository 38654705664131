import {text_mod_13, } from "../text/Text.js";
export const module13 = {
  title:
    "Розділ 3.2. Теми 103-113. Віднімання чисел виду 48 – 8, 48 – 40. Взаємозв’язок дій додавання і віднімання.",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_13, },
    ]
  };




