export const module38 = {
  title: "Емоції у процесі спілкування",
  questions: [
    {
      id: 1,
      question: "Яка з наведених емоцій покращує настрій?",
      options: [
        { id: "A", text: "Страх" },
        { id: "B", text: "Гнів" },
        { id: "C", text: "Радість" },
        { id: "D", text: "Тривога" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 2,
      question: "Яке з наведених висловлень підкреслює важливість інтонації в спілкуванні?",
      options: [
        { id: "A", text: "Теплі слова розтоплюють холодні серця" },
        { id: "B", text: "Щастя на боці тих, хто вміє радіти" },
        { id: "C", text: "Неможливо змінити світ, але можна змінити себе" },
        { id: "D", text: "Інтонація голосу, очі і весь образ співрозмовника містять не менше красномовства, ніж слова" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з порад не стосується вираження емоцій?",
      options: [
        { id: "A", text: "Розпізнайте свої емоції" },
        { id: "B", text: "Використовуйте «Я-повідомлення»" },
        { id: "C", text: "Ігноруйте свої емоції" },
        { id: "D", text: "Практикуйте активне слухання" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка з наведених емоцій є негативною?",
      options: [
        { id: "A", text: "Задоволення" },
        { id: "B", text: "Захоплення" },
        { id: "C", text: "Відчай" },
        { id: "D", text: "Радість" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з порад найбільше допомагає у спілкуванні?",
      options: [
        { id: "A", text: "Контролюйте фізичні прояви емоцій" },
        { id: "B", text: "Ігноруйте почуття інших" },
        { id: "C", text: "Говоріть тільки про себе" },
        { id: "D", text: "Не слухайте співрозмовника" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 6,
      question: "Який з методів допомагає перетворити негативні думки на позитивні?",
      options: [
        { id: "A", text: "Я не зможу - я спробую" },
        { id: "B", text: "Це безнадійно - я не хочу це робити" },
        { id: "C", text: "Я опинився в складній ситуації - це велика проблема" },
        { id: "D", text: "У мене не вийде - я не можу" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
  ],
};
