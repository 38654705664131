import {text_mod_1, } from "../text/Text.js";
export const module01 = {
  title:
    "Тема 1. Літо, прощавай! ",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_1, },
    ]
  }
