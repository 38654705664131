import React, { useState, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import "./WordGameDnD.css";
import "./responsive.css";

const ItemType = {
  WORD: "word",
};

const Word = ({ id, text, isDraggable, draggedWords }) => {
  const isAlreadyDragged = draggedWords.includes(id); // Проверяем, было ли слово перетащено
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemType.WORD,
    item: { id, text },
    canDrag: isDraggable && !isAlreadyDragged, // Отключаем перетаскивание, если слово уже перетащено
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={isDraggable && !isAlreadyDragged ? drag : null}
      className="draggableWord"
      style={{
        opacity: isAlreadyDragged ? 0.5 : isDragging ? 0.5 : 1,
        cursor: isDraggable && !isAlreadyDragged ? "move" : "default",
        margin: "5px",
        backgroundColor: isAlreadyDragged ? "#cccccc" : "#e0e0e0", // Серый цвет для перетащенных слов
        borderRadius: "5px",
        display: "inline-block",
        pointerEvents: isDraggable && !isAlreadyDragged ? "auto" : "none",
      }}
    >
      {text}
    </div>
  );
};

const DropZone = ({ accept, onDrop, children }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept,
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      className="drop-container"
      ref={drop}
      style={{
        backgroundColor: isOver ? "#f0f0f0" : "#FFFF00",
        minHeight: "150px",
        padding: "10px",
        border: "2px dashed #e0e0e0",
        borderRadius: "5px",
      }}
    >
      {children}
    </div>
  );
};

const WordGameDnD = ({
  onScoreUpdate,
  prevScore,
  onGameOver,
  question,
  imgPath,
  videoPath,
  initialWords,
  leftBucketTitle,
  centerBucketTitle,
  rightBucketTitle,
  bucketsAmount,
}) => {
  const [availableWords, setAvailableWords] = useState(initialWords);
  const [leftWords, setLeftWords] = useState([]);
  const [centerWords, setCenterWords] = useState([]);
  const [rightWords, setRightWords] = useState([]);
  const [score, setScore] = useState(prevScore);
  const [newScore, setNewScore] = useState(prevScore);
  const [isGameOver, setIsGameOver] = useState(false);
  const [draggedWords, setDraggedWords] = useState([]); // Состояние для хранения перетащенных слов

  useEffect(() => {
    // Проверяем, если все слова были перетащены, заканчиваем игру
    if (
      [...leftWords, ...centerWords, ...rightWords].length === initialWords.length
    ) {
      setIsGameOver(true);
      onGameOver(true);
    }
  }, [leftWords, centerWords, rightWords, initialWords.length, onGameOver]);

  useEffect(() => {
    if (newScore !== prevScore) {
      onScoreUpdate(newScore);
    }
  }, [newScore, onScoreUpdate, prevScore]);

  const handleDropLeftBucket = (item) => {
    setLeftWords((prev) => [...prev, item]);
    setDraggedWords((prev) => [...prev, item.id]); // Добавляем ID перетащенного слова
    updateScore(item, "leftBucket");
  };

  const handleDropCenterBucket = (item) => {
    setCenterWords((prev) => [...prev, item]);
    setDraggedWords((prev) => [...prev, item.id]);
    updateScore(item, "centerBucket");
  };

  const handleDropRightBucket = (item) => {
    setRightWords((prev) => [...prev, item]);
    setDraggedWords((prev) => [...prev, item.id]);
    updateScore(item, "rightBucket");
  };

  const updateScore = (item, targetType) => {
    const word = initialWords.find((word) => word.id === item.id);
    if (word.type === targetType) {
      setNewScore((prev) => prev + 0.5);
    } else {
      setNewScore((prev) => prev - 0.5);
    }
  };

  const isTouchDevice = () => {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0;
  };

  return (
    <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
      <div className="module-game">
        <div
          className="instructions"
          dangerouslySetInnerHTML={{ __html: question }}
        ></div>
        {imgPath && (
          <img style={{ maxWidth: "100%" }} src={imgPath} alt="moduleImg" />
        )}

        {videoPath && (
          <iframe
            width="350px"
            height="250px"
            style={{ maxWidth: "100%" }}
            src={videoPath}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
        <div className="word-container">
          {availableWords.map((word) => (
            <div key={word.id}>
              
              <Word
                id={word.id}
                text={" " + word.id + " "}
                isDraggable={true}
                draggedWords={draggedWords}
              /><span>{word.text}</span>
            </div>
          ))}
        </div>
        <div className="drop-zones">
          <DropZone accept={ItemType.WORD} onDrop={handleDropLeftBucket}>
            <div
              className="zone-title"
              dangerouslySetInnerHTML={{ __html: leftBucketTitle }}
            ></div>
            {leftWords.map((word) => (
              <Word
                key={word.id}
                id={word.id}
                text={word.text}
                isDraggable={false}
                draggedWords={draggedWords}
              />
            ))}
          </DropZone>
          <DropZone accept={ItemType.WORD} onDrop={handleDropRightBucket}>
            <div
              className="zone-title"
              dangerouslySetInnerHTML={{ __html: rightBucketTitle }}
            ></div>
            {rightWords.map((word) => (
              <Word
                key={word.id}
                id={word.id}
                text={word.text}
                isDraggable={false}
                draggedWords={draggedWords}
              />
            ))}
          </DropZone>
          {bucketsAmount > 2 && (
            <DropZone accept={ItemType.WORD} onDrop={handleDropCenterBucket}>
              <div
                className="zone-title"
                dangerouslySetInnerHTML={{ __html: centerBucketTitle }}
              ></div>
              {centerWords.map((word) => (
                <Word
                  key={word.id}
                  id={word.id}
                  text={word.text}
                  isDraggable={false}
                  draggedWords={draggedWords}
                />
              ))}
            </DropZone>
          )}
        </div>
      </div>
    </DndProvider>
  );
};

export default WordGameDnD;
