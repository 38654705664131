export const module20 = {
  title: "Явний і прихований зміст інформації",
  questions: [
    {
      id: 1,
      question: "Що означає фразеологізм «читати між рядками»?",
      options: [
        { id: "A", text: "Розуміти текст буквально" },
        { id: "B", text: "Знати авторську думку" },
        { id: "C", text: "Відчувати приховані значення" },
        { id: "D", text: "Ігнорувати текст" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з наведених навичок є важливою для розуміння прихованого змісту інформації?",
      options: [
        { id: "A", text: "Багатий словник" },
        { id: "B", text: "Вміння малювати" },
        { id: "C", text: "Вміння писати есе" },
        { id: "D", text: "Знання іноземних мов" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з наведених характеристик є прикладом явного змісту інформації?",
      options: [
        { id: "A", text: "Сигнали, що повідомляють про приховану інформацію" },
        { id: "B", text: "Текст, який сприймається одразу" },
        { id: "C", text: "Символи, що мають багато значень" },
        { id: "D", text: "Метафори в художньому творі" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка з наведених ситуацій є прикладом прихованої інформації?",
      options: [
        { id: "A", text: "Чітке пояснення правил" },
        { id: "B", text: "Зображення, що викликає емоції" },
        { id: "C", text: "Пряме запитання" },
        { id: "D", text: "Оголошення про подію" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених навичок допомагає в аналізі прихованої інформації?",
      options: [
        { id: "A", text: "Творча активність" },
        { id: "B", text: "Вміння малювати" },
        { id: "C", text: "Знання історії" },
        { id: "D", text: "Вміння співати" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 6,
      question: "Який з наведених прикладів є сигналом для розпізнавання прихованої інформації?",
      options: [
        { id: "A", text: "Структура тексту" },
        { id: "B", text: "Вставні слова" },
        { id: "C", text: "Художні засоби" },
        { id: "D", text: "Граматичні правила" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
  ],
};
