export const module70_71 = {
  title: "§ 70–71. Відокремлені означення",
  questions: [
    {
      id: 1,
      question: "1. Поширене означення треба відокремлювати комами в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Наближається повний спеки й світла південь." },
        { id: "Б", text: "Шумлять білим цвітом налиті сади навесні." },
        { id: "В", text: "Мечем і кров’ю писані кросворди ніхто уже повік не розгада." },
        { id: "Г", text: "Хто це там оповитий в туман заховався за сиві каштани?" }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 2,
      question: "2. Невідокремлене означення вжито в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Пропахлі тропіками птахи поверталися до рідного краю." },
        { id: "Б", text: "Пропахлі тропіками поверталися до рідного краю птахи." },
        { id: "В", text: "До рідного краю поверталися птахи пропахлі тропіками." },
        { id: "Г", text: "До рідного краю пропахлі тропіками поверталися птахи." }
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Якщо на місці пропуску поставити словосполучення <B><I>«оздоблені інеєм»</b></i>, то неускладненим стане речення",
      options: [
        { id: "А", text: "… вражають красою дерева." },
        { id: "Б", text: "Вражають красою дерева ... ." },
        { id: "В", text: "Дерева … вражають красою." },
        { id: "Г", text: "Вражають красою … дерева." }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Речення <b><i>Задоволені й усміхнені … поверталися зі стадіону додому</b></i> ускладниться відокремленим означенням, якщо на місці пропуску поставити слово",
      options: [
        { id: "А", text: "глядачі" },
        { id: "Б", text: "футболісти" },
        { id: "В", text: "вони" },
        { id: "Г", text: "тренери" }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Пунктуаційну помилку допущено в реченні",
      options: [
        { id: "А", text: "Прокидається наповнений зеленими пахощами степ." },
        { id: "Б", text: "Засмаглі й оздоровлені покидали ми лазурове узбережжя." },
        { id: "В", text: "Батько сидів із веслом на кормі — веселий та дужий." },
        { id: "Г", text: "Заходив тихий вечір, незмінно вогкий, кудлатий від хмар." }
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 6,
      question: "6. Поширене означення НЕ ТРЕБА відокремлювати комами в реченні (розділові знаки пропущено)",
      options: [
        { id: "А", text: "Селища натомлено поринулі в сон спочивають у теплих обіймах ночі." },
        { id: "Б", text: "Легенький попілець підхоплений силою вогню злітав догори та розходився навколо." },
        { id: "В", text: "Найдовше сніг тримається в затінку або в присипаних листям чи землею ровах." },
        { id: "Г", text: "Застебнутий на всі ґудзики він стояв справді як соняшник у цвіту й тішив мені очі." }
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question:
        '7. Розподіліть речення за особливостями: <i><b>правильно поставлено розділові знаки в реченні; неправильно поставлено розділові знаки в реченні.</b></i>',
      isGame: true,
      initialWords: [
        { id: 1, text: "Мені наснилася криниця, водою сповнена до краю.", type: "leftBucket" },
        { id: 2, text: "Наступним об’єктом полювань стали сусідські голуби – породисті, дорогі.", type: "leftBucket" },
        { id: 3, text: "Човен сплетений із мрій біло-синього дня, тихо тоне.", type: "rightBucket" },
        { id: 4, text: "Стомлений роями пасажирів вулик, залізничного вокзалу надвечір стих.", type: "rightBucket" },
        { id: 5, text: "Озеро, оточене густими кущами та високими вербами, було не широке, а довге.", type: "leftBucket" },
        { id: 6, text: "Ти одчиниш двері рідна, і крилата, вийдеш зустрічати сина на поріг.", type: "rightBucket" },
      ],
      leftBucketTitle: "Правильно поставлено розділові знаки в реченні",
      rightBucketTitle: "Неправильно поставлено розділові знаки в реченні",
    },
    {
      id: 8,
      question:
        '8. Розподіліть речення за особливостями: <i><b>правильно поставлено розділові знаки в реченні; неправильно поставлено розділові знаки в реченні.</b></i>',
      isGame: true,
      initialWords: [
        { id: 1, text: "З моря на місто напливав вранішній туман – легкий, сивий.", type: "leftBucket" },
        { id: 2, text: "В дні прожиті, печально і просто, все було як незайманий сніг.", type: "rightBucket" },
        { id: 3, text: "Нам випала завидна роль, бути біля джерела оновлення життя.", type: "rightBucket" },
        { id: 4, text: "Обережні й лякливі, сарни йшли лісовою гущавиною.", type: "leftBucket" },
        { id: 5, text: "Граційні вони вибігли з-за кущів, і спинилися на галявині.", type: "rightBucket" },
        { id: 6, text: "Дорога, вимощена тесаним камінням, вела до причалу.", type: "leftBucket" },
      ],
      leftBucketTitle: "Правильно поставлено розділові знаки в реченні",
      rightBucketTitle: "Неправильно поставлено розділові знаки в реченні",
    },
  ],
};
