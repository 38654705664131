import { text_mod_30_1, text_mod_30_2, text_mod_30_3 } from "../text/Text.js";

export const module30 = {
  title: "Михайло Старицький «За двома зайцями»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_30_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_30_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_30_3,
    },
    {
      id: 4,
      question: "За жанром п’єса «За двома зайцями» є",
      options: [
        { id: "A", text: "драмою" },
        { id: "B", text: "сатиричною комедією" },
        { id: "C", text: "водевілем" },
        { id: "D", text: "трагікомедією" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Головною темою п’єси є",
      options: [
        { id: "A", text: "кохання та шлюб" },
        { id: "B", text: "соціальна нерівність" },
        { id: "C", text: "свавілля чиновників" },
        { id: "D", text: "засудження людської жадібності та поверховості" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 6,
      question: "Проня Прокопівна втілює в собі",
      options: [
        { id: "A", text: "високі моральні якості" },
        { id: "B", text: "жадібність і вульгарність" },
        { id: "C", text: "щирість і доброзичливість" },
        { id: "D", text: "відданість традиціям" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 7,
      question: "Символізм назви «За двома зайцями»",
      options: [
        { id: "A", text: "заклик до боротьби за справедливість" },
        { id: "B", text: "засудження прагнення досягти всього одразу" },
        { id: "C", text: "відображення соціального протесту" },
        { id: "D", text: "насмішка над традиціями українського села" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 8,
      question: "Персонажі п’єси спілкуються переважно",
      options: [
        { id: "A", text: "українською мовою з домішками суржику" },
        { id: "B", text: "чистою українською мовою" },
        { id: "C", text: "російською мовою" },
        { id: "D", text: "сумішшю української та польської мов" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
  ],
};
