import { text_mod_38_1, text_mod_38_2 } from "../text/Text.js";

export const module38 = {
  title: "Тема 38. Жан Батист Мольєр «Міщанин-шляхтич»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_38_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_38_2,
    },
    {
      id: 3,
      question: "Тема п'єси “Міщанин-шляхтич”",
      options: [
        { id: "А", text: "намагання головного героя зайнятися самоосвітою" },
        { id: "Б", text: "намагання міських багатіїв вибитися в шляхтянство" },
        { id: "В", text: "пошук головним героєм власного “Я”" },
        { id: "Г", text: "прагнення людини бути корисною суспільству" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "Кого з вчителів НЕ було в пана Журдена?",
      options: [
        { id: "А", text: "музики" },
        { id: "Б", text: "танців" },
        { id: "В", text: "філософії" },
        { id: "Г", text: "живопису" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 5,
      question: "Яке з правил класицизму не дотримано в п'єсі?",
      options: [
        { id: "А", text: "поділ на позитивних і негативних персонажів" },
        { id: "Б", text: "викриття вад суспільства" },
        { id: "В", text: "правило єдності дії" },
        { id: "Г", text: "правило єдності місця та часу" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "Мольєр НЕ порушує у п'єсі проблему",
      options: [
        { id: "А", text: "свободи як найвищої цінності" },
        { id: "Б", text: "місця людини в суспільстві" },
        { id: "В", text: "панівних моральних ідеалів" },
        { id: "Г", text: "проблеми освіти" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 7,
      question: "Паразитизм аристократії автор розкриває через образ",
      options: [
        { id: "А", text: "пана Журдена" },
        { id: "Б", text: "Доранта" },
        { id: "В", text: "Клеонта" },
        { id: "Г", text: "пані Журден" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "За жанром “Міщанин-шляхтич” –",
      options: [
        { id: "А", text: "трагікомедія" },
        { id: "Б", text: "водевіль" },
        { id: "В", text: "трагедія" },
        { id: "Г", text: "комедія з елементами балету" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
  ],
};
