import {text_mod_4, } from "../text/Text.js";
export const module04 = {
  title:
    "Тема 4. Дзвонить осінь школярам…",
  questions: [
    {
      id: 1,
      onlyText: true,
      text: text_mod_4, },
    ]
  }
