import { text_mod_39_1, text_mod_39_2 } from "../text/Text.js";

export const module39 = {
  title: "Тема 39. Григорій Квітка-Основ’яненко «Сватання на Гончарівці»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_39_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_39_2,
    },
    {
      id: 3,
      question: "П'єса “Сватання на Гончарівці” за жанром",
      options: [
        { id: "А", text: "романтична комедія" },
        { id: "Б", text: "соціально-побутова комедія з елементами водевілю" },
        { id: "В", text: "водевіль" },
        { id: "Г", text: "пригодницька комедія" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "У п'єсі не порушено проблему",
      options: [
        { id: "А", text: "кохання та сімейного щастя" },
        { id: "Б", text: "батьків і дітей" },
        { id: "В", text: "гармонії людини й природи" },
        { id: "Г", text: "соціальної нерівності" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "Мати Уляни проти її шлюбу з Олексієм, бо",
      options: [
        { id: "А", text: "хлопець їй не подобається." },
        { id: "Б", text: "юнак має йти до війська." },
        { id: "В", text: "їхня родина багата, а хлопець з бідної сім'ї." },
        { id: "Г", text: "парубок є кріпаком." },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "Мати зауважує Уляні: “Як-таки се можна, щоб тобі з волі та у неволю…” Мова йде про",
      options: [
        { id: "А", text: "одруження зі Стецьком" },
        { id: "Б", text: "одруження з Олексієм" },
        { id: "В", text: "влаштування на роботу до панів" },
        { id: "Г", text: "переїзд у село" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "Усі наведені характеристики стосуються образу Уляни, ОКРІМ",
      options: [
        { id: "А", text: "гарна й працьовита, вільнолюбна;" },
        { id: "Б", text: "здатна на самопожертву заради щастя з коханим;" },
        { id: "В", text: "забобонна й сварлива" },
        { id: "Г", text: "є уособленням кращих моральних рис української дівчини." },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "Персонажі п'єси спілкуються діалектом",
      options: [
        { id: "А", text: "слобожанським" },
        { id: "Б", text: "волинським" },
        { id: "В", text: "степовим" },
        { id: "Г", text: "гуцульським" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
  ],
};
