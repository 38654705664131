export const module37 = {
  title: "Стратегії спілкування",
  questions: [
    {
      id: 1,
      question: "Яка з наведених стратегій спілкування є основною метою мовлення?",
      options: [
        { id: "A", text: "Переконувальна" },
        { id: "B", text: "Неправильна" },
        { id: "C", text: "Спонукальна" },
        { id: "D", text: "Всі наведені вище" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 2,
      question: "Яка з тактик спілкування залежить від психологічних особливостей учасників?",
      options: [
        { id: "A", text: "Підготовчо-імпровізаційна" },
        { id: "B", text: "Творча" },
        { id: "C", text: "Наслідувальна" },
        { id: "D", text: "Всі наведені вище" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 3,
      question: "Яка з наведених стратегій не є видом стратегії за основною метою?",
      options: [
        { id: "A", text: "Інформативна" },
        { id: "B", text: "Стратегія відмови" },
        { id: "C", text: "Стратегія спілкування" },
        { id: "D", text: "Переконувальна" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 4,
      question: "Яка з тактик є найбільш підходящою для імпровізації в спілкуванні?",
      options: [
        { id: "A", text: "Підготовча" },
        { id: "B", text: "Творча" },
        { id: "C", text: "Наслідувальна" },
        { id: "D", text: "Неправильна" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 5,
      question: "Яка з наведених стратегій є предметною?",
      options: [
        { id: "A", text: "Стратегія відмови" },
        { id: "B", text: "Інформативна" },
        { id: "C", text: "Спонукальна" },
        { id: "D", text: "Переконувальна" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 6,
      question: "Яка з тактик спілкування є найбільш універсальною?",
      options: [
        { id: "A", text: "Творча" },
        { id: "B", text: "Підготовчо-імпровізаційна" },
        { id: "C", text: "Наслідувальна" },
        { id: "D", text: "Всі наведені вище" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
  ],
};
