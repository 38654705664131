import { text_mod_13_1, text_mod_13_2 } from "../text/Text.js";

export const module13 = {
  title: "Тема 13. ЛІТЕРАТУРА СЕРЕДНЬОВІЧЧЯ. Доба Середньовіччя у світовій літературі",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_13_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_13_2,
    },
    {
      id: 3,
      question: "1. Який світогляд став основою європейської цивілізації після занепаду античного світу?",
      options: [
        { id: "А", text: "Язичництво" },
        { id: "Б", text: "Іслам" },
        { id: "В", text: "Християнство" },
        { id: "Г", text: "Буддизм" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 4,
      question: "2. Що таке теоцентризм у контексті середньовічного світогляду?",
      options: [
        { id: "А", text: "Зосередження на людині як центрі всесвіту" },
        { id: "Б", text: "Повне зосередження на ідеї Бога як творця і мети буття" },
        { id: "В", text: "Концентрація на природі як головній силі" },
        { id: "Г", text: "Фокус на науковому пізнанні світу" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 5,
      question: "3. Яка із перелічених ознак НЕ Є характерною для середньовічного мистецтва?",
      options: [
        { id: "А", text: "Алегоричність" },
        { id: "Б", text: "Символізм" },
        { id: "В", text: "Реалізм" },
        { id: "Г", text: "Церковна спрямованість" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "4. Які мистецькі стилі переважали в архітектурі Середньовіччя?",
      options: [
        { id: "А", text: "Бароко і класицизм" },
        { id: "Б", text: "Романський і готичний" },
        { id: "В", text: "Ренесанс і маньєризм" },
        { id: "Г", text: "Рококо і модерн" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 7,
      question: "5. Яка особливість НЕ притаманна готичному стилю в архітектурі?",
      options: [
        { id: "А", text: "Гострокутні високі шпилі" },
        { id: "Б", text: "Масивні фігури на геометричній основі" },
        { id: "В", text: "Великі вікна з вітражами" },
        { id: "Г", text: "Арки і склепіння" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 8,
      question: "6. Яке твердження щодо літератури Середньовіччя є правильним?",
      options: [
        { id: "А", text: "Існувала лише церковна література" },
        { id: "Б", text: "Писали виключно латинською мовою" },
        { id: "В", text: "Переважали наукові трактати" },
        { id: "Г", text: "Спостерігалося жанрово-тематичне розмаїття" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
  ],
};
