const paths = {
  Авраменко: "avr",
  Яценко: "yac",
  Маляр: "mal",
  Поліщук: "pol",
  Прошкуратова: "prosh",
  Голуб: "gol",
  Захарійчук: "zah",
};

export default paths;
