import { text_mod_4_1, text_mod_4_2 } from "../text/Text.js";

export const module04 = {
  title: "Тема 4. Есхіл «Прометей закутий»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_4_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_4_2,
    },
    {
      id: 3,
      question: "1. Театральні вистави в Елладі були пов'язані зі святами на честь",
      options: [
        { id: "А", text: "Зевса" },
        { id: "Б", text: "Діоніса" },
        { id: "В", text: "Гери" },
        { id: "Г", text: "Аполлона" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "2. Сюжетною основою античних трагедій були",
      options: [
        { id: "А", text: "життєвий досвід автора" },
        { id: "Б", text: "звичайне життя людей" },
        { id: "В", text: "перекази" },
        { id: "Г", text: "міфи" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 5,
      question: "3. Що в античних трагедіях заборонялося показувати на сцені?",
      options: [
        { id: "А", text: "народження героя" },
        { id: "Б", text: "одруження героя" },
        { id: "В", text: "смерть героя" },
        { id: "Г", text: "трапези" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 6,
      question: "4. Основний конфлікт у трагедії Есхіла «Прометей закутий» відбувається між",
      options: [
        { id: "А", text: "Зевсом і Мойрами" },
        { id: "Б", text: "Прометеєм та Гефестом" },
        { id: "В", text: "Зевсом та Прометеєм" },
        { id: "Г", text: "Прометеєм та Мойрами" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "5. До чого закликає Океан титана Прометея у трагедії Есхіла?",
      options: [
        { id: "А", text: "скоритися Зевсові" },
        { id: "Б", text: "обмовити Гефеста" },
        { id: "В", text: "утекти" },
        { id: "Г", text: "благати про звільнення" },
      ],
      correctAnswer: "А",
      isTest: true,
    },
    {
      id: 8,
      question: "6. Які проблеми НЕ розкриваються в трагедії Есхіла «Прометей закутий»?",
      options: [
        { id: "А", text: "засудження тиранії" },
        { id: "Б", text: "перемоги богів над людьми" },
        { id: "В", text: "свободи та нескореності" },
        { id: "Г", text: "утвердження ідеї служіння людству" },
      ],
      correctAnswer: "Б",
      isTest: true,
    },
  ],
};
