
import { text_mod_19_1, text_mod_19_2, text_mod_19_3 } from "../text/Text.js";

export const module19 = {
  title: "Романтизм в українській літературі",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_19_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_19_2,
    },
    {
      id: 3,
      subtitle: "ЧИТАЦЬКЕ ДОЗВІЛЛЯ",
      onlyText: true,
      text: text_mod_19_3,
    },
    {
      id: 4,
      question: "Українське національне відродження ХІХ ст. протистояло",
      options: [
        { id: "A", text: "імперській владі" },
        { id: "B", text: "відсутності культури у народних масах" },
        { id: "C", text: "переважанню фольклорних творів" },
        { id: "D", text: "літературі, написаній книжною мовою" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 5,
      question: "У якій із європейських літератур у ХІХ ст. виникла «українська школа»?",
      options: [
        { id: "A", text: "польській" },
        { id: "B", text: "англійській" },
        { id: "C", text: "німецькій" },
        { id: "D", text: "іспанській" },
      ],
      correctAnswer: "A",
      isTest: true,
    },
    {
      id: 6,
      question: "Нова українська література відрізнялася від давнього письменства перш за все",
      options: [
        { id: "A", text: "жанрами творів" },
        { id: "B", text: "кількістю письменників" },
        { id: "C", text: "мовою, якою писали художні твори" },
        { id: "D", text: "художніми засобами, використаними у творах" },
      ],
      correctAnswer: "C",
      isTest: true,
    },
    {
      id: 7,
      question: "Основними рисами романтизму як літературного напряму є всі, ОКРІМ",
      options: [
        { id: "A", text: "розкриття внутрішнього світу людини" },
        { id: "B", text: "парадність, урочистість, пишність, динамічність" },
        { id: "C", text: "символізм" },
        { id: "D", text: "послідовний націоналізм" },
      ],
      correctAnswer: "B",
      isTest: true,
    },
    {
      id: 8,
      question: "Поема Івана Котляревського «Енеїда» стала",
      options: [
        { id: "A", text: "першим зразком літератури романтизму" },
        { id: "B", text: "книжним героїчним епосом" },
        { id: "C", text: "останнім твором давнього українського письменства" },
        { id: "D", text: "першим твором нової української літератури" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
    {
      id: 9,
      question: "У товаристві “Руська трійця” згуртувалися й працювали митці",
      options: [
        { id: "A", text: "з Харкова" },
        { id: "B", text: "з Києва" },
        { id: "C", text: "зі Східної України" },
        { id: "D", text: "із Західної України" },
      ],
      correctAnswer: "D",
      isTest: true,
    },
  ],
};
