import { text_mod_36_1, text_mod_36_2 } from "../text/Text.js";

export const module36 = {
  title: "Тема 36. Кримські татари та українці в сучасній українській літературі. Володимир Рутківський «Пастух Рашит»",
  questions: [
    {
      id: 1,
      subtitle: "ДІЗНАЙСЯ БІЛЬШЕ",
      onlyText: true,
      text: text_mod_36_1,
    },
    {
      id: 2,
      subtitle: "ДОСЛІДЖУЄМО МЕДІА",
      onlyText: true,
      text: text_mod_36_2,
    },
    {
      id: 3,
      question: "Грицик і Санько обдурили слуг пана Кобильського,",
      options: [
        { id: "А", text: "вказавши їм неправильну дорогу до села" },
        { id: "Б", text: "оголосивши про набіг татар" },
        { id: "В", text: "викравши їхніх коней" },
        { id: "Г", text: "попередивши односельців" },
      ],
      subtitle: "Тестові питання",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "Санько має надзвичайні здібності –",
      options: [
        { id: "А", text: "розуміє мову тварин" },
        { id: "Б", text: "вміє рухати предмети" },
        { id: "В", text: "навіює думки іншим істотам" },
        { id: "Г", text: "відвертає стріли ворога" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 5,
      question: "Дід Кудьма був",
      options: [
        { id: "А", text: "кошовим отаманом" },
        { id: "Б", text: "вивідником" },
        { id: "В", text: "сотником" },
        { id: "Г", text: "знахарем" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: "Грицик засмутився, що Санько стане характерником, бо",
      options: [
        { id: "А", text: "боявся ворожбитів" },
        { id: "Б", text: "заздрив товаришеві" },
        { id: "В", text: "не хотів втрачати справжнього друга" },
        { id: "Г", text: "не вірив у здібності хлопця" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 7,
      question: "Швайка довіряв Рашиту, бо",
      options: [
        { id: "А", text: "вони були давніми знайомими" },
        { id: "Б", text: "вони разом воювали" },
        { id: "В", text: "їх обох виховувала мати Рашита" },
        { id: "Г", text: "їх матері були разом у полоні" },
      ],
      correctAnswer: "В",
      isTest: true,
    },
    {
      id: 8,
      question: "“...невловимий, хоробрий і всюдисущий…” – так автор описує",
      options: [
        { id: "А", text: "Рашита" },
        { id: "Б", text: "Грицика" },
        { id: "В", text: "діда Кудьму" },
        { id: "Г", text: "Пилипа Швайку" },
      ],
      correctAnswer: "Г",
      isTest: true,
    },
  ],
};
